import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import IState from '../lib/state';

import { Auth } from '@halio-inc/react-authentication';

import { getLastSelectedSiteShortId } from '../lib/shared/selectors';

import NavLink from './nav-link';

interface IStateProps {
  readonly selectedSiteShortId?: string;
}

interface INavProps extends RouteComponentProps<any> {
  auth: Auth;
}

class Nav extends React.Component<
  INavProps & IStateProps & WithTranslation,
  any
> {
  public render() {
    return !this.props.selectedSiteShortId ? null : (
      <div>
        <ul className="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
          <NavLink
            exact
            to={`/${this.props.selectedSiteShortId}`}
            flaticon="layers"
          >
            {this.props.t('navigation-label-dashboard')}
          </NavLink>
          <NavLink
            exact
            to={`/${this.props.selectedSiteShortId}/activity-stream`}
            flaticon="clock-1"
          >
            {this.props.t('navigation-label-activity-stream')}
          </NavLink>
          <NavLink
            to={`/${this.props.selectedSiteShortId}/scenes`}
            flaticon="clock-1"
          >
            {this.props.t('navigation-label-scenes')}
          </NavLink>
          <NavLink
            to={`/${this.props.selectedSiteShortId}/reporting`}
            flaticon="statistics"
          >
            {this.props.t('navigation-label-reporting')}
          </NavLink>
          <NavLink
            to={`/${this.props.selectedSiteShortId}/mode-browser`}
            flaticon="interface-8"
          >
            {this.props.t('navigation-label-mode-browser')}
          </NavLink>
          <NavLink
            to={`/${this.props.selectedSiteShortId}/schedule`}
            flaticon="event-calendar-symbol"
          >
            {this.props.t('navigation-label-schedule')}
          </NavLink>
        </ul>
        <ul className="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
          <NavLink to="/support" flaticon="support">
            {this.props.t('navigation-label-support')}
          </NavLink>
          <NavLink to="/faq" flaticon="information">
            {this.props.t('navigation-label-faq')}
          </NavLink>
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    selectedSiteShortId: getLastSelectedSiteShortId(state),
  };
}

export default withTranslation('shared')(
  withRouter(connect<IStateProps, {}, INavProps>(mapStateToProps)(Nav)),
);
