import * as _ from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import IState from './state';

import actions from './actions';
import { getLocations } from './utils';

import sharedActions from '../../lib/shared/actions';

import debug from '../../lib/debug';
const createDebug = debug('pages:mode-browser');

type ActionTypes =
  | ActionType<typeof actions>
  | ActionType<typeof sharedActions>;

const initialState = {
  archStates: [],
  hasArchItems: false,
  hasLocations: false,
  isLoading: true,
  locations: [],
  rollups: {},
};

const log = createDebug('reducer');

export default (state: IState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case getType(actions.setArchStates):
      log('setArchStates');
      const setArchStatesAction = action as ReturnType<
        typeof actions.setArchStates
      >;
      const archStates = setArchStatesAction.payload;
      const hasArchItems = !!_.get(archStates, '[0].items', []).length;
      const locations = getLocations(archStates);
      const hasLocations = !!locations.length;

      return Object.assign({}, state, {
        archStates,
        hasArchItems,
        hasLocations,
        isLoading: false,
        locations,
      });
    case getType(actions.setRollups):
      log('setRollups');
      const setRollupsAction = action as ReturnType<typeof actions.setRollups>;

      return Object.assign({}, state, {
        rollups: setRollupsAction.payload,
      });
    case getType(actions.unload):
    case getType(sharedActions.setSite):
    case getType(sharedActions.clear):
      log(action.type);
      return initialState;
    case getType(actions.load):
    case getType(actions.changeArchState):
      log(action.type);
      return state;
    default:
      return state;
  }
};
