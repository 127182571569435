import * as React from 'react';

import GlareControlIcon from '../../svgs/GlareControlIcon';

import './index.css';

const NoSunPosition = () => (
  <div className="no-sun-position">
    <div className="no-sun-position__icon">
      <GlareControlIcon
        width="90px"
        height="90px"
        stroke="black"
        fill="black"
      />
    </div>
    <div className="no-sun-position__message">
      <p>No Sun Position set</p>
    </div>
  </div>
);

export default NoSunPosition;
