import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';

import { IApiResponse } from '@halio-inc/api-client';

import notifier from 'lib/shared/notifier';
import ApiClient from 'lib/web/api-client';
import LoadingSpinner from '../../components/LoadingSpinner';
import Form from './form';
import {
  flattenArchItems,
  ICompleteScene,
  ISetWindows,
  setWindowLocations,
} from './utils';

interface IMatchProps {
  siteId: string;
  sceneId: string;
}
interface IParams extends RouteComponentProps<IMatchProps> {}

const EditScenePage: React.FC<IParams> = props => {
  const {
    match: {
      params: { siteId, sceneId },
    },
    history,
  } = props;

  // Get scene
  const {
    data: scene,
    isError: isErrorScenes,
    isFetching: isFetchingScenes,
  } = useQuery<IApiResponse<ICompleteScene>>(
    [`${siteId}-${sceneId}-scene`],
    () => ApiClient.rawGet<ICompleteScene>(`sites/${siteId}/scenes/${sceneId}`),
  );
  // Get all locations and windows for site
  const {
    data: locationWindows,
    isError: isItemError,
    isFetching: isFetchingItems,
  } = useQuery<IApiResponse<ISetWindows[]>>(
    [`${siteId}-arch-item-window-location`],
    () =>
      ApiClient.rawGet<ISetWindows[]>(
        `sites/${siteId}/arch/items?types=window,location&min=true`,
      ),
  );

  const saveSceneMutation = useMutation(
    (newScene: ICompleteScene) => {
      return ApiClient.rawPut(`sites/${siteId}/scenes/${newScene.id}`, {
        scene: {
          name: newScene.name,
          windows: newScene.windows.map(w => ({
            id: w.id,
            level: w.sceneLevel,
          })),
        },
      });
    },
    {
      onError: e => {
        // Need to log to sentry or some remote log feature
        // tslint:disable-next-line: no-console
        console.log(e);
        notifier(
          'error',
          'An error occurred while attempting to save the Scene. Please try again.',
        );
      },
      onSuccess: (_, newScene) => {
        notifier('success', `Scene "${newScene.name}" has been saved`);
        history.push(`/${siteId}/scenes`);
      },
    },
  );

  // Handle save
  const handleSubmit = (newScene: ICompleteScene) => {
    saveSceneMutation.mutate(newScene);
  };

  const handleReset = () => {
    history.push(`/${siteId}/scenes`);
  };

  if (isFetchingScenes || isFetchingItems) {
    return <LoadingSpinner width="50px" height="50px" />;
  }

  if (!scene || !locationWindows || isErrorScenes || isItemError) {
    return <p>Unable to load scene</p>;
  }

  setWindowLocations(locationWindows.results);
  const flattened = flattenArchItems(locationWindows.results);
  const windows = flattened.filter(i => i.type === 'window');
  return (
    <Form
      scene={scene.results}
      windows={windows}
      onSubmit={handleSubmit}
      onReset={handleReset}
    />
  );
};

export default EditScenePage;
