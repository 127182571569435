import * as React from 'react';
import { Translation } from 'react-i18next';

interface ILocaleProps {
  nameSpace: string;
  localeKey: string;
}

const Locale = (props: ILocaleProps) => (
  <Translation ns={props.nameSpace}>
    {t => <React.Fragment>{t(props.localeKey)}</React.Fragment>}
  </Translation>
);

export default Locale;
