import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import IState from '../lib/state';

import { ISite } from '@halio-inc/api-client';

interface IStateProps {
  readonly selectedSite?: ISite;
}

interface IForceSiteRedirectProps
  extends RouteComponentProps<any>,
    IStateProps {}

class ForceSiteRedirect extends React.Component<IForceSiteRedirectProps, any> {
  constructor(props: IForceSiteRedirectProps) {
    super(props);

    this.setup(props);
  }

  public componentWillReceiveProps(
    nextProps: IForceSiteRedirectProps,
    nextContext: any,
  ) {
    this.setup(nextProps);
  }

  public render() {
    return null;
  }

  private setup(props: IForceSiteRedirectProps) {
    if (props.selectedSite) {
      this.props.history.push(`/${props.selectedSite.id}`);
    }
  }
}

function mapStateToProps(state: IState) {
  return {
    selectedSite: _.get(state, 'shared.lastSelectedSite', null),
  };
}

export default withRouter(
  connect<IStateProps, IForceSiteRedirectProps>(mapStateToProps)(
    ForceSiteRedirect,
  ),
);
