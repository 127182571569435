import * as _ from 'lodash';
import * as React from 'react';

import { IEvent } from '@halio-inc/api-client';

import EventContent from './EventContent';

import { getLanguageKeyFromDay } from '../../../lib/shared/utils';
import Locale from '../../locale';

interface IEventSectionProps {
  date: string;
  day: string;
  dayEvents: IEvent[];
  localizeNameSpace?: string;
}

const EventSection = (props: IEventSectionProps) => {
  const getDataType = (dayEvent: IEvent) => {
    const dataFromSource = _.get(dayEvent, 'data.source.data.type', '');
    const dataFromBody = _.get(dayEvent, 'body.source', '');

    return dataFromSource || dataFromBody;
  };
  let eventSectionTitle: string | JSX.Element;

  if (props.localizeNameSpace) {
    const day = getLanguageKeyFromDay(props.day);
    eventSectionTitle = (
      <>
        <Locale nameSpace={props.localizeNameSpace} localeKey={day} /> -{' '}
        {props.date}
      </>
    );
  } else {
    eventSectionTitle = `${props.day} - ${props.date}`;
  }

  return (
    <div className="event-section">
      <div className="alert alert-info event-section__title" role="alert">
        {eventSectionTitle}
      </div>
      <div className="event-section__content-wrapper">
        {props.dayEvents.map(dayEvent => (
          <React.Fragment key={dayEvent.inserted_at}>
            <EventContent
              time={dayEvent.event_time}
              dataType={getDataType(dayEvent)}
              eventType={_.get(dayEvent, 'hdr.event_type', '')}
              eventSummary={_.get(dayEvent, 'data.summary', '')}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default EventSection;
