import * as React from 'react';

class Colophon extends React.Component {
  public render() {
    return (
      <div className="page-colophon">
        <ul>
          <li>
            Theme:{' '}
            <a href="https://keenthemes.com/metronic/preview/?page=index&demo=default">
              Metronic
            </a>{' '}
            (
            <a href="https://keenthemes.com/metronic/preview/?page=components/icons/flaticon&demo=default">
              icons
            </a>
            )
          </li>
          <li>
            Charts: <a href="https://www.chartjs.org/">Chart.js</a> (<a href="http://www.chartjs.org/samples/latest/">
              samples
            </a>)
          </li>
        </ul>
      </div>
    );
  }
}

export default Colophon;
