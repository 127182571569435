import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { ISite } from '@halio-inc/api-client';

import SiteSummary from '../components/site-summary';
import ActionTypes from '../lib/action-types';
import SharedActions from '../lib/shared/actions';
import IState from '../lib/state';

import './header.css';

interface IStateProps {
  readonly sites?: ISite[];
  readonly selectedSite?: ISite;
}

interface IDispatchProps {
  setSite?: (
    site: ISite | undefined,
  ) => ReturnType<typeof SharedActions.setSite>;
}

interface IHeaderProps
  extends RouteComponentProps<any>,
    IStateProps,
    IDispatchProps {}

const HeaderDesktop = (props: IHeaderProps) => {
  if (!props.selectedSite) {
    return null;
  }

  const setSite = async (site: ISite) => {
    if (props.setSite) {
      props.setSite(site);
    }
  };

  return (
    <ul
      id="m-menu_desktop-nav"
      className="m-menu__nav m-menu__nav--submenu-arrow "
    >
      <li
        className="m-menu__item m-menu__item--submenu m-menu__item--rel"
        m-menu-submenu-toggle="click"
        m-menu-link-redirect="1"
        aria-haspopup="true"
      >
        <button className="m-menu__link m-menu__toggle btn-blank">
          <span className="m-menu__link-text">
            <h2>
              {props.selectedSite.name}
              <SiteSummary style={{ fontSize: 'medium' }} prefix=" - " />
            </h2>
            <div className="siteAddress">
              {_.get(
                props.selectedSite,
                'attributes.siteinfo.address.value',
                '',
              )}
            </div>
          </span>
        </button>
        <div className="m-menu__submenu m-menu__submenu--classic m-menu__submenu--left">
          <span className="m-menu__arrow" />
          <ul className="m-menu__subnav site_switcher">
            {!props.sites
              ? null
              : props.sites.map(site => (
                  <li className="m-menu__item " key={site.id}>
                    <button
                      className="m-menu__link btn-blank"
                      onClick={setSite.bind({}, site)}
                    >
                      <span className="m-menu__link-text">{site.name}</span>
                    </button>
                  </li>
                ))}
          </ul>
        </div>
      </li>
    </ul>
  );
};

function mapStateToProps(state: IState) {
  return {
    selectedSite: _.get(state, 'shared.lastSelectedSite', {}),
    sites: _.get(state, 'shared.sites', []),
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    {
      setSite: SharedActions.setSite,
    },
    dispatch,
  );

export default withRouter(
  connect<IStateProps, IDispatchProps, IHeaderProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(HeaderDesktop),
);
