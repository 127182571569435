import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';

interface ISortableHeaderProps {
  onClick: (key: string) => void;
  sortKey: string;
  currSort: string;
  currSortAsc: boolean;
}

const useSortableHeaderStyles = createUseStyles({
  th: {
    '&.active': {
      '&.asc': {
        '&::before': {
          opacity: 1,
        },
      },
      '&.desc': {
        '&::after': {
          opacity: 1,
        },
      },
    },
    '&::after': {
      content: "'\\2193'",
      opacity: 0.3,
      position: 'absolute',
      right: '0.5em',
    },
    '&::before': {
      content: "'\\2191'",
      opacity: 0.3,
      position: 'absolute',
      right: '1em',
    },
    cursor: 'pointer',
    position: 'relative',
  },
});

const SortableHeader: React.FC<ISortableHeaderProps> = ({
  onClick,
  sortKey,
  children,
  currSort,
  currSortAsc,
}) => {
  const sortClick = useCallback(() => onClick(sortKey), [onClick, sortKey]);
  const classes = useSortableHeaderStyles();
  const active = currSort === sortKey;
  return (
    <th
      className={classNames(classes.th, {
        active,
        asc: active && currSortAsc,
        desc: active && !currSortAsc,
      })}
      onClick={sortClick}
    >
      {children}
    </th>
  );
};

export const useSortColumn = (
  defaultSortCol: string,
  defaultSortIsAsc: boolean = true,
) => {
  const [sortCol, setSortCol] = useState(defaultSortCol);
  const [sortIsAsc, setSortIsAsc] = useState<boolean>(defaultSortIsAsc);
  const onHeaderClick = (newSortCol: string) => {
    if (sortCol === newSortCol) {
      setSortIsAsc(s => !s);
    } else {
      setSortCol(newSortCol);
      setSortIsAsc(true);
    }
  };
  return {
    onHeaderClick,
    setSortCol,
    setSortIsAsc,
    sortCol,
    sortIsAsc,
  };
};

export default SortableHeader;
