import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { HorizontalBar } from 'react-chartjs-2';

import { ISite } from '@halio-inc/api-client';

import './index.css';

import ActionTypes from '../../../lib/action-types';
import IState from '../../../state';
import LoadingIndicator from '../../LoadingIndicator';

import Actions from './actions';
import ITintByLocationWidgetStateProps from './state';

import debug from '../../../lib/debug';
const createDebug = debug('components:widgets:tint-by-location');

const log = createDebug('');

interface IDispatchProps {
  onLoad: () => ReturnType<typeof Actions.load>;
  onUnload: () => ReturnType<typeof Actions.unload>;
}

interface ITintByLocationWidgetComponentProps {
  height?: string;
  width?: string;
}

interface ITintByLocationWidgetProps
  extends RouteComponentProps<any>,
    ITintByLocationWidgetComponentProps,
    ITintByLocationWidgetStateProps,
    IDispatchProps {
  selectedSite: ISite;
}

class TintByLocationWidget extends React.Component<
  ITintByLocationWidgetProps,
  any
> {
  public render() {
    const chartData = this.getChartData();

    return (
      <div className="widget-tint-by-location">
        {this.props.isLoading && (
          <LoadingIndicator height="90px" width="90px" />
        )}
        <div
          className="chart-container"
          style={{
            position: 'relative',
          }}
        >
          <HorizontalBar
            data={{
              datasets: [
                {
                  data: chartData.values,
                  fill: false,
                },
              ],
              labels: chartData.labels,
            }}
            legend={{ display: false }}
            options={{
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      max: 100,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>
    );
  }

  public componentDidMount() {
    this.props.onLoad();
  }

  public componentWillUnmount() {
    this.props.onUnload();
  }

  private getChartData() {
    log({
      locations: this.props.locations,
      rollups: this.props.rollups,
    });

    const data: { labels: string[]; values: number[] } = {
      labels: [],
      values: [],
    };

    if (!this.props.locations || !this.props.rollups) {
      return data;
    }

    // Organize rollups for easier access
    const rollups = {};
    this.props.rollups.forEach(r => {
      if (rollups[r.objectId]) {
        return;
      }
      rollups[r.objectId] = r;
    });

    // Iterate locations in order to generate chart data
    this.props.locations.forEach(loc => {
      if (!rollups[loc.id]) {
        return;
      }
      data.labels.push(loc.name);
      data.values.push(Number.parseInt(rollups[loc.id].value, 10));
    });

    return data;
  }
}

function mapStateToProps(state: IState) {
  return {
    isLoading: _.get(
      state,
      'components.widgets.tintByLocation.isLoading',
      false,
    ),
    locations: _.get(
      state,
      'components.widgets.tintByLocation.locations',
      null,
    ),
    rollups: _.get(state, 'components.widgets.tintByLocation.rollups', null),
    selectedSite: _.get(state, 'shared.lastSelectedSite', null),
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    {
      onLoad: Actions.load,
      onUnload: Actions.unload,
    },
    dispatch,
  );

export default withRouter(
  connect<
    ITintByLocationWidgetStateProps,
    IDispatchProps,
    ITintByLocationWidgetComponentProps
  >(
    mapStateToProps,
    mapDispatchToProps,
  )(TintByLocationWidget),
);
