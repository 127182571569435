import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const EcoLargeIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '125px'}
    height={props.height ? props.height : '125px'}
    viewBox="0 0 150 150"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="eco">
        <circle
          id="Oval"
          stroke="#4684BE"
          strokeWidth="6"
          fill="#FFFFFF"
          cx="75"
          cy="75"
          r="72"
        />
        <g id="icon" transform="translate(36.000000, 36.000000)">
          <g transform="translate(39.500000, 39.500000) scale(-1, 1) rotate(-180.000000) translate(-39.500000, -39.500000) ">
            <circle
              id="circle-top"
              stroke="#4684BE"
              strokeWidth="3"
              cx="33"
              cy="66"
              r="11.5"
            />
            <path
              d="M57.512,67.703 C53.562,66.36 50.402,63.99 50.086,62.252 C49.691,60.514 51.034,60.672 54.51,62.884 C56.09,63.911 58.46,65.017 59.645,65.254 C62.173,65.886 62.173,66.36 59.487,58.381 L58.223,54.589 L62.568,50.086 C66.597,45.978 67.387,44.477 69.362,37.525 C69.52,36.972 70.863,36.498 72.443,36.498 L75.208,36.498 L75.208,30.573 L75.208,24.648 L71.416,24.648 C67.782,24.648 67.466,24.49 65.096,21.172 C63.674,19.276 60.988,16.669 59.092,15.484 C56.09,13.588 55.458,12.64 54.194,8.532 L52.693,3.713 L48.743,3.713 L44.872,3.713 L43.766,7.505 L42.739,11.218 L36.577,11.218 L30.494,11.218 L29.309,7.505 C28.124,3.792 28.124,3.713 24.49,3.476 C20.303,3.239 19.513,4.029 18.644,9.559 C18.17,12.403 17.696,13.114 15.089,14.378 C0.948,21.646 -1.106,41.001 11.139,51.587 C17.143,56.722 16.116,59.487 10.033,54.668 C3.476,49.454 0.079,42.502 0.079,34.523 C0.079,28.914 0.948,25.754 3.713,21.014 C5.53,17.933 12.008,11.85 14.378,11.06 C14.773,10.981 15.642,8.927 16.274,6.557 C17.617,1.422 19.039,0.158 23.542,0.158 C28.835,0.158 30.573,1.185 31.679,4.819 C32.627,8.058 32.627,8.058 36.498,8.058 C40.369,8.058 40.369,8.058 41.238,4.898 C42.265,1.106 43.845,0.158 48.901,0.158 C53.957,0.158 55.221,1.185 56.88,6.636 C57.986,10.27 58.934,11.613 61.541,13.509 C63.279,14.773 65.728,17.064 66.834,18.565 C68.651,20.935 69.52,21.33 72.917,21.646 C75.129,21.883 77.262,22.594 77.657,23.147 C78.684,24.885 78.526,37.604 77.42,38.71 C76.867,39.263 75.524,39.658 74.418,39.658 C72.68,39.658 72.048,40.29 70.547,44.003 C69.52,46.373 67.15,50.007 65.175,51.982 L61.699,55.616 L63.279,60.435 C65.254,66.202 65.333,67.782 63.674,68.414 C61.936,69.046 61.067,68.967 57.512,67.703 Z"
              id="Path"
              fill="#4684BE"
              fillRule="nonzero"
            />
            <circle id="eye" fill="#4684BE" cx="58" cy="44" r="3" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default EcoLargeIcon;
