import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import * as _ from 'lodash';

import ActionTypes from '../lib/action-types';
import SharedActions from '../lib/shared/actions';
import IState from '../lib/state';

import { IAccount, ISite } from '@halio-inc/api-client';

import { Auth } from '@halio-inc/react-authentication';

import LanguageTextSwitcher from '../components/language-text-switcher';
import HeaderDesktop from './header-desktop';
import HeaderMobile from './header-mobile';

import './header.css';

interface IStateProps {
  readonly account?: IAccount;
  readonly sites?: ISite[];
  readonly selectedSite?: ISite;
}

interface IDispatchProps {
  setSite?: (
    site: ISite | undefined,
  ) => ReturnType<typeof SharedActions.setSite>;
}

interface IHeaderProps
  extends RouteComponentProps<any>,
    IStateProps,
    IDispatchProps {
  auth: Auth;
}

class Header extends React.Component<IHeaderProps, any> {
  public render() {
    return (
      <div className="m-container m-container--fluid m-container--full-height">
        <div className="m-stack m-stack--ver m-stack--desktop">
          <div className="m-stack__item m-brand  m-brand--skin-dark ">
            <div className="m-stack m-stack--ver m-stack--general">
              <div className="m-stack__item m-stack__item--middle m-brand__logo">
                <a href="/" className="m-brand__logo-wrapper">
                  <img alt="" src="/logo.png" height="70px" />
                </a>
              </div>
              <div className="m-stack__item m-stack__item--middle m-brand__tools">
                <button
                  id="m_aside_left_minimize_toggle"
                  className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block btn-blank"
                >
                  <span />
                </button>
                <button
                  id="m_aside_left_offcanvas_toggle"
                  className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block btn-blank"
                >
                  <span />
                </button>
                <button
                  id="m_aside_header_menu_mobile_toggle"
                  className="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block btn-blank"
                >
                  <span />
                </button>
                <button
                  id="m_aside_header_topbar_mobile_toggle"
                  className="m-brand__icon m--visible-tablet-and-mobile-inline-block btn-blank"
                  onClick={this.toggleMobileContentPadding}
                >
                  <i className="flaticon-more" />
                </button>
              </div>
            </div>
          </div>
          <div
            className="m-stack__item m-stack__item--fluid m-header-head"
            id="m_header_nav"
          >
            <button
              className="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-dark "
              id="m_aside_header_menu_mobile_close_btn"
            >
              <i className="la la-close" />
            </button>
            <div
              id="m_header_menu"
              className="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark "
            >
              <HeaderMobile />
              <HeaderDesktop />
            </div>
            <div
              id="m_header_topbar"
              className="m-topbar  m-stack m-stack--ver m-stack--general m-stack--fluid"
            >
              <div className="m-stack__item m-topbar__nav-wrapper">
                <ul className="m-topbar__user-nav">
                  <li className="m-nav__item m-topbar__user-profile m-topbar__user-profile--img">
                    <button className="m-nav__link btn-blank">
                      {(() =>
                        !this.props.account ? null : (
                          <span
                            className="m-topbar__username"
                            style={{ color: '#676c7b' }}
                          >
                            {this.props.account.name}
                          </span>
                        ))()}
                    </button>
                  </li>
                  <li className="m-nav__item">
                    <React.Suspense
                      fallback={<LanguageTextSwitcher isLoadingState />}
                    >
                      <LanguageTextSwitcher />
                    </React.Suspense>
                  </li>
                  <li className="m-nav__item m-nav__item-auth">
                    {(() =>
                      !this.props.account ? null : (
                        <button
                          className="m-nav__link btn-blank btn-hyperlink"
                          onClick={this.props.auth.logout}
                        >
                          <span className="m-nav__link-icon">
                            <span className="m-nav__link-icon-wrapper">
                              <i className="flaticon-logout">&nbsp;</i>
                            </span>
                          </span>
                        </button>
                      ))()}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private toggleMobileContentPadding = () => {
    const $rootContainer = document.getElementById('root');

    if (!$rootContainer) {
      return;
    }

    const isActive =
      $rootContainer.className.indexOf('user-info-header--on') > -1;

    if (isActive) {
      $rootContainer.classList.remove('user-info-header--on');
    } else {
      $rootContainer.classList.add('user-info-header--on');
    }
  };
}

function mapStateToProps(state: IState) {
  return {
    account: _.get(state, 'shared.me', null),
    selectedSite: _.get(state, 'shared.lastSelectedSite', []),
    sites: _.get(state, 'shared.sites', null),
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    {
      setSite: SharedActions.setSite,
    },
    dispatch,
  );

export default withRouter(
  connect<IStateProps, IDispatchProps, IHeaderProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(Header),
);
