import * as React from 'react';

import './styles.css';

interface ILoadingSpinnerProps {
  height?: string;
  width?: string;
}

const Spinner = (props: ILoadingSpinnerProps) => {
  const inlineStyles = {
    height: props.height || '20px',
    width: props.width || '20px',
  };

  return (
    <div className="loading-container">
      <div className="loading-spinner" style={inlineStyles}>
        <div className="loading-outline" />
        <div className="loading-outline" />
        <div className="loading-outline" />
        <div className="loading-outline" />
      </div>
    </div>
  );
};

export default Spinner;
