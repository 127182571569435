import classNames from 'classnames';
import { orderBy } from 'lodash';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';

import SortableHeader, {
  useSortColumn,
} from 'components/table/sortable-header';
import useForm from 'lib/hooks/useForm';
import { ICompleteScene, ISetWindows } from './utils';

interface IWindowRowProps {
  sceneWindow: ISetWindows | undefined;
  className: string;
  window: ISetWindows;
  setState: (path: string, value: any) => void;
  sceneWindows: ISetWindows[];
}
const WindowRow: React.FC<IWindowRowProps> = ({
  sceneWindow,
  className,
  window: w,
  setState,
  sceneWindows,
}) => {
  const toggleWindow = useCallback(
    () => {
      let windows = sceneWindows;
      if (sceneWindow) {
        windows = windows.filter(w2 => w2.id !== w.id);
      } else {
        windows = [
          ...windows,
          {
            ...w,
            sceneLevel: 0,
          },
        ];
      }
      setState('windows', windows);
    },
    [sceneWindow, setState, sceneWindows, w],
  );
  const setWindowTint = useCallback(
    e => {
      const wIdx = sceneWindows.findIndex(w2 => w2.id === w.id);
      setState('windows', [
        ...sceneWindows.slice(0, wIdx),
        {
          ...w,
          sceneLevel: e.target.valueAsNumber,
        },
        ...sceneWindows.slice(wIdx + 1),
      ]);
    },
    [setState, sceneWindows, w],
  );
  return (
    <tr className={className}>
      <td>{w.name}</td>
      <td>{w.location.name}</td>
      <td onClick={toggleWindow}>
        <input type="checkbox" checked={!!sceneWindow} readOnly />
      </td>
      <td>
        {sceneWindow ? (
          <input
            type="range"
            value={sceneWindow.sceneLevel}
            min={0}
            max={100}
            step={10}
            onChange={setWindowTint}
          />
        ) : (
          'N/A'
        )}
      </td>
    </tr>
  );
};

const sortTypes = {
  include: 'include',
  location: 'location',
  name: 'name',
  tintlvl: 'tintlvl',
};

interface IValidateForm {
  name: string;
  windows: string;
}

const validityChecker = (scene: ICompleteScene): IValidateForm => {
  return {
    name: scene.name === '' ? 'Name is required' : '',
    windows:
      scene.windows.length === 0
        ? 'At least one window must exist in the scene'
        : '',
  };
};

interface IParams {
  scene: ICompleteScene;
  windows: ISetWindows[];
  onSubmit: (scene: ICompleteScene) => void;
  onReset: () => void;
}
const useStyles = createUseStyles({
  windowTable: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
  },
  windowTableBody: {
    display: 'block',
    flex: '1 1 auto',
    height: '400px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  windowTableHeader: {
    flex: '0 0 auto',
  },
  windowTableRow: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
});
const SceneForm: React.FC<IParams> = props => {
  const { scene, windows, onSubmit, onReset: handleReset } = props;
  const {
    formState,
    formValidity,
    onChange: handleChange,
    handleSubmit,
    setState,
  } = useForm<ICompleteScene, IValidateForm>(scene, onSubmit, validityChecker);
  const classes = useStyles();
  const [textSearch, setTextSearch] = useState<string>('');
  const [includeChecked, setIncludeChecked] = useState<boolean>(false);
  const {
    sortCol,
    sortIsAsc,
    onHeaderClick: handleHeaderClick,
  } = useSortColumn(sortTypes.name);

  const handleTextSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setTextSearch(e.target.value);
  };
  const handleIncludeCheck = () => {
    setIncludeChecked(c => !c);
  };

  const selectedWindowIds = formState.windows.reduce((a, w) => {
    a[w.id] = w;
    return a;
  }, {});
  let filteredWindows = windows;
  if (textSearch) {
    const textLower = textSearch.toLowerCase();
    filteredWindows = filteredWindows.filter(
      w =>
        w.name.toLowerCase().includes(textLower) ||
        w.location.name.toLowerCase().includes(textLower),
    );
  }
  if (includeChecked) {
    filteredWindows = filteredWindows.filter(w => selectedWindowIds[w.id]);
  }

  filteredWindows = orderBy(
    filteredWindows,
    w => {
      const win = selectedWindowIds[w.id];
      const tintBtm = !sortIsAsc ? -1 : 101;
      switch (sortCol) {
        case sortTypes.name:
          return w.name;
        case sortTypes.location:
          return w.location.name;
        case sortTypes.include:
          return win !== undefined;
        case sortTypes.tintlvl:
          return win === undefined ? tintBtm : win.sceneLevel;
        default:
          break;
      }
    },
    [sortIsAsc ? 'asc' : 'desc'],
  );

  return (
    <div>
      {formValidity.submitted &&
        formValidity.error && (
          <div>
            <div className="alert alert-danger" role="alert">
              <ul className="mb-0">
                {Object.keys(formValidity.fields)
                  .map(
                    k =>
                      formValidity.fields[k].error
                        ? formValidity.fields[k].msg
                        : undefined,
                  )
                  .filter(m => m)
                  .map(m => <li key={m}>{m}</li>)}
              </ul>
            </div>
          </div>
        )}
      <form onSubmit={handleSubmit} onReset={handleReset} noValidate>
        <div>
          <div className="form-group">
            <label
              className={classNames({
                'text-danger':
                  formValidity.submitted && formValidity.fields.name.error,
              })}
            >
              Scene Name <span className="text-danger">*</span>
            </label>
            <input
              value={formState.name}
              onChange={handleChange}
              name="name"
              className="form-control"
              placeholder="Enter Name"
            />
          </div>
        </div>
        <div className="table-responsive">
          <div className="form-group row">
            <label className="col-2 col-form-label">
              Search Window/Location
            </label>
            <input
              className="col-3 form-control"
              type="text"
              value={textSearch}
              onChange={handleTextSearch}
            />
            <label
              className="col-2 col-form-label"
              onClick={handleIncludeCheck}
            >
              Show Included Only
            </label>
            <input
              type="checkbox"
              className="form-control col-1 align-self-center"
              checked={includeChecked}
              onChange={handleIncludeCheck}
            />
          </div>
          <table
            className={classNames(
              'table table-head-custom table-head-bg table-borderless table-vertical-center',
              classes.windowTable,
            )}
          >
            <thead className={classes.windowTableHeader}>
              <tr className={classes.windowTableRow}>
                <SortableHeader
                  onClick={handleHeaderClick}
                  sortKey={sortTypes.name}
                  currSort={sortCol}
                  currSortAsc={sortIsAsc}
                >
                  Window Name
                </SortableHeader>
                <SortableHeader
                  onClick={handleHeaderClick}
                  sortKey={sortTypes.location}
                  currSort={sortCol}
                  currSortAsc={sortIsAsc}
                >
                  Location
                </SortableHeader>
                <SortableHeader
                  onClick={handleHeaderClick}
                  sortKey={sortTypes.include}
                  currSort={sortCol}
                  currSortAsc={sortIsAsc}
                >
                  Include in Scene
                </SortableHeader>
                <SortableHeader
                  onClick={handleHeaderClick}
                  sortKey={sortTypes.tintlvl}
                  currSort={sortCol}
                  currSortAsc={sortIsAsc}
                >
                  Tint Level %
                </SortableHeader>
              </tr>
            </thead>
            <tbody className={classes.windowTableBody}>
              {filteredWindows.map(w => {
                const included = formState.windows.find(w2 => w2.id === w.id);
                if (includeChecked && !included) {
                  return null;
                }
                return (
                  <WindowRow
                    window={w}
                    sceneWindow={included}
                    sceneWindows={formState.windows}
                    setState={setState}
                    key={w.id}
                    className={classes.windowTableRow}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <button type="submit" className="btn btn-primary mr-2">
            Submit
          </button>
          <button type="reset" className="btn btn-secondary">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default SceneForm;
