import { all } from 'redux-saga/effects';

import ApiClient from '@halio-inc/api-client';
import { IAuth } from '@halio-inc/react-authentication';

import setupSaga from './lib/shared/setup-saga';

import Components from './components/sagas';
import Pages from './pages/sagas';

export default function Sagas(auth: IAuth, client: ApiClient) {
  return function*() {
    yield all([Components(), setupSaga(auth, client), Pages()]);
  };
}
