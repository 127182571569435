import moment from 'moment';
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  race,
  take,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import sharedActions from '../../../lib/shared/actions';
import { requireSelectedSite } from '../../../lib/shared/setup-saga';
import actions from './actions';

import { IApiResponse, IRollup } from '@halio-inc/api-client';
import ApiClient from '../../../lib/web/api-client';

import debug from '../../../lib/debug';
const createDebug = debug('components:widgets:events-by-source');

const log = createDebug('sagas');

export default function* EventsBySourceSaga() {
  while (true) {
    // Wait for component load
    yield take(getType(actions.load));

    let handle = yield fork(loadData, true);

    while (true) {
      const {
        selectedSite,
        unload,
      }: {
        selectedSite: ReturnType<typeof sharedActions.setSite>;
        unload: ReturnType<typeof actions.unload>;
      } = yield race({
        selectedSite: take(getType(sharedActions.setSite)),
        unload: take(getType(actions.unload)),
      });

      yield cancel(handle);

      if (unload) {
        log('Page unloaded');
        break;
      }

      let fresh = false;

      if (selectedSite) {
        fresh = true;
      }

      handle = yield fork(loadData, fresh);
    }
  }
}

function* loadData(fresh: boolean) {
  try {
    const siteId = yield* requireSelectedSite();

    if (yield cancelled()) {
      log('cancel handled (1)');
      return;
    }

    const rollupsService = ApiClient.getRollups();

    const rollupsResponse: IApiResponse<IRollup[]> = yield call(
      [rollupsService, rollupsService.getRollups],
      {
        limit: 500,
        objectTypes: ['site'],
        period: '1d',
        relative: '1w',
        siteId,
        to: moment()
          .endOf('day')
          .toDate(),
        type: 'events_by_source',
      },
    );

    if (yield cancelled()) {
      log('cancel handled (2)');
      return;
    }

    if (rollupsResponse.success) {
      const rollups: IRollup[] = rollupsResponse.results;
      yield put(actions.setRollups(rollups));
    } else {
      log('Failed to retrieve rollups');
      return;
    }
  } finally {
    if (yield cancelled()) {
      log('cancel handled');
    }
  }
}
