import { createStandardAction } from 'typesafe-actions';

import { IScene, IScheduleEventFormatted } from '@halio-inc/api-client';

const ENTITY = 'PAGES/SCHEDULE';

export default {
  load: createStandardAction(`${ENTITY}/LOAD`)(),
  setFormattedScheduleEvents: createStandardAction(
    `${ENTITY}/SET_FORMATTED_SCHEDULE_EVENTS`,
  )<IScheduleEventFormatted>(),
  setScenes: createStandardAction(`${ENTITY}/SET_SCENES`)<IScene[]>(),
  unload: createStandardAction(`${ENTITY}/UNLOAD`)(),
};
