import * as _ from 'lodash';

import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import moment from 'moment-timezone';

import ModeIcon from '../../components/ModeIcon';
import { getLanguageKeyFromDay } from '../../lib/shared/utils';
import SubHeader from '../../theme/sub-header';
import NoSchedule from './no-schedule';

import {
  IScene,
  IScheduleEvent,
  IScheduleEventFormatted,
  ISite,
} from '@halio-inc/api-client';

import ActionTypes from '../../lib/action-types';
import IState from '../../state';
import Actions from './actions';
import IScheduleStateProps from './state';

import './index.css';

interface IDispatchProps {
  onLoad: () => ReturnType<typeof Actions.load>;
  onUnload: () => ReturnType<typeof Actions.unload>;
}

// Where the any in RouteComponentProps<any> is the interface for props.match.params
interface IScheduleProps
  extends RouteComponentProps<any>,
    IScheduleStateProps,
    IDispatchProps {
  selectedSite: ISite;
  formattedScheduleEvents: IScheduleEventFormatted;
  scenes: IScene[];
}

interface IScheduleState {
  date?: moment.Moment;
}

export class Schedule extends React.Component<
  IScheduleProps & WithTranslation,
  IScheduleState
> {
  public readonly state: IScheduleState = {};

  public componentDidMount() {
    this.props.onLoad();

    if (window.mApp) {
      window.mApp.init();
    }
  }

  public componentWillUnmount() {
    this.props.onUnload();
  }

  public render() {
    const timezone = _.get(
      this.props.selectedSite,
      'attributes.siteinfo.timezone.value',
      false,
    );
    let showResults = false;
    const results: JSX.Element[] = [];
    // TODO: Need to refactor this. this.setState should not be
    // called within render function.
    if (!this.state.date && this.props.selectedSite) {
      const now = moment();

      if (timezone) {
        now.tz(timezone);
      }

      this.setState({
        date: now,
      });
    }

    for (let i = 0; i < 7; i++) {
      const eventTimes = this.getEvents(i);

      results.push(
        <React.Fragment key={i}>
          <div className="alert alert-info" role="alert">
            {this.props.t(`shared:${getLanguageKeyFromDay(eventTimes.text)}`)}
          </div>
          <div
            className="m-scrollable _mCS_5 mCS-autoHide"
            style={{
              position: 'relative',
            }}
          >
            <div className="m-timeline-2">
              <div className="m-timeline-2__items m--padding-top-25">
                {/*
                  disabling eslint for no-loop-func.
                  the issue is with showResults variable.
                  this is tied to the TODO on line 73
                  uncomment to eslint to see error.
                */}
                {/* eslint-disable-next-line */}
                {Object.keys(eventTimes.results).map(time => (
                  <React.Fragment key={time}>
                    {eventTimes.results[time].map((event: IScheduleEvent) => {
                      showResults = true;

                      const details: JSX.Element[] = [];

                      switch (event.data.type) {
                        case 'set tint':
                        case 'set_tint':
                          if (event.data.setpoint) {
                            details.push(
                              <React.Fragment key={event.data.setpoint}>
                                <br />
                                <span className="normal-font">
                                  {this.props.t(
                                    'event-description-label-setpoint',
                                  )}: {event.data.setpoint}
                                </span>
                              </React.Fragment>,
                            );
                          }
                          break;
                        case 'set scene':
                        case 'set_scene':
                          if (event.data.sceneId) {
                            const sceneName = this.getSceneName(
                              event.data.sceneId,
                            );

                            if (sceneName) {
                              details.push(
                                <React.Fragment key={event.data.sceneId}>
                                  <br />
                                  <span className="normal-font">
                                    {this.props.t(
                                      'event-description-label-scene',
                                    )}: {sceneName}
                                  </span>
                                </React.Fragment>,
                              );
                            }
                          }
                          break;
                        default:
                          break;
                      }

                      return (
                        <div key={`${i}_${event.id}`} className="time-line">
                          <span className="time-line__item-time">
                            <span>
                              {moment(
                                `2014-12-13 ${event.time}`,
                                'YYYY-MM-DD HH:mm',
                              ).format('h:mm A')}
                            </span>
                          </span>
                          <div className="time-line__item-icon">
                            <ModeIcon modeType={event.data.type} />
                          </div>
                          <div className="time-line__item-text  m--padding-top-5">
                            {this.props.t(
                              'event-description-label-change-mode',
                            )}:{' '}
                            <span className="event-type-text normal-font">
                              {event.data.type}
                            </span>
                            {details}
                            <br />
                            {this.props.t('event-description-label-locations')}:
                            <ul>
                              {event.archItems.map(ai => (
                                <li key={ai.id}>{ai.name}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </React.Fragment>,
      );
    }

    return (
      <div className="page-schedule">
        <SubHeader
          title={this.props.t('title')}
          tzoneLabelTranslation={this.props.t('shared:label-time-zone')}
          timeZone={timezone}
        />

        {showResults ? results : <NoSchedule />}
      </div>
    );
  }

  private getSceneName(sceneId: string) {
    if (this.props.scenes && _.isArray(this.props.scenes)) {
      const scene = this.props.scenes.find(s => s.id === sceneId);

      if (scene) {
        return scene.name;
      }
    }

    return '';
  }

  private getEvents(fromToday: number) {
    if (!this.state.date) {
      return {
        results: {},
        text: moment()
          .add(fromToday, 'days')
          .calendar('', {
            lastDay: '[Yesterday]',
            lastWeek: '[Last] dddd',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            sameDay: '[Today]',
            sameElse: 'DD/MM/YYYY',
          }),
      };
    }

    const date = this.state.date.clone().add(fromToday, 'days');

    const dayOfWeek = date.format('dddd').toLowerCase();
    const dateString = date.format('YYYY-MM-DD');

    let eventTimes: { [time: string]: IScheduleEvent[] };

    if (this.props.formattedScheduleEvents.exceptions[dateString]) {
      eventTimes = this.props.formattedScheduleEvents.exceptions[dateString];
    } else {
      eventTimes = _.get(
        this.props.formattedScheduleEvents,
        `days.${dayOfWeek}`,
        {},
      );
    }

    const results: { [time: string]: IScheduleEvent[] } = {};

    Object.keys(eventTimes)
      .sort()
      .forEach(key => (results[key] = eventTimes[key]));

    return {
      results,
      text: date.calendar('', {
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        sameDay: '[Today]',
        sameElse: 'DD/MM/YYYY',
      }),
    };
  }
}

function mapStateToProps(state: IState) {
  return {
    formattedScheduleEvents: _.get(
      state,
      'pages.schedule.formattedScheduleEvents',
      [],
    ),
    scenes: _.get(state, 'pages.schedule.scenes', []),
    selectedSite: _.get(state, 'shared.lastSelectedSite', null),
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    {
      onLoad: Actions.load,
      onUnload: Actions.unload,
    },
    dispatch,
  );

export default withTranslation(['schedule', 'shared'])(
  withRouter(
    connect<IScheduleStateProps, IDispatchProps>(
      mapStateToProps,
      mapDispatchToProps,
    )(Schedule),
  ),
);
