import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const CloseIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '20px'}
    height={props.height ? props.height : '20px'}
    viewBox="0 0 38 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <g
        id="close-icon"
        transform="translate(1.000000, 0.000000)"
        stroke="#6A6A6A"
      >
        <path d="M0.163636364,0.163636364 L35.8363636,35.8363636" id="Line" />
        <path d="M36,0 L0,36 L36,0 Z" id="Line" />
      </g>
    </g>
  </svg>
);

export default CloseIcon;
