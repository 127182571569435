import * as _ from 'lodash';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link, NavLinkProps as RRDNavLinkProps, Route } from 'react-router-dom';

interface INavLinkProps extends RRDNavLinkProps {
  flaticon?: string;
  [rest: string]: any;
}

/**
 * A <Link> wrapper that knows if it's "active" or not.
 */
export default class NavLink extends React.Component<INavLinkProps> {
  private activeClassName = 'm-menu__item--active';

  constructor(props: INavLinkProps) {
    super(props);

    if (this.props.activeClassName) {
      this.activeClassName = this.props.activeClassName;
    }
  }

  public render() {
    const path =
      typeof this.props.to === 'object'
        ? this.props.to.pathname
        : this.props.to;

    // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
    const escapedPath =
      path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

    return (
      <Route
        path={escapedPath}
        exact={this.props.exact}
        strict={this.props.strict}
        location={this.props.location}
        children={this.processChildren}
      />
    );
  }

  private processChildren = (context: RouteComponentProps) => {
    const isActive = !!(this.props.isActive
      ? this.props.isActive(context.match, context.location)
      : context.match);
    const closeMobileNavMenu = () => {
      const isMobile = window.innerWidth <= 1025;
      const $closeBtn = document.getElementById('m_aside_left_close_btn');

      if (!isMobile || !$closeBtn) {
        return;
      }

      $closeBtn.click();
    };
    let iconClass: JSX.Element | undefined;

    if (this.props.flaticon) {
      iconClass = (
        <i className={'m-menu__link-icon flaticon-' + this.props.flaticon}>
          &nbsp;
        </i>
      );
    }

    return (
      <li
        className={
          isActive
            ? ['m-menu__item', this.activeClassName].filter(i => i).join(' ')
            : 'm-menu__item'
        }
        style={isActive ? _.get(this, 'props.activeStyle', {}) : undefined}
        aria-haspopup="true"
        onClick={closeMobileNavMenu}
      >
        <Link
          to={this.props.to}
          className={['m-menu__link', this.props.className]
            .filter(i => i)
            .join(' ')}
          style={this.props.style}
          aria-current={(isActive && this.props['aria-current']) || null}
          {...this.props.rest}
        >
          {iconClass}
          <span className="m-menu__link-title">
            <span className="m-menu__link-wrap">
              <span className="m-menu__link-text">{this.props.children}</span>
            </span>
          </span>
        </Link>
      </li>
    );
  };
}
