import {
  call,
  cancel,
  cancelled,
  fork,
  join,
  put,
  race,
  select,
  take,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import sharedActions from '../../lib/shared/actions';
import { getLastSelectedSite } from '../../lib/shared/selectors';
import actions from './actions';

import { requireSelectedSite } from '../../lib/shared/setup-saga';

import {
  IApiResponse,
  IEventApiResult,
  IEventFiltersParam,
} from '@halio-inc/api-client';
import ApiClient from '../../lib/web/api-client';

import debug from '../../lib/debug';
const createDebug = debug('pages:activity-stream:sagas');

const log = createDebug();

export default function* ActivityStreamSaga() {
  while (true) {
    const loadData = yield take(getType(actions.load));

    const params = loadData.payload || {};

    let fresh: boolean = true;

    let handle = yield fork(loadPageData, fresh, params);

    while (true) {
      const {
        filtersChanged,
        loadMore,
        selectedSite,
        unload,
      }: {
        filtersChanged: ReturnType<typeof actions.filtersChanged>;
        loadMore: ReturnType<typeof actions.loadMore>;
        selectedSite: ReturnType<typeof sharedActions.setSite>;
        unload: ReturnType<typeof actions.unload>;
      } = yield race({
        filtersChanged: take(getType(actions.filtersChanged)),
        loadMore: take(getType(actions.loadMore)),
        selectedSite: take(getType(sharedActions.setSite)),
        unload: take(getType(actions.unload)),
      });

      if (loadMore) {
        // wait for our forked loadPageData task
        yield join(handle);
        params.position = loadMore.payload.position;
      } else {
        yield cancel(handle);
      }

      if (unload) {
        log('Page unloaded');
        break;
      }

      fresh = false;

      if (selectedSite || filtersChanged) {
        fresh = true;
        params.position = undefined;
      }

      if (filtersChanged) {
        params.filters = filtersChanged.payload;
      }

      handle = yield fork(loadPageData, fresh, params);
    }
  }
}

function* loadPageData(fresh: boolean, params: any) {
  try {
    const siteId = yield* requireSelectedSite();
    const lastSelectedSite = yield select(getLastSelectedSite);
    const count: number = params.count;
    const filters: IEventFiltersParam = params.filters;
    const position: string = params.position;

    if (yield cancelled()) {
      return;
    }

    const eventsService = ApiClient.getEvents();

    const data: IApiResponse<IEventApiResult> = yield call(
      [eventsService, eventsService.getEvents],
      {
        count,
        eventTypes: [
          'tint_window',
          'tint_group',
          'execute_scene',
          'execute_collection',
        ],
        filters,
        position,
        siteId,
      },
    );

    if (yield cancelled()) {
      return;
    }

    yield put(
      actions.setEvents({
        count,
        events: data.results.events,
        filters,
        fresh,
        isMoreEvents: data.results.events.length === count,
        lastSelectedSite,
        position: data.results.position,
      }),
    );
  } finally {
    // TODO: Handle this
    if (yield cancelled()) {
      // yield put(actions.requestFailure('loadPageData cancelled!'))
    }
  }
}
