import { createStandardAction } from 'typesafe-actions';

const ENTITY = 'COMPONENTS/SITE_SUMMARY';

export default {
  load: createStandardAction(`${ENTITY}/LOAD`)(),
  setLocationCount: createStandardAction(`${ENTITY}/SET_LOCATION_COUNT`)<
    number
  >(),
  setWindowCount: createStandardAction(`${ENTITY}/SET_WINDOW_COUNT`)<number>(),
  unload: createStandardAction(`${ENTITY}/UNLOAD`)(),
};
