import { IFAQContent } from './faq-types';

export const faqList: IFAQContent[] = [
  {
    id: 'm_section_2',
    items: [
      {
        contentKey: 'faq:faq-contact-support-answer',
        id: 1,
        titleKey: 'faq-dashboard-question-1',
      },
      {
        contentKey: 'faq:faq-contact-google-answer',
        id: 2,
        titleKey: 'faq-dashboard-question-2',
      },
      {
        contentKey: 'faq-dashboard-answer-3',
        id: 3,
        titleKey: 'faq-dashboard-question-3',
      },
      {
        contentKey: 'faq-dashboard-answer-4',
        id: 4,
        titleKey: 'faq-dashboard-question-4',
      },
      {
        contentKey: 'faq-dashboard-answer-5',
        id: 5,
        titleKey: 'faq-dashboard-question-5',
      },
    ],
    sectionContentId: 'm_section_2_content',
    tabTitle: 'faq-title-dashboard',
    title: 'accordion-title-dashboard',
  },
  {
    id: 'm_section_3',
    items: [
      {
        contentKey: 'faq:faq-contact-support-answer',
        id: 1,
        titleKey: 'faq-mobile-app-question-1',
      },
      {
        contentKey: 'faq:faq-contact-google-answer',
        id: 2,
        titleKey: 'faq-mobile-app-question-2',
      },
      {
        contentKey: 'faq-mobile-app-answer-3',
        id: 3,
        titleKey: 'faq-mobile-app-question-3',
      },
      {
        contentKey: 'faq-mobile-app-answer-4',
        id: 4,
        titleKey: 'faq-mobile-app-question-4',
      },
      {
        contentKey: 'faq-mobile-app-answer-5',
        id: 5,
        titleKey: 'faq-mobile-app-question-5',
      },
    ],
    sectionContentId: 'm_section_3_content',
    tabTitle: 'faq-title-mobile-app',
    title: 'accordion-title-mobile-app',
  },
  {
    id: 'm_section_4',
    items: [
      {
        contentKey: 'faq-mobile-automation-answer-1',
        id: 1,
        titleKey: 'faq-mobile-automation-question-1',
      },
      {
        contentKey: 'faq-contact-support-answer',
        id: 2,
        titleKey: 'faq-mobile-automation-question-2',
      },
      {
        contentKey: 'faq-mobile-automation-answer-3',
        id: 3,
        titleKey: 'faq-mobile-automation-question-3',
      },
      {
        contentKey: 'faq-contact-support-answer',
        id: 4,
        titleKey: 'faq-mobile-automation-question-4',
      },
      {
        contentKey: 'faq-mobile-automation-answer-5',
        id: 5,
        titleKey: 'faq-mobile-automation-question-5',
      },
    ],
    sectionContentId: 'm_section_4_content',
    tabTitle: 'faq-title-modes',
    title: 'accordion-title-modes',
  },
  {
    id: 'm_section_7',
    items: [
      {
        contentKey: 'faq-hardware-questions-answer-1',
        id: 1,
        titleKey: 'faq-hardware-questions-question-1',
      },
    ],
    sectionContentId: 'm_section_7_content',
    tabTitle: 'faq-title-hardware',
    title: 'accordion-title-hardware-questions',
  },
  {
    id: 'm_section_5',
    items: [
      {
        contentKey: 'faq-tint-selector-answer-1',
        id: 1,
        titleKey: 'faq-tint-selector-question-1',
      },
      {
        contentKey: 'faq-tint-selector-answer-2',
        id: 2,
        titleKey: 'faq-tint-selector-question-2',
      },
      {
        contentKey: 'faq-contact-support-answer',
        id: 3,
        titleKey: 'faq-tint-selector-question-3',
      },
    ],
    sectionContentId: 'm_section_5_content',
    tabTitle: 'faq-title-tint-selector',
    title: 'accordion-title-tint-selector',
  },
  {
    id: 'm_section_6',
    items: [
      {
        contentKey: 'faq-contact-support-answer',
        id: 1,
        titleKey: 'faq-general-questions-question-1',
      },
      {
        contentKey: 'faq-general-questions-answer-2',
        id: 2,
        titleKey: 'faq-general-questions-question-2',
      },
      {
        contentKey: 'faq-general-questions-answer-3',
        id: 3,
        titleKey: 'faq-general-questions-question-3',
      },
      {
        contentKey: 'faq-general-questions-answer-5',
        id: 5,
        titleKey: 'faq-general-questions-question-5',
      },
      {
        contentKey: 'faq-general-questions-answer-6',
        id: 6,
        titleKey: 'faq-general-questions-question-6',
      },
    ],
    sectionContentId: 'm_section_6_content',
    tabTitle: 'faq-title-general-questions',
    title: 'accordion-title-general-questions',
  },
];
