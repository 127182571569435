import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const SetSceneLargeIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '125px'}
    height={props.height ? props.height : '125px'}
    viewBox="0 0 150 150"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="manual">
        <circle
          id="Oval"
          stroke="#4684BE"
          strokeWidth="6"
          fill="#FFFFFF"
          cx="75"
          cy="75"
          r="72"
        />
        <g
          id="scene-set-icon"
          transform="translate(76.050000, 75.000000) scale(-1, 1) rotate(-180.000000) translate(-76.050000, -75.000000) translate(38.925000, 37.500000)"
          fill="#4684BE"
          fillRule="nonzero"
        >
          <path
            d="M1.35,72.9 C-1.125,70.2 -0.15,67.575 7.875,53.625 C12.15,46.275 15.975,40.05 16.5,39.9 C18.975,39.075 17.4,42.9 10.65,54.525 C6.525,61.65 3.075,67.875 3,68.4 C2.325,73.575 5.175,72 16.875,60.675 C24.225,53.625 28.2,50.25 28.95,50.55 C29.55,50.775 30.075,51.225 30.075,51.6 C30.075,51.975 24.825,57.225 18.45,63.225 C6.15,74.925 4.2,76.05 1.35,72.9 Z"
            id="Path"
          />
          <path
            d="M33.075,45.75 C16.35,30 2.1,16.5 1.35,15.6 C0.6,14.7 0.075,12.825 0.075,11.1 C0.075,5.025 7.725,0.675 18.3,0.675 C26.475,0.675 30.6,1.95 34.8,5.85 L36.825,7.8 L38.925,5.85 C43.05,1.95 47.175,0.675 55.35,0.675 C62.25,0.675 67.5,2.325 70.95,5.625 C73.05,7.575 73.575,8.625 73.575,10.95 C73.575,12.6 72.975,14.775 72.225,15.825 C70.275,18.825 57.975,31.5 57.075,31.5 C55.275,31.5 56.55,28.575 59.7,25.5 C62.775,22.5 64.2,20.175 62.325,21.375 C61.2,22.125 49.425,21.9 46.8,21.075 C45.45,20.7 44.325,20.55 44.325,20.85 C44.325,21.75 58.125,46.125 63.225,54.225 C71.7,67.725 72.375,69.45 70.125,72.6 C69.3,73.8 68.25,74.25 66.225,74.25 C63.6,74.25 61.725,72.6 33.075,45.75 Z M68.025,70.2 C68.55,68.85 67.2,66.225 60.375,55.5 C57.45,50.775 51.075,39.825 46.2,31.125 C41.4,22.5 37.35,15.3 37.125,15.075 C36.975,14.925 35.85,15.6 34.725,16.65 C30.6,20.55 26.475,21.825 18.375,21.825 C15.3,21.825 12.825,21.975 12.825,22.125 C12.825,22.275 24.45,33.375 38.7,46.8 C54.9,62.1 65.175,71.25 66.15,71.25 C66.975,71.25 67.8,70.8 68.025,70.2 Z M29.175,16.875 C39.675,12 33.45,3.75 19.125,3.75 C9.6,3.75 3.075,6.825 3.075,11.25 C3.075,15.675 9.225,18.6 18.825,18.675 C24.075,18.75 25.875,18.375 29.175,16.875 Z M65.175,16.875 C75.675,12 69.45,3.75 55.125,3.75 C42.75,3.75 35.775,8.925 40.8,14.55 C43.2,17.325 47.55,18.675 54.825,18.675 C60.075,18.75 61.875,18.375 65.175,16.875 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SetSceneLargeIcon;
