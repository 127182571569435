import chartjs from 'chart.js';
import _ from 'lodash';
import { IDashboardChartInstance } from './state';

export const chartPlugins = {
  dataSeparation: {
    afterDatasetsDraw: (chartInstance: IDashboardChartInstance) => {
      if (
        !chartInstance.config.data.datasets.length ||
        !chartInstance.options.plugins.dataSeparation.isWeekly
      ) {
        return;
      }
      const ctx = chartInstance.canvas.getContext('2d');
      const pluginOptions = chartInstance.$plugins.descriptors.find(
        (plugin: chartjs.ChartPluginsOptions) =>
          plugin.plugin.id === 'dataSeparation',
      );
      const labelChangePoints = _.get(
        pluginOptions,
        'options.labelChangePoints',
        [],
      );
      const chartMetaData = chartInstance.getDatasetMeta(0).data;

      if (
        ctx &&
        chartInstance.chartArea &&
        labelChangePoints.length &&
        chartMetaData &&
        chartMetaData.length
      ) {
        labelChangePoints.forEach((labelPoint: number, index: number) => {
          const labelXPosition = chartMetaData[labelPoint]._model.x;
          const nextLabel = chartMetaData[labelChangePoints[index + 1]];

          if (nextLabel && nextLabel._model) {
            const nextLabelPosition = nextLabel._model.x;
            const rectWidth = nextLabelPosition - labelXPosition;
            ctx.strokeStyle = 'transparent';
            ctx.fillStyle = index % 2 === 0 ? '#d9d9d9' : '#f5f5f5';
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillRect(
              labelXPosition,
              chartInstance.chartArea.top,
              rectWidth,
              chartInstance.scales['y-axis-0'].height,
            );
          }
        });
      }
    },
    id: 'dataSeparation',
  },
};
