import { combineReducers } from 'redux';

import eventsBySource from './events-by-source/reducer';
import modes from './modes/reducer';
import tintByFacade from './tint-by-facade/reducer';
import tintByLocation from './tint-by-location/reducer';
import tintSummary from './tint-summary/reducer';

const Reducer = combineReducers({
  eventsBySource,
  modes,
  tintByFacade,
  tintByLocation,
  tintSummary,
});

export default Reducer;
