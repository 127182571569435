import { ActionType, getType } from 'typesafe-actions';

import IState from './state';

import actions from './actions';

import sharedActions from '../../lib/shared/actions';

import debug from '../../lib/debug';
const createDebug = debug('pages:home');

type ActionTypes =
  | ActionType<typeof actions>
  | ActionType<typeof sharedActions>;

const initialState = {
  chart: {
    datasets: {},
    labels: [],
  },
  helpMessages: {
    mapWidget: {
      hintMessage: '',
      message: 'widget-map-help-msg',
    },
    modesWidget: {
      hintMessage: 'widget-current-modes-help-hint-msg',
      message: 'widget-current-modes-help-msg',
    },
    skycameraEffect: {
      hintMessage: 'widget-skycamera-effect-hint-msg',
      message: 'widget-skycamera-effect-help-msg',
    },
    sunPositionWidget: {
      hintMessage: '',
      message: 'widget-sun-position-help-msg',
    },
    tintWidget: {
      hintMessage: 'widget-tint-location-help-hint-msg',
      message: 'widget-tint-location-help-msg',
    },
  },
  isLoading: true,
  isLoadingChart: true,
  locations: [],
};

const log = createDebug('reducer');

export default (state: IState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case getType(actions.setLocations):
      log('setLocations');
      const setLocationsAction = action as ReturnType<
        typeof actions.setLocations
      >;

      return Object.assign({}, state, {
        locations: setLocationsAction.payload,
      });
    case getType(actions.setChart):
      log('setChart');
      const setChartAction = action as ReturnType<typeof actions.setChart>;

      return Object.assign({}, state, {
        chart: setChartAction.payload,
        isLoading: false,
        isLoadingChart: false,
      });
    case getType(actions.load):
    case getType(actions.unload):
    case getType(sharedActions.setSite):
    case getType(sharedActions.clear):
      log(action.type);
      return initialState;
    case getType(actions.timeRangeChanged):
      return {
        ...state,
        isLoadingChart: true,
      };
    default:
      return state;
  }
};
