import { AxiosInstance, AxiosResponse } from 'axios';

import { IApiResponse, IArchItem } from '@halio-inc/api-client';
import ArchItems from '@halio-inc/api-client/arch-items';

export class ExtendedArchItems extends ArchItems {
  constructor(
    private readonly extClient: AxiosInstance,
    private readonly extApiUrl: string,
    private readonly extHeaders: any = {},
  ) {
    super(extClient, extApiUrl, extHeaders);
  }
  public async getArchItems(params: {
    siteId: string;
    types?: string[];
    minified?: boolean;
  }): Promise<IApiResponse<IArchItem[]>> {
    const response = await this.extClient
      .get(`${this.extApiUrl}/sites/${params.siteId}/arch/items`, {
        headers: this.extHeaders,
        params: {
          min: !!params.minified,
          types: params.types ? params.types.join(',') : undefined,
        },
      })
      .catch<AxiosResponse<IApiResponse<IArchItem[]>>>(err =>
        Promise.resolve(err.response),
      );

    return response.data;
  }
}
