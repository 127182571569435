import { all } from 'redux-saga/effects';

import eventsBySource from './events-by-source/sagas';
import modes from './modes/sagas';
import tintByFacade from './tint-by-facade/sagas';
import tintByLocation from './tint-by-location/sagas';
import tintSummary from './tint-summary/sagas';

export default function* Sagas() {
  yield all([
    eventsBySource(),
    modes(),
    tintByFacade(),
    tintByLocation(),
    tintSummary(),
  ]);
}
