import * as _ from 'lodash';
import * as React from 'react';
import { renderToString } from 'react-dom/server';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';

import './styles.css';

interface IHelpPopupProps {
  message: string;
  hintMessage?: string;
  localizeNameSpace?: string;
}

class HelpPopup extends React.Component<IHelpPopupProps & WithTranslation> {
  public onHandleIconclick = () => {
    const sweetAlert = _.get(window, 'Swal', false);
    if (sweetAlert) {
      const alertConfig = {
        confirmButtonText: 'Close',
        html: this.getHtmlTemplate(
          this.props.message,
          this.props.hintMessage,
          this.props.localizeNameSpace,
        ),
        type: 'info',
      };

      sweetAlert.fire(alertConfig);
    }
  };

  public render() {
    return (
      <div className="help-popup">
        <div className="help-popup__icon" onClick={this.onHandleIconclick}>
          <i className="m-menu__link-icon flaticon-info">&nbsp;</i>
        </div>
      </div>
    );
  }

  private getHtmlTemplate = (
    message: string = '',
    hintMessage: string = '',
    localizeNameSpace: string = '',
  ) => {
    return renderToString(
      <div className="popup-container">
        <div className="popup-message">
          {localizeNameSpace
            ? this.props.t(`${localizeNameSpace}:${message}`)
            : message}
        </div>
        <div className="popup-hint-message">
          {localizeNameSpace ? (
            <Trans i18nKey={`${localizeNameSpace}:${hintMessage}`} />
          ) : (
            hintMessage
          )}
        </div>
      </div>,
    );
  };
}

export default withTranslation()(HelpPopup);
