import * as React from 'react';

import Locale from '../../../components/locale';

interface IActivityStreamFilterSourceProps {
  readonly source?: string;
  readonly onFilter: (source: string) => any;
}

export default class ActivityStreamFilterSource extends React.Component<
  IActivityStreamFilterSourceProps,
  any
> {
  public render() {
    return (
      <div className="dropdown">
        <button
          className="btn m-btn--pill btn-primary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <Locale nameSpace="activity-stream" localeKey="filter-label" />
        </button>
        <div
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
          x-placement="bottom-start"
        >
          <button
            className="dropdown-item btn-blank"
            onClick={this.handleClick.bind(this, '')}
            data-value=""
          >
            <Locale nameSpace="activity-stream" localeKey="filter-label-all" />{' '}
            {this.props.source ? '' : <i className="la la-check" />}
          </button>
          <button
            className="dropdown-item btn-blank"
            onClick={this.handleClick.bind(this, 'dashboard')}
            data-value="dashboard"
          >
            <Locale
              nameSpace="activity-stream"
              localeKey="filter-label-dashboard"
            />{' '}
            {this.props.source === 'dashboard' ? (
              <i className="la la-check" />
            ) : (
              ''
            )}
          </button>
          <button
            className="dropdown-item btn-blank"
            onClick={this.handleClick.bind(this, 'mobile')}
            data-value="mobile"
          >
            <Locale
              nameSpace="activity-stream"
              localeKey="filter-label-mobile"
            />{' '}
            {this.props.source === 'mobile' ? (
              <i className="la la-check" />
            ) : (
              ''
            )}
          </button>
          <button
            className="dropdown-item btn-blank"
            onClick={this.handleClick.bind(this, 'api')}
            data-value="api"
          >
            <Locale nameSpace="activity-stream" localeKey="filter-label-api" />{' '}
            {this.props.source === 'api' ? <i className="la la-check" /> : ''}
          </button>
          <button
            className="dropdown-item btn-blank"
            onClick={this.handleClick.bind(this, 'keypad')}
            data-value="keypad"
          >
            <Locale
              nameSpace="activity-stream"
              localeKey="filter-label-tint-selector"
            />{' '}
            {this.props.source === 'keypad' ? (
              <i className="la la-check" />
            ) : (
              ''
            )}
          </button>
        </div>
      </div>
    );
  }

  private handleClick = (source: string) => {
    this.props.onFilter(source);
  };
}
