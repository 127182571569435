import { ISite } from '@halio-inc/api-client';

export const alphabetizeList = (
  list: ISite[],
  orderProperty: string,
): ISite[] => {
  if (!list.length) {
    return [];
  }

  const listCopy = [...list];

  listCopy.sort((a, b) => {
    const nameA = a[orderProperty].toLowerCase();
    const nameB = b[orderProperty].toLowerCase();

    if (nameA > nameB) {
      return 1;
    } else if (nameA < nameB) {
      return -1;
    }

    return 0;
  });

  return listCopy;
};

export const getLanguageKeyFromDay = (day: string) => {
  switch (day.toLowerCase()) {
    case 'monday':
      return 'day-monday';
    case 'tuesday':
      return 'day-tuesday';
    case 'wednesday':
      return 'day-wednesday';
    case 'thursday':
      return 'day-thursday';
    case 'friday':
      return 'day-friday';
    case 'saturday':
      return 'day-saturday';
    case 'sunday':
      return 'day-sunday';
    case 'today':
      return 'day-today';
    case 'tomorrow':
      return 'day-tomorrow';
    default:
      return day;
  }
};
