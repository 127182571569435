import * as _ from 'lodash';
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  race,
  select,
  take,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import sharedActions from '../../../lib/shared/actions';
import { requireSelectedSite } from '../../../lib/shared/setup-saga';
import actions from './actions';

import { IApiResponse, IArchItem, IRollup } from '@halio-inc/api-client';
import ApiClient from '../../../lib/web/api-client';

import debug from '../../../lib/debug';
const createDebug = debug('components:widgets:tint-by-location');

const log = createDebug('sagas');

export default function* TintByLocationSaga() {
  while (true) {
    // Wait for component load
    yield take(getType(actions.load));

    let handle = yield fork(loadData, true);

    while (true) {
      const {
        selectedSite,
        unload,
      }: {
        selectedSite: ReturnType<typeof sharedActions.setSite>;
        unload: ReturnType<typeof actions.unload>;
      } = yield race({
        selectedSite: take(getType(sharedActions.setSite)),
        unload: take(getType(actions.unload)),
      });

      yield cancel(handle);

      if (unload) {
        log('Page unloaded');
        break;
      }

      let fresh = false;

      if (selectedSite) {
        fresh = true;
      }

      handle = yield fork(loadData, fresh);
    }
  }
}

function* loadData(fresh: boolean) {
  try {
    const siteId = yield* requireSelectedSite();

    if (yield cancelled()) {
      log('cancel handled (1)');
      return;
    }

    let locations: IArchItem[];

    if (fresh) {
      const archItemsService = ApiClient.getArchItems();

      const archItemsResponse: IApiResponse<IArchItem[]> = yield call(
        [archItemsService, archItemsService.getArchItems],
        {
          minified: true,
          siteId,
          types: ['location'],
        },
      );

      if (yield cancelled()) {
        log('cancel handled (2)');
        return;
      }

      if (archItemsResponse.success) {
        locations = filterLocations(
          flattenLocations(archItemsResponse.results),
        );
        yield put(actions.setLocations(locations));
      } else {
        log('Failed to retrieve locations');
        return;
      }
    } else {
      const state = yield select();
      locations = state.components.widgets.tintByLocation.locations;
    }

    if (yield cancelled()) {
      log('cancel handled (3)');
      return;
    }

    const rollupsService = ApiClient.getRollups();

    const rollupsResponse: IApiResponse<IRollup[]> = yield call(
      [rollupsService, rollupsService.getLatestRollups],
      {
        objectIds: locations.map(loc => loc.id),
        objectTypes: ['location'],
        period: '5m',
        siteId,
        type: 'window_tint',
      },
    );

    if (yield cancelled()) {
      log('cancel handled (4)');
      return;
    }

    if (rollupsResponse.success) {
      const rollups: IRollup[] = filterRollups(
        rollupsResponse.results,
        locations,
      );
      log({ rollups, rollupsResponse });
      yield put(actions.setRollups(rollups));
    } else {
      log('Failed to retrieve rollups');
      return;
    }
  } finally {
    if (yield cancelled()) {
      log('cancel handled');
    }
  }
}

function flattenLocations(locations: IArchItem[]) {
  const flat: IArchItem[] = [];
  locations.forEach(item => {
    if (item.type === 'location') {
      flat.push(item, ...flattenLocations(item.items));
    }
  });
  return flat;
}

function filterLocations(locations: IArchItem[]) {
  if (!locations) {
    return [];
  }

  return locations.filter(loc => {
    const yn = require('yn');

    return !yn(
      _.get(loc, 'attributes.dashboard.hideOnHomePageGraph.value', false),
      {
        default: false,
        lenient: true,
      },
    );
  });
}

function filterRollups(rollups: IRollup[], locations?: IArchItem[]) {
  if (!locations) {
    return rollups;
  }

  if (!rollups) {
    return [];
  }

  return rollups.filter(r => {
    return locations.find(loc => loc.id === r.objectId);
  });
}
