export default (
  type: ToastrType,
  msg: string,
  title?: string,
  config?: ToastrOptions,
): JQuery<HTMLElement> | undefined => {
  if (toastr) {
    if (type === 'success') {
      return toastr.success(msg, title, config);
    } else if (type === 'error') {
      return toastr.error(msg, title, config);
    } else if (type === 'info') {
      return toastr.info(msg, title, config);
    } else if (type === 'warning') {
      return toastr.warning(msg, title, config);
    }
  }
  return undefined;
};
