import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { IEvent, ISite } from '@halio-inc/api-client';

export const formatEventsIntoSchedules = (
  events: IEvent[],
  lastSelectedSite: ISite,
) => {
  if (!events.length) {
    return {};
  }

  const eventsInSchedules = {};
  const eventScheduleList = [];
  const timezone = _.get(
    lastSelectedSite,
    'attributes.siteinfo.timezone.value',
    '',
  );

  events.forEach((event, index) => {
    const now = moment.unix(event.hdr.utc);
    if (timezone) {
      now.tz(timezone);
    }

    const month = now.get('month') + 1;
    const dayOfMonth = now.get('date');
    const year = now.get('year');
    const eventDate = `${month}-${dayOfMonth}-${year}`;
    const formattedDay = now.format('dddd');
    const formattedDate = now.format('MMMM Do YYYY');
    const eventTime = now.format('h:mm a');

    if (!eventsInSchedules[eventDate]) {
      eventsInSchedules[eventDate] = {
        date: formattedDate,
        day: formattedDay,
        events: [{ ...event, event_time: eventTime }],
      };
    } else {
      eventsInSchedules[eventDate].events.push({
        ...event,
        event_time: eventTime,
      });
    }
  });

  for (const event in eventsInSchedules) {
    if (eventsInSchedules[event]) {
      eventScheduleList.push({
        date: event,
        ...eventsInSchedules[event],
      });
    }
  }

  return eventScheduleList;
};
