import moment from 'moment';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as suncalc from 'suncalc';

import './index.css';

import NoSunPosition from './NoSunPosition';

import debug from '../../../lib/debug';
const createDebug = debug('components:widgets:sun-position');

const log = createDebug('');

interface ISunPositionsWidgetProps {
  height?: number;
  latitude?: number;
  longitude?: number;
  width?: number;
}

class SunPositionsWidget extends React.Component<
  ISunPositionsWidgetProps & WithTranslation,
  any
> {
  public render() {
    if (!this.props.latitude || !this.props.longitude) {
      return <NoSunPosition />;
    }

    const times = suncalc.getTimes(
      new Date(),
      this.props.latitude,
      this.props.longitude,
    );

    log({ props: this.props, times });

    return (
      <div className="widget-sun-positions">
        <table className="table table-striped m-table">
          <tbody>
            <tr>
              <td>{this.props.t('widget-sun-position-label-sunrise')}</td>
              <td>{moment(times.sunrise).format('hh:mm A')}</td>
            </tr>
            <tr>
              <td>{this.props.t('widget-sun-position-label-zenith')}</td>
              <td>{moment(times.solarNoon).format('hh:mm A')}</td>
            </tr>
            <tr>
              <td>{this.props.t('widget-sun-position-label-sunset')}</td>
              <td>{moment(times.sunset).format('hh:mm A')}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default withTranslation('dashboard')(SunPositionsWidget);
