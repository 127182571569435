import * as _ from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import IState from './state';

import actions from './actions';

import debug from '../../lib/debug';
const createDebug = debug('components:site-summary');

type ActionTypes = ActionType<typeof actions>;

const initialState = {
  currentLanguage: 'en-US',
  languages: [
    {
      id: 'english-3',
      isActive: false,
      languageCode: 'en-us',
      languageName: 'language-list-label-english',
      nativeLangTitle: 'English',
    },
    {
      id: 'chinese-simplified-zh-7',
      isActive: false,
      languageCode: 'zh-hans',
      languageName: 'language-list-label-chinese-simplified',
      nativeLangTitle: '简体中文）',
    },
    {
      id: 'chinese-traditional-zh-7',
      isActive: false,
      languageCode: 'zh-hant-tw',
      languageName: 'language-list-label-chinese-traditional',
      nativeLangTitle: '中国传统的）',
    },
    {
      id: 'japanese-jp-6',
      isActive: false,
      languageCode: 'ja-jp',
      languageName: 'language-list-label-japanese',
      nativeLangTitle: '日本語',
    },
    {
      id: 'korean-ko-6',
      isActive: false,
      languageCode: 'ko',
      languageName: 'language-list-label-korean',
      nativeLangTitle: '한국어',
    },
    {
      id: 'french-fr-6',
      isActive: false,
      languageCode: 'fr',
      languageName: 'language-list-label-french',
      nativeLangTitle: 'français',
    },
    {
      id: 'german-de-4',
      isActive: false,
      languageCode: 'de-de',
      languageName: 'language-list-label-german',
      nativeLangTitle: 'Deutsche',
    },
    {
      id: 'spanish-es-5',
      isActive: false,
      languageCode: 'es-gt',
      languageName: 'language-list-label-spanish',
      nativeLangTitle: 'Español',
    },
  ],
};

const log = createDebug('reducer');

export default (state: IState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case getType(actions.changeLanguage):
      log(action.type);

      const changeLanguageAction = action as ReturnType<
        typeof actions.changeLanguage
      >;
      const newActiveLanguageId = changeLanguageAction.payload;
      const chosenLanguage = state.languages.find(
        language => language.id === newActiveLanguageId,
      );
      const currentLanguage = _.get(chosenLanguage, 'languageCode', 'en-US');
      const languages = state.languages.map(language => {
        if (language.id === newActiveLanguageId) {
          language.isActive = true;
          return language;
        }

        language.isActive = false;

        return language;
      });

      return {
        ...state,
        currentLanguage,
        languages,
      };
    default:
      return state;
  }
};
