import * as _ from 'lodash';
import * as React from 'react';

import HelpPopup from '../help-popup';

import './styles.css';

interface IDashboardWidget {
  children: React.ReactNode;
  title?: string;
  tzLabelTranslation?: string;
  helpMessage?: string;
  helpHintMessage?: string;
}

const DashboardWidget: React.FC<IDashboardWidget> = props => (
  <div className="m-portlet widget-container">
    <div className="m-portlet__head">
      <div className="m-portlet__head-caption">
        <div className="row widget-section">
          <div className="m-portlet__head-title col-11 widget-content">
            <h3 className="m-portlet__head-text">
              {props.title}
              <span className="timezone-label">
                {' - '}
                {_.get(props, 'tzLabelTranslation', 'Timezone: UTC')}
              </span>
            </h3>
          </div>
          {!!(props.helpMessage || props.helpHintMessage) && (
            <div className="col-1">
              <HelpPopup
                message={props.helpMessage || ''}
                hintMessage={props.helpHintMessage || ''}
              />
            </div>
          )}
        </div>
      </div>
    </div>
    <div className="m-portlet__body">{props.children}</div>
  </div>
);

export default DashboardWidget;
