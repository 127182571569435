import { IArchState, ISite } from '@halio-inc/api-client';
import * as _ from 'lodash';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { Modal, ModalBody } from 'reactstrap';

import LoadingSpinner from '../../components/LoadingSpinner';

import CloseIcon from '../../components/svgs/CloseIcon';
import EcoIcon from '../../components/svgs/EcoIcon';
import EcoLargeIcon from '../../components/svgs/EcoLargeIcon';
import GlareControlIcon from '../../components/svgs/GlareControlIcon';
import GlareControlLargeIcon from '../../components/svgs/GlareControlLargeIcon';
import LockIcon from '../../components/svgs/LockIcon';
import ManualIcon from '../../components/svgs/ManualIcon';
import ManualLargeIcon from '../../components/svgs/ManualLargeIcon';
import SetSceneIcon from '../../components/svgs/SetSceneIcon';
import SetSceneLargeIcon from '../../components/svgs/SetSceneLargeIcon';
import SetTintIcon from '../../components/svgs/SetTintIcon';
import SetTintLargeIcon from '../../components/svgs/SetTintLargeIcon';
import TooltipItem from '../../components/widgets/tooltip-item';

import ActionTypes from '../../lib/action-types';
import IState from '../../state';
import SubHeader from '../../theme/sub-header';
import Actions from './actions';
import NoMode from './no-mode';
import IModeBrowserStateProps from './state';

import './index.css';

interface IDispatchProps {
  changeArchState: (
    {
      archItemId,
      state,
    }: {
      archItemId: string;
      state: { [name: string]: string };
    },
  ) => ReturnType<typeof Actions.changeArchState>;
  onLoad: () => ReturnType<typeof Actions.load>;
  onUnload: () => ReturnType<typeof Actions.unload>;
}

// Where the any in RouteComponentProps<any> is the interface for props.match.params
interface IModeBrowserProps
  extends RouteComponentProps<any>,
    IModeBrowserStateProps,
    IDispatchProps {
  selectedSite: ISite;
}

interface IModeBrowserState {
  archStates: IArchState[];
  modal: boolean;
  rollups: {
    [locationId: string]: string;
  };
  selectedItem?: IArchState;
}

export class ModeBrowser extends React.Component<
  IModeBrowserProps & WithTranslation,
  IModeBrowserState
> {
  public readonly state: IModeBrowserState = {
    archStates: [],
    modal: false,
    rollups: {},
  };

  public constructor(props: IModeBrowserProps & WithTranslation) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  public componentDidMount() {
    this.props.onLoad();
  }

  public componentWillUnmount() {
    this.props.onUnload();
  }

  public render() {
    if (this.props.isLoading) {
      return <LoadingSpinner height="50px" width="50px" />;
    }

    if (!this.props.hasArchItems || !this.props.hasLocations) {
      return (
        <div className="page-mode-browser">
          <div className="page-mode-header">
            <SubHeader title={this.props.t('title')} />
          </div>
          <NoMode />
        </div>
      );
    }

    const popupIconStyles: React.CSSProperties = {
      display: 'inline-block',
      fontSize: 150,
      height: 150,
      width: 150,
    };

    return (
      <div className="page-mode-browser">
        <div className="page-mode-header">
          <SubHeader title={this.props.t('title')} />
        </div>
        {this.props.hasArchItems &&
          this.props.locations.map(archState => (
            <div className="row lane-section" key={archState.id}>
              <h2 className="col-12 lane-section__header">
                {this.getArchStateModeIcon(archState, {
                  fontSize: 25,
                })}
                {archState.name}
              </h2>
              <div className="lane-section__content">
                {archState.items.map(child => (
                  <div className="lane-section__item" key={child.id}>
                    <button
                      className="btn-blank"
                      onClick={this.toggle.bind(this, child)}
                      key={child.id}
                    >
                      <div className="circle" id={`tooltip-${child.id}`}>
                        {this.checkWindowIsLocked(child.items)}
                        {_.get(this.props.rollups, `${child.id}`, '--')}%<span>
                          <TooltipItem
                            id={child.id}
                            text={this.getArchStateModeIcon(child, {}, true)}
                            target={`tooltip-${child.id}`}
                          >
                            {this.getArchStateModeIcon(child)}
                          </TooltipItem>
                        </span>
                      </div>
                      <h3 className="circle-footer">{child.name}</h3>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          fade
          centered
          className={'modal-body-container'}
        >
          <div className="modal-close-button">
            <button onClick={this.closeModal}>
              <CloseIcon />
            </button>
          </div>
          <ModalBody>
            <div className="modal-body">
              <div className="modal-body__row">
                <button
                  className="modal-body__row--item btn-blank"
                  onClick={this.changeMode.bind(this, 'glare_control')}
                >
                  {this.getModeIcon('glare_control', popupIconStyles, true)}
                  <span className="row-title">
                    {this.props.t('mode-type-label-glare-control')}
                  </span>
                </button>
                <button
                  className="modal-body__row--item btn-blank"
                  onClick={this.changeMode.bind(this, 'manual')}
                >
                  {this.getModeIcon('manual', popupIconStyles, true)}
                  <span className="row-title">
                    {this.props.t('mode-type-label-manual')}
                  </span>
                </button>
              </div>
              <div className="modal-body__row">
                <h2 className="modal-body__row-title">
                  {this.props.t('modal-cta')}
                </h2>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }

  private getModeIcon(
    mode: string,
    style: React.CSSProperties = {},
    isModal?: boolean,
  ) {
    switch (mode) {
      case 'eco':
        return (
          <React.Fragment>
            {isModal ? <EcoLargeIcon /> : <EcoIcon />}
          </React.Fragment>
        );
      case 'glare control':
      case 'glare_control':
        return (
          <React.Fragment>
            {isModal ? <GlareControlLargeIcon /> : <GlareControlIcon />}
          </React.Fragment>
        );
      case 'manual':
        return (
          <React.Fragment>
            {isModal ? <ManualLargeIcon /> : <ManualIcon />}
          </React.Fragment>
        );
      case 'set scene':
      case 'set_scene':
        return (
          <React.Fragment>
            {isModal ? <SetSceneLargeIcon /> : <SetSceneIcon />}
          </React.Fragment>
        );
      case 'set tint':
      case 'set_tint':
        return (
          <React.Fragment>
            {isModal ? <SetTintLargeIcon /> : <SetTintIcon />}
          </React.Fragment>
        );
      case 'mixed':
        return (
          <i className="m-menu__link-icon flaticon-puzzle" style={style}>
            &nbsp;
          </i>
        );
      default:
        return (
          <i className="m-menu__link-icon flaticon-info" style={style}>
            &nbsp;
          </i>
        );
    }
  }

  private getArchStateModeIcon(
    archState: IArchState,
    style: React.CSSProperties = {},
    getMode?: boolean,
  ) {
    let mode = _.get(archState, 'state.mode', 'unknown');

    const recurseModeCheck = (items?: IArchState[]) => {
      if (!items || items.length === 0) {
        return;
      }

      for (const item of items) {
        if (mode !== _.get(item, 'state.mode', mode)) {
          mode = 'mixed';
          break;
        }

        mode = _.get(item, 'state.mode', mode);

        recurseModeCheck(item.items);

        if (mode === 'mixed') {
          break;
        }
      }
    };

    recurseModeCheck(archState.items);

    if (getMode) {
      return mode;
    }

    return this.getModeIcon(mode, style);
  }

  private changeMode(mode: string) {
    if (this.state.selectedItem) {
      this.props.changeArchState({
        archItemId: this.state.selectedItem.id,
        state: { mode },
      });
    }

    this.toggle();
  }

  private toggle(item?: IArchState) {
    this.setState({
      modal: !_.get(this.state, 'modal', false),
      selectedItem: item,
    });
  }

  private closeModal() {
    this.toggle();
  }

  private checkWindowIsLocked(items: IArchState[]) {
    const lockedWindow = items.find(window =>
      _.get(window, 'state.lockedAt', false),
    );

    if (lockedWindow) {
      return (
        <div className="window-locked-container">
          <LockIcon />
        </div>
      );
    }

    return '';
  }
}

function mapStateToProps(state: IState) {
  return {
    archStates: _.get(state, 'pages.modeBrowser.archStates', []),
    hasArchItems: _.get(state, 'pages.modeBrowser.hasArchItems', false),
    hasLocations: _.get(state, 'pages.modeBrowser.hasLocations', false),
    isLoading: _.get(state, 'pages.modeBrowser.isLoading', false),
    locations: _.get(state, 'pages.modeBrowser.locations', []),
    rollups: _.get(state, 'pages.modeBrowser.rollups', []),
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    {
      changeArchState: Actions.changeArchState,
      onLoad: Actions.load,
      onUnload: Actions.unload,
    },
    dispatch,
  );

export default withTranslation(['mode-browser'])(
  withRouter(
    connect<IModeBrowserStateProps, IDispatchProps>(
      mapStateToProps,
      mapDispatchToProps,
    )(ModeBrowser),
  ),
);
