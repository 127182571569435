import { ISite } from '@halio-inc/api-client';
import * as _ from 'lodash';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import SubHeader from '../../theme/sub-header';

import DashboardWidget from '../../components/widgets/dashboard-widget';
import EventsBySourceWidget from '../../components/widgets/events-by-source';
import TintByFacadeWidget from '../../components/widgets/tint-by-facade';
import TintByLocationWidget from '../../components/widgets/tint-by-location';
import TintSummaryWidget from '../../components/widgets/tint-summary';
import IState from '../../state';

import { helpMessages } from './constants';

interface IReportingPageProps {
  readonly selectedSite?: ISite;
}

export class Reporting extends React.Component<
  IReportingPageProps & WithTranslation
> {
  public render() {
    return (
      <div className="reporting-page">
        <SubHeader title={this.props.t('title')} />
        <div className="m-content">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-xl-6">
              <DashboardWidget
                title={this.props.t('widget-events-by-source')}
                tzLabelTranslation={this.props.t('shared:label-time-zone-utc')}
                helpMessage={this.props.t(helpMessages.eventsBySource.message)}
              >
                <EventsBySourceWidget />
              </DashboardWidget>
            </div>
            <div className="col-xs-12 col-sm-12 col-xl-6">
              <DashboardWidget
                title={this.props.t('widget-tint-by-location')}
                tzLabelTranslation={this.props.t('shared:label-time-zone-utc')}
                helpMessage={this.props.t(helpMessages.tintByLocale.message)}
              >
                <TintByLocationWidget />
              </DashboardWidget>
            </div>
            <div className="col-xs-12 col-sm-12 col-xl-6">
              <DashboardWidget
                title={this.props.t('widget-tint-summary')}
                tzLabelTranslation={this.props.t('shared:label-time-zone-utc')}
                helpMessage={this.props.t(helpMessages.tintSummary.message)}
              >
                <TintSummaryWidget />
              </DashboardWidget>
            </div>
            <div className="col-xs-12 col-sm-12 col-xl-6">
              <DashboardWidget
                title={this.props.t('widget-tint-by-facade')}
                tzLabelTranslation={this.props.t('shared:label-time-zone-utc')}
                helpMessage={this.props.t(helpMessages.tintByTrend.message)}
              >
                <TintByFacadeWidget />
              </DashboardWidget>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    selectedSite: _.get(state, 'shared.lastSelectedSite', null),
  };
}

export default withTranslation(['reporting', 'shared'])(
  connect<IReportingPageProps, {}, {}>(mapStateToProps)(Reporting),
);
