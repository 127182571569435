import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const GlareControlLargeIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '125px'}
    height={props.height ? props.height : '125px'}
    viewBox="0 0 150 150"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="glarecontrol">
        <circle
          id="Oval"
          stroke="#4684BE"
          strokeWidth="6"
          fill="#FFFFFF"
          cx="75"
          cy="75"
          r="72"
        />
        <g id="icon" transform="translate(33.000000, 34.000000)">
          <g
            id="inner-circle"
            transform="translate(20.000000, 19.000000)"
            stroke="#4684BE"
            strokeWidth="2"
          >
            <path
              d="M22.7677428,0 C34.6418649,0 44.2677428,9.8497355 44.2677428,22 C44.2677428,34.1502645 34.6418649,44 22.7677428,44 C22.5107505,44 22.2548113,43.9953862 22,43.986235 L22,0.0137649646 C22.2548113,0.00461383304 22.5107505,0 22.7677428,0 Z"
              id="Path"
              fill="#FFFFFF"
            />
            <path
              d="M21.5,0 C21.7569923,0 22.0129315,0.00461383304 22.2677428,0.0137649646 L22.2677428,43.986235 C22.0129315,43.9953862 21.7569923,44 21.5,44 C9.62587788,44 0,34.1502645 0,22 C0,9.8497355 9.62587788,0 21.5,0 Z"
              id="Path"
              fill="#4684BE"
            />
          </g>
          <g id="flares" fill="#4684BE">
            <rect
              id="Rectangle"
              x="40.0769231"
              y="0"
              width="2.30769231"
              height="15.6485671"
              rx="1.15384615"
            />
            <path
              d="M58.9452935,2.31377875 C59.5875801,2.31832938 60.1071236,2.83790774 60.1116312,3.48019461 L60.2047875,16.754166 C60.2092138,17.3848697 59.7015157,17.8997442 59.070812,17.9041705 C59.0654435,17.9042082 59.0600749,17.904208 59.0547065,17.90417 C58.4124199,17.8996193 57.8928764,17.380041 57.8883688,16.7377541 L57.7952125,3.46378267 C57.7907862,2.83307901 58.2984843,2.31820447 58.929188,2.31377821 C58.9345565,2.31374053 58.9399251,2.31374071 58.9452935,2.31377875 Z"
              id="Rectangle"
              transform="translate(59.000000, 10.108974) rotate(28.000000) translate(-59.000000, -10.108974) "
            />
            <path
              d="M70.406832,16.5338834 C71.0552574,16.538434 71.5798016,17.062943 71.5843957,17.7113682 L71.6772399,30.8156019 C71.6817512,31.4523331 71.1692356,31.9721629 70.5325044,31.9766741 C70.5270846,31.9767125 70.5216647,31.9767127 70.5162449,31.9766747 C69.8678195,31.972124 69.3432753,31.4476151 69.3386812,30.7991899 L69.245837,17.6949562 C69.2413258,17.058225 69.7538413,16.5383952 70.3905726,16.5338839 C70.3959923,16.5338455 70.4014122,16.5338454 70.406832,16.5338834 Z"
              id="Rectangle"
              transform="translate(70.461538, 24.255279) rotate(-298.000000) translate(-70.461538, -24.255279) "
            />
            <path
              d="M75.9230769,32.9939668 C76.5712618,32.9939668 77.0967195,33.5194245 77.0967195,34.1676094 L77.0967195,47.2049397 C77.0967195,47.8531245 76.5712618,48.3785822 75.9230769,48.3785822 C75.274892,48.3785822 74.7494344,47.8531245 74.7494344,47.2049397 L74.7494344,34.1676094 C74.7494344,33.5194245 75.274892,32.9939668 75.9230769,32.9939668 Z"
              id="Rectangle"
              transform="translate(75.923077, 40.686275) rotate(-270.000000) translate(-75.923077, -40.686275) "
            />
            <path
              d="M72.1340703,51.0194193 C72.7700825,51.0147543 73.289454,51.5265623 73.294119,52.1625745 C73.2941605,52.1682297 73.2941603,52.173885 73.2941184,52.1795401 L73.1971146,65.2927576 C73.1923195,65.9409753 72.6679938,66.4652681 72.0197759,66.4700226 C71.3837637,66.4746876 70.8643922,65.9628796 70.8597272,65.3268674 C70.8596857,65.3212123 70.8596859,65.3155569 70.8597277,65.3099018 L70.9567316,52.1966843 C70.9615267,51.5484667 71.4858524,51.0241738 72.1340703,51.0194193 Z"
              id="Rectangle"
              transform="translate(72.076923, 58.744721) rotate(-240.000000) translate(-72.076923, -58.744721) "
            />
            <path
              d="M59.0547065,63.4683791 C59.6854098,63.4639105 60.2003184,63.9715741 60.204787,64.6022775 C60.204825,64.6076459 60.2048252,64.6130145 60.2047875,64.618383 L60.1116312,77.8923544 C60.1071236,78.5346413 59.5875801,79.0542196 58.9452935,79.0587703 C58.3145902,79.0632388 57.7996816,78.5555752 57.795213,77.9248719 C57.795175,77.9195034 57.7951748,77.9141348 57.7952125,77.9087663 L57.8883688,64.6347949 C57.8928764,63.992508 58.4124199,63.4729297 59.0547065,63.4683791 Z"
              id="Rectangle"
              transform="translate(59.000000, 71.263575) rotate(-208.000000) translate(-59.000000, -71.263575) "
            />
            <rect
              id="Rectangle"
              transform="translate(41.230769, 75.175716) rotate(-180.000000) translate(-41.230769, -75.175716) "
              x="40.0769231"
              y="67.3514329"
              width="2.30769231"
              height="15.6485671"
              rx="1.15384615"
            />
            <path
              d="M24.1760628,63.4683791 C24.8183493,63.4729297 25.3378928,63.992508 25.3424004,64.6347949 L25.4355568,77.9087663 C25.439983,78.53947 24.9322849,79.0543445 24.3015812,79.0587708 C24.2962128,79.0588085 24.2908441,79.0588083 24.2854757,79.0587703 C23.6431891,79.0542196 23.1236456,78.5346413 23.1191381,77.8923544 L23.0259817,64.618383 C23.0215554,63.9876793 23.5292536,63.4728048 24.1599572,63.4683785 C24.1653257,63.4683408 24.1706943,63.468341 24.1760628,63.4683791 Z"
              id="Rectangle"
              transform="translate(24.230769, 71.263575) rotate(-152.000000) translate(-24.230769, -71.263575) "
            />
            <path
              d="M11.8696916,50.2427837 C12.5182104,50.247224 13.0428573,50.7718348 13.0473422,51.4203533 L13.1379364,64.5202475 C13.1423423,65.1573498 12.6294406,65.677395 11.9923383,65.681801 C11.9870464,65.6818376 11.9817542,65.6818377 11.9764623,65.6818015 C11.3279435,65.6773612 10.8032966,65.1527504 10.7988117,64.5042319 L10.7082175,51.4043377 C10.7038115,50.7672354 11.2167132,50.2471902 11.8538155,50.2427843 C11.8591075,50.2427477 11.8643996,50.2427475 11.8696916,50.2427837 Z"
              id="Rectangle"
              transform="translate(11.923077, 57.962293) rotate(-117.000000) translate(-11.923077, -57.962293) "
            />
            <path
              d="M8.07692308,32.9939668 C8.72510795,32.9939668 9.25056561,33.5194245 9.25056561,34.1676094 L9.25056561,47.2049397 C9.25056561,47.8531245 8.72510795,48.3785822 8.07692308,48.3785822 C7.4287382,48.3785822 6.90328054,47.8531245 6.90328054,47.2049397 L6.90328054,34.1676094 C6.90328054,33.5194245 7.4287382,32.9939668 8.07692308,32.9939668 Z"
              id="Rectangle"
              transform="translate(8.076923, 40.686275) rotate(-90.000000) translate(-8.076923, -40.686275) "
            />
            <path
              d="M12.8239372,16.5338834 C13.4606688,16.5294148 13.9804642,17.0419652 13.9849327,17.6786968 C13.9849708,17.6841165 13.9849706,17.6895365 13.9849322,17.6949562 L13.892088,30.7991899 C13.8874939,31.4476151 13.3629497,31.972124 12.7145243,31.9766747 C12.0777928,31.9811432 11.5579974,31.4685928 11.5535288,30.8318613 C11.5534908,30.8264415 11.553491,30.8210216 11.5535294,30.8156019 L11.6463735,17.7113682 C11.6509676,17.062943 12.1755118,16.538434 12.8239372,16.5338834 Z"
              id="Rectangle"
              transform="translate(12.769231, 24.255279) rotate(-62.000000) translate(-12.769231, -24.255279) "
            />
            <path
              d="M23.8525044,3.27034781 C24.4833435,3.26609741 24.9981853,3.77404792 25.0024357,4.40488702 C25.0024701,4.40999092 25.0024703,4.41509498 25.0024362,4.42019888 L24.9138751,17.7027407 C24.9095956,18.3445886 24.3903536,18.8638669 23.748506,18.8681915 C23.1176669,18.8724419 22.6028252,18.3644914 22.5985748,17.7336523 C22.5985404,17.7285484 22.5985402,17.7234443 22.5985742,17.7183404 L22.6871354,4.43579864 C22.6914149,3.79395076 23.2106569,3.27467237 23.8525044,3.27034781 Z"
              id="Rectangle"
              transform="translate(23.800505, 11.069270) rotate(-26.000000) translate(-23.800505, -11.069270) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GlareControlLargeIcon;
