import { createStandardAction } from 'typesafe-actions';

import { IRollup } from '@halio-inc/api-client';

const ENTITY = 'COMPONENTS/WIDGETS/TINT_SUMMARY';

export default {
  load: createStandardAction(`${ENTITY}/LOAD`)(),
  setRollups: createStandardAction(`${ENTITY}/SET_ROLLUPS`)<IRollup[]>(),
  unload: createStandardAction(`${ENTITY}/UNLOAD`)(),
};
