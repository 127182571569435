import * as React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import './styles.css';

const NoMode = () => (
  <div className="no-mode">
    <div className="m-portlet">
      <div className="m-portlet__body">
        <p className="no-mode-content">
          <Trans i18nKey="mode-browser:no-modes">
            There are no modes for this site. If you would like to create modes
            please contact <Link to="/support">Halio Services</Link> to assist
            you in creating one.
          </Trans>
        </p>
      </div>
    </div>
  </div>
);

export default NoMode;
