import { createStandardAction } from 'typesafe-actions';

import { IArchState, IMode } from '@halio-inc/api-client';

const ENTITY = 'COMPONENTS/WIDGETS/MODES';

export default {
  load: createStandardAction(`${ENTITY}/LOAD`)(),
  setArchStates: createStandardAction(`${ENTITY}/SET_ARCH_STATES`)<
    IArchState[]
  >(),
  setModes: createStandardAction(`${ENTITY}/SET_MODES`)<IMode[]>(),
  unload: createStandardAction(`${ENTITY}/UNLOAD`)(),
};
