import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  race,
  take,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import sharedActions from '../../lib/shared/actions';
import { requireSelectedSite } from '../../lib/shared/setup-saga';
import actions from './actions';

import {
  IApiResponse,
  IScene,
  IScheduleEventFormatted,
} from '@halio-inc/api-client';
import ApiClient from '../../lib/web/api-client';

import debug from '../../lib/debug';
const createDebug = debug('pages:schedule');

const log = createDebug('sagas');

export default function* ModeBrowserSaga() {
  while (true) {
    // Wait for page load
    yield take(getType(actions.load));

    let handle = yield fork(LoadPageData, true);

    while (true) {
      const {
        load,
        selectedSite,
        unload,
      }: {
        load: ReturnType<typeof actions.load>;
        selectedSite: ReturnType<typeof sharedActions.setSite>;
        unload: ReturnType<typeof actions.unload>;
      } = yield race({
        load: take(getType(actions.load)),
        selectedSite: take(getType(sharedActions.setSite)),
        unload: take(getType(actions.unload)),
      });

      yield cancel(handle);

      if (unload) {
        log('Page Unloaded');
        break;
      }

      let fresh = false;

      if (selectedSite || load) {
        fresh = true;
      }

      handle = yield fork(LoadPageData, fresh);
    }
  }
}

function* LoadPageData(fresh: boolean) {
  try {
    const siteId = yield* requireSelectedSite();

    if (yield cancelled()) {
      log('cancel handeled1');
      return;
    }

    if (fresh) {
      const scheduleEventsService = ApiClient.getScheduleEvents();

      const formattedScheduleEvents: IScheduleEventFormatted = yield call(
        [
          scheduleEventsService,
          scheduleEventsService.getFormattedScheduleEvents,
        ],
        {
          siteId,
        },
      );

      if (yield cancelled()) {
        log('cancel handeled2');
        return;
      }

      if (formattedScheduleEvents) {
        yield put(actions.setFormattedScheduleEvents(formattedScheduleEvents));
      }
    }

    if (fresh) {
      const scenesService = ApiClient.getScenes();

      const scenesResponse: IApiResponse<IScene[]> = yield call(
        [scenesService, scenesService.getScenes],
        {
          siteId,
        },
      );

      if (yield cancelled()) {
        log('cancel handeled2');
        return;
      }

      if (scenesResponse.success) {
        yield put(actions.setScenes(scenesResponse.results));
      }
    }
  } finally {
    if (yield cancelled()) {
      log('cancel handeled');
    }
  }
}
