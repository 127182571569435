import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const ManualLargeIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '125px'}
    height={props.height ? props.height : '125px'}
    viewBox="0 0 150 150"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="manual">
        <circle
          id="Oval"
          stroke="#4684BE"
          strokeWidth="6"
          fill="#FFFFFF"
          cx="75"
          cy="75"
          r="72"
        />
        <g
          id="finger-icon"
          transform="translate(76.125000, 72.750000) scale(-1, 1) rotate(-180.000000) translate(-76.125000, -72.750000) translate(49.500000, 35.250000)"
          fill="#4684BE"
          fillRule="nonzero"
        >
          <path
            d="M24,74.25 C15.375,72.75 10.05,63.3 12.975,54.825 C13.5,53.25 14.25,52.5 15.3,52.5 C16.5,52.5 16.725,52.8 16.275,54.225 C13.35,63.825 17.85,71.25 26.7,71.25 C34.725,71.25 39.675,64.425 37.275,56.55 C36.75,54.825 36.525,53.175 36.75,52.95 C38.625,51 40.95,55.275 40.8,60.45 C40.725,69.075 32.625,75.675 24,74.25 Z"
            id="Path"
          />
          <path
            d="M23.175,63.225 C22.425,62.325 21.375,58.275 20.4,51.75 C19.5,46.2 18.6,41.4 18.3,41.1 C17.925,40.725 14.85,39.675 11.4,38.7 C7.125,37.425 4.5,36.15 3,34.65 C-0.6,31.125 -0.675,28.725 2.1,16.05 C4.35,6.15 4.875,4.65 6.9,2.775 C9.075,0.825 9.45,0.75 18.375,0.6 C29.925,0.45 30.825,0.6 33.45,2.1 C35.55,3.375 49.95,24.45 52.35,29.775 C53.7,32.85 52.65,35.55 49.65,36.75 C47.175,37.8 45.75,37.2 40.95,33.225 C39.225,31.875 37.125,30.75 36.3,30.75 C34.35,30.75 34.2,31.5 33.15,48 C32.475,57.75 32.025,60.75 30.975,62.25 C29.25,64.65 24.975,65.175 23.175,63.225 Z M29.025,60.6 C29.175,60.075 29.775,53.1 30.375,45.15 C31.275,33.375 31.725,30.45 32.775,29.25 C35.025,26.775 38.175,27.375 42.975,31.125 C47.775,34.875 48.525,35.1 49.575,33.075 C50.25,31.95 48.975,29.475 42.45,19.425 C38.175,12.675 33.9,6.375 33,5.475 C31.5,3.825 30.825,3.75 20.775,3.75 C13.425,3.75 9.825,4.05 8.925,4.725 C7.425,5.775 3.15,22.95 3.15,27.6 C3.15,32.175 4.65,33.45 12.9,36 C17.475,37.425 20.625,38.85 21.075,39.6 C21.45,40.35 22.35,44.775 23.1,49.5 C23.775,54.225 24.675,58.875 24.975,59.85 C25.65,61.65 28.5,62.175 29.025,60.6 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ManualLargeIcon;
