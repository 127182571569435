import * as _ from 'lodash';
import IState from '../state';

export const getLastSelectedSiteId = (state: IState) =>
  !_.get(state, 'shared.lastSelectedSite', null)
    ? undefined
    : _.get(state, 'shared.lastSelectedSite.id', undefined);
export const getLastSelectedSiteShortId = (state: IState) =>
  !_.get(state, 'shared.lastSelectedSite', null)
    ? undefined
    : _.get(state, 'shared.lastSelectedSite.id', undefined);
export const getLastSelectedSite = (state: IState) =>
  !_.get(state, 'shared.lastSelectedSite', null)
    ? undefined
    : _.get(state, 'shared.lastSelectedSite', undefined);
