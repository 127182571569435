import * as React from 'react';

import AutomationIcon from '../../svgs/AutomationIcon';
import ComputerIcon from '../../svgs/ComputerIcon';
import KeypadIcon from '../../svgs/KeypadIcon';
import MobileIcon from '../../svgs/MobileIcon';
import SettingsIcon from '../../svgs/SettingsIcon';

interface IEventContentProps {
  dataType: string;
  eventSummary: string;
  eventType: string;
  time: string;
}

const EventContent = (props: IEventContentProps) => {
  return (
    <div className="event-section__content">
      <div className="event-section__content-item--time">
        <p>{props.time}</p>
      </div>
      <div className="event-section__content-item--status">
        {getIconForDataType(props.dataType)}
      </div>
      <div className="event-section__content-item--info">
        <p>
          {props.eventSummary
            ? props.eventSummary
            : `${props.eventType} event. No summary is available`}
        </p>
      </div>
    </div>
  );
};

const getIconForDataType = (type: string) => {
  switch (type) {
    case 'dashboard':
    case 'web':
      return <ComputerIcon />;
    case 'mobile':
      return <MobileIcon />;
    case 'automation':
      return <AutomationIcon />;
    case 'keypad':
      return <KeypadIcon />;
    default:
      return <SettingsIcon />;
  }
};

export default EventContent;
