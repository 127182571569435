import { ActionType, getType } from 'typesafe-actions';

import IState from './state';

import actions from './actions';

import sharedActions from '../../../lib/shared/actions';

import debug from '../../../lib/debug';
const createDebug = debug('components:widgets:tint-by-facade');

type ActionTypes =
  | ActionType<typeof actions>
  | ActionType<typeof sharedActions>;

const initialState = {
  facades: [],
  isLoading: true,
  rollups: [],
};

const log = createDebug('reducer');

export default (state: IState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case getType(actions.setFacades):
      log({ msg: 'setFacades', action });
      const setFacadesAction = action as ReturnType<typeof actions.setFacades>;

      return Object.assign({}, state, {
        facades: setFacadesAction.payload,
        isLoading: false,
        rollups: [],
      });
    case getType(actions.setRollups):
      log('setRollups');
      const setRollupsAction = action as ReturnType<typeof actions.setRollups>;

      return Object.assign({}, state, {
        isLoading: false,
        rollups: setRollupsAction.payload,
      });
    case getType(actions.unload):
    case getType(sharedActions.setSite):
    case getType(sharedActions.clear):
      log(action.type);
      return initialState;
    case getType(actions.load):
      log(action.type);
      return state;
    default:
      return state;
  }
};
