import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const EcoIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '27px'}
    height={props.height ? props.height : '27px'}
    viewBox="0 0 50 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="eco-icon">
        <g
          id="icon"
          transform="translate(25.000000, 25.000000) scale(-1, 1) rotate(-180.000000) translate(-25.000000, -25.000000) "
        >
          <circle
            id="circle-top"
            stroke="#8788A6"
            strokeWidth="3"
            cx="20.8860759"
            cy="41.7721519"
            r="6.7278481"
          />
          <path
            d="M36.4,42.85 C33.9,42 31.9,40.5 31.7,39.4 C31.45,38.3 32.3,38.4 34.5,39.8 C35.5,40.45 37,41.15 37.75,41.3 C39.35,41.7 39.35,42 37.65,36.95 L36.85,34.55 L39.6,31.7 C42.15,29.1 42.65,28.15 43.9,23.75 C44,23.4 44.85,23.1 45.85,23.1 L47.6,23.1 L47.6,19.35 L47.6,15.6 L45.2,15.6 C42.9,15.6 42.7,15.5 41.2,13.4 C40.3,12.2 38.6,10.55 37.4,9.8 C35.5,8.6 35.1,8 34.3,5.4 L33.35,2.35 L30.85,2.35 L28.4,2.35 L27.7,4.75 L27.05,7.1 L23.15,7.1 L19.3,7.1 L18.55,4.75 C17.8,2.4 17.8,2.35 15.5,2.2 C12.85,2.05 12.35,2.55 11.8,6.05 C11.5,7.85 11.2,8.3 9.55,9.1 C0.6,13.7 -0.7,25.95 7.05,32.65 C10.85,35.9 10.2,37.65 6.35,34.6 C2.2,31.3 0.05,26.9 0.05,21.85 C0.05,18.3 0.6,16.3 2.35,13.3 C3.5,11.35 7.6,7.5 9.1,7 C9.35,6.95 9.9,5.65 10.3,4.15 C11.15,0.9 12.05,0.1 14.9,0.1 C18.25,0.1 19.35,0.75 20.05,3.05 C20.65,5.1 20.65,5.1 23.1,5.1 C25.55,5.1 25.55,5.1 26.1,3.1 C26.75,0.7 27.75,0.1 30.95,0.1 C34.15,0.1 34.95,0.75 36,4.2 C36.7,6.5 37.3,7.35 38.95,8.55 C40.05,9.35 41.6,10.8 42.3,11.75 C43.45,13.25 44,13.5 46.15,13.7 C47.55,13.85 48.9,14.3 49.15,14.65 C49.8,15.75 49.7,23.8 49,24.5 C48.65,24.85 47.8,25.1 47.1,25.1 C46,25.1 45.6,25.5 44.65,27.85 C44,29.35 42.5,31.65 41.25,32.9 L39.05,35.2 L40.05,38.25 C41.3,41.9 41.35,42.9 40.3,43.3 C39.2,43.7 38.65,43.65 36.4,42.85 Z"
            id="Path"
            fill="#8788A6"
            fillRule="nonzero"
          />
          <circle
            id="eye"
            fill="#8788A6"
            cx="36.7088608"
            cy="27.8481013"
            r="1.89873418"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default EcoIcon;
