import { ISite } from '@halio-inc/api-client';
import * as _ from 'lodash';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import EventsBySchedule from '../../components/widgets/events-by-schedule';

import Actions from './actions';

import ActionTypes from '../../lib/action-types';

import SubHeader from '../../theme/sub-header';

import IState from '../../state';
import { IEventInSchedules } from './state';

import { IEvent, IEventFiltersParam } from '@halio-inc/api-client';

import ActivityStreamError from './components/error';
import ActivityStreamFilterSource from './components/filter-source';
import ActivityStreamLoadMore from './components/load-more';

import './styles.css';

interface IStateProps {
  readonly count: number;
  readonly error?: string;
  readonly events: IEvent[];
  readonly eventsInSchedules: IEventInSchedules[];
  readonly filters?: IEventFiltersParam;
  readonly isMoreEvents?: boolean;
  readonly position?: any;
}

interface IDispatchProps {
  filtersChanged: (
    payload: IEventFiltersParam,
  ) => ReturnType<typeof Actions.filtersChanged>;
  load: ({ count }: { count: number }) => ReturnType<typeof Actions.load>;
  loadMore: (
    {
      count,
      filters,
      position,
    }: { count: number; filters?: IEventFiltersParam; position: string },
  ) => ReturnType<typeof Actions.loadMore>;
  unload: () => ReturnType<typeof Actions.unload>;
}

// Where the any in RouteComponentProps<any> is the interface for props.match.params
interface IActivityStreamProps
  extends RouteComponentProps<any>,
    IStateProps,
    IDispatchProps {
  selectedSite: ISite;
}

export class ActivityStream extends React.Component<
  IActivityStreamProps & WithTranslation,
  any
> {
  public componentDidMount() {
    this.props.load({
      count: this.props.count,
    });
  }

  public componentWillUnmount() {
    this.props.unload();
  }

  public render() {
    return (
      <div className="activity-stream">
        <div className="activity-stream-header">
          <SubHeader
            title={this.props.t('title')}
            tzoneLabelTranslation={this.props.t('shared:label-time-zone')}
            timeZone={_.get(
              this.props,
              'selectedSite.attributes.siteinfo.timezone.value',
              '',
            )}
          >
            <ActivityStreamFilterSource
              source={_.get(this, 'props.filters.source', '')}
              onFilter={this.filterEvents}
            />
          </SubHeader>
        </div>
        {this.props.events.length ? (
          <div>
            <EventsBySchedule
              events={this.props.eventsInSchedules}
              localizeNameSpace="shared"
            />
            {this.props.isMoreEvents && (
              <ActivityStreamLoadMore loadMore={this.loadMoreEvents} />
            )}
          </div>
        ) : this.props.error ? (
          <div className="activity-stream-error-container">
            <div className="m-portlet">
              <div className="m-portlet__body">
                <ActivityStreamError error={this.props.error} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  private loadMoreEvents = () => {
    this.props.loadMore({
      count: this.props.count,
      filters: this.props.filters,
      position: this.props.position,
    });
  };

  private filterEvents = (filterParam: string) => {
    this.props.filtersChanged({
      source: filterParam,
    });
  };
}

function mapStateToProps(state: IState) {
  return {
    count: _.get(state, 'pages.activityStream.count', null),
    error: _.get(state, 'pages.activityStream.error', ''),
    events: _.get(state, 'pages.activityStream.events', []),
    eventsInSchedules: _.get(
      state,
      'pages.activityStream.eventsInSchedules',
      [],
    ),
    filters: _.get(state, 'pages.activityStream.filters', { source: '' }),
    isMoreEvents: _.get(state, 'pages.activityStream.isMoreEvents', false),
    position: _.get(state, 'pages.activityStream.position', undefined),
    selectedSite: _.get(state, 'shared.lastSelectedSite', null),
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    {
      filtersChanged: Actions.filtersChanged,
      load: Actions.load,
      loadMore: Actions.loadMore,
      unload: Actions.unload,
    },
    dispatch,
  );

export default withTranslation(['activity-stream', 'shared'])(
  withRouter(
    connect<IStateProps, IDispatchProps, IActivityStreamProps>(
      mapStateToProps,
      mapDispatchToProps,
    )(ActivityStream),
  ),
);
