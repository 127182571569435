import * as React from 'react';

const NavLoading = () => (
  <ul className="m-menu__nav-loading m-menu__nav m-menu__nav--dropdown-submenu-arrow">
    <li className="m-menu__nav-loading-item">
      <p className="loading-message">Loading...</p>
    </li>
  </ul>
);

export default NavLoading;
