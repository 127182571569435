import * as React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const NoSchedule = () => (
  <div className="m-content">
    <div className="no-schedule col-12">
      <div className="m-portlet">
        <div className="m-portlet__body">
          <p className="no-schedule-content">
            <Trans i18nKey="schedule:no-schedules">
              There is no schedule created for this site. If you would like to
              create a schedule please contact{' '}
              <Link to="/support">Halio Services</Link> to assist you in
              creating one.
            </Trans>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default NoSchedule;
