import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const KeypadIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '26px'}
    height={props.height ? props.height : '26px'}
    viewBox="0 0 13 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <image
        id="ico_keypad@3x"
        x="-9"
        y="-2"
        width="30"
        height="30"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABGdBTUEAALGOfPtRkwAAA8NJREFUeAHtnT9oFEEUxufN3SkKpkuXQAKiiGhhoyJiUpg/IhZ2VqZQIgg2QmJMI0jOWFgKCqKCQpDUmhiLJCrGShAiIohcYZFGLCyCye083ybeeoYzx5qdt3eXb4rcZObN+/b97svmZos5Y9BAAARAAARAAARAAAQaiADVQi27j5zasVQ07Y5NKxnO+bgmx/zDZbe9+To3vugjf7Wc2WoBPuevMdu3+dnLbNx1ZrPVp1aYm4heCOgu3zqV8qcKem5k+g4bc77ShXkaO+Qpb9W0tmqEp4CukZnjypDDSu57Kqdq2tQcTcZdENBRk38WjyzZMWPpZzSYYMeR/fb8ytH3CaaMlSo10HLHPGDMKmqB/GFyuPOs3EPL2ccqpNaDU7t1MHNbCQ4TfWxkyGGdqYEuQQ5fxccN6+RSnTUBunQxjfwK0ErvLkAD9PoEukdf7+29Od2yflTl2RP5V7u6bs22Vp71M1qXjpbNzm0uLs27Ihd6bsyciYOme2R6sOiWP5lF96UnP3s6ztqNxNYlaGJ3Lixano9kXOD64gCQNf2r8Zx1v/PEWf+/sXUJWj4LbokKJor3MIp4e7TWmPJ+2XDy3boEnTwG/xkB2j/jFQWABmglAkoycDRAKxFQkoGjAVqJgJIMHA3QSgSUZOBogFYioCQDRwO0EgElGTgaoJUIKMnA0QCtREBJBo4GaCUCSjJwNEArEVCSgaMBWomAkgwcDdBKBJRk4GiAViKgJANHA7QSASUZOBqglQgoycDRAK1EQEkGjgZoJQJKMnA0QCsRUJKBowFaiYCSDBwN0EoElGTgaIBWIqAkA0cDtBIBJRk4elOBJjmuJEaTAwmXonBmLyc/RvkT6tSGozneCY5M9l5YP5EJbMY+TIiF1zS1ATpmiVPDHRdzRPtsltomhzrGYi5PJTzFs0k3Vu/T4c75jWXQXV2XjtZFlIwaQCfDsWoWgK6KKJmATQ1a85Tf1EDLedGFyCvEe6K+x05v/mWzwG2OJMgUor7nToqfOvid1NYW1ifblf1ylOUDOc36iTX2z2YknEyoBeyaHAcDsjOKzMWGwmtQaamBtjZzNwiC6GxQOSG9zwSmL5Afvlr59lP+or5ncjzuS2tt3ujdXTvh+/eJoWNTUuzKDs+3VqX8so2/9Gygc6HSnI+x1ECHxRy+2tFP1gz+9ezCR5VlOcnQgmzdT8qXNzwuG/belRrTbzsP9jYFnGl3xrb4+gqnwLjFbC5TyDQtFz5PTNTFg6j03xlcAQiAAAiAAAiAAAiAwD8I/AIbFbqCclcixQAAAABJRU5ErkJggg=="
      />
    </g>
  </svg>
);

export default KeypadIcon;
