import * as React from 'react';

import EventSection from './EventSection';

import { IEventInSchedules } from '../../../pages/activity-stream/state';

import './styles.css';

interface IEventsByScheduleProps {
  readonly events: IEventInSchedules[];
  readonly localizeNameSpace?: string;
}

const EventsBySchedule = (props: IEventsByScheduleProps) => {
  return (
    <div className="events-by-schedule">
      {props.events.map(event => (
        <EventSection
          key={event.date}
          date={event.date}
          day={event.day}
          dayEvents={event.events}
          localizeNameSpace={props.localizeNameSpace}
        />
      ))}
    </div>
  );
};

export default EventsBySchedule;
