import { createStandardAction } from 'typesafe-actions';

import { IArchState } from '@halio-inc/api-client';

const ENTITY = 'PAGES/MODE_BROWSER';

export default {
  changeArchState: createStandardAction(`${ENTITY}/CHANGE_ARCH_STATE`)<{
    archItemId: string;
    state: {
      [name: string]: string;
    };
  }>(),
  load: createStandardAction(`${ENTITY}/LOAD`)(),
  setArchStates: createStandardAction(`${ENTITY}/SET_ARCH_STATES`)<
    IArchState[]
  >(),
  setRollups: createStandardAction(`${ENTITY}/SET_ROLLUPS`)<{
    [locationId: string]: string;
  }>(),
  unload: createStandardAction(`${ENTITY}/UNLOAD`)(),
};
