import chartjs from 'chart.js';
import _ from 'lodash';
import * as React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Button } from 'reactstrap';

import { chartPlugins } from './chart-plugins';
import {
  IChartDataSet,
  IDashboardChartPlugins,
  IDashboardChartProps,
  IDashboardChartState,
  ITooltipBodyItem,
  ITooltipModel,
} from './state';

import './style.css';

class DashboardChart extends React.Component<
  IDashboardChartProps,
  IDashboardChartState
> {
  public chartRef = React.createRef<Line>();

  constructor(props: IDashboardChartProps) {
    super(props);

    this.state = {
      id: '',
      isSelectAllToggle: false,
    };

    this.onHandleToggleSelectAll = this.onHandleToggleSelectAll.bind(this);
    this.toggleChartLegendItems = this.toggleChartLegendItems.bind(this);
  }

  public componentDidMount() {
    const toolTipId = `tooltip_${Math.random()
      .toString(36)
      .substr(2, 9)}`;

    this.setState({
      id: toolTipId,
    });
  }

  public render() {
    const legendOptions: chartjs.ChartLegendOptions = { position: 'bottom' };
    const mode = _.get(this, 'props.mode', 'index');
    const options: chartjs.ChartOptions = {
      ...this.props.options,
      plugins: {
        ...this.props.plugins,
      },
      tooltips: {
        custom: this.handleCustomToolTip,
        enabled: false,
        intersect: true,
        mode,
      },
    };
    const chartPluginOptions = this.props.plugins
      ? this.getPlugins(this.props.plugins)
      : [];
    const getChart = (chartType: string = '') => {
      switch (chartType) {
        case 'line':
          return (
            <div className="line-chart-wrapper">
              <Line
                data={this.props.chartData}
                legend={legendOptions}
                options={options}
                plugins={chartPluginOptions}
                ref={this.chartRef}
              />
              {!!_.get(this, 'props.chartData.datasets', []).length && (
                <Button
                  data-is-active={this.state.isSelectAllToggle}
                  className="btn-select-all"
                  color="secondary"
                  id={this.props.buttonId ? this.props.buttonId : ''}
                  onClick={this.onHandleToggleSelectAll}
                  outline
                  size="sm"
                >
                  {!this.state.isSelectAllToggle
                    ? 'Unselect All'
                    : 'Select All'}
                </Button>
              )}
            </div>
          );
        default:
          return (
            <div className="bar-chart-wrapper">
              <Bar
                data={this.props.chartData}
                legend={legendOptions}
                options={options}
                ref={this.chartRef}
              />
              {!!_.get(this, 'props.chartData.datasets', []).length && (
                <Button
                  className="btn-select-all"
                  color="secondary"
                  onClick={this.onHandleToggleSelectAll}
                  outline
                  size="sm"
                >
                  {!this.state.isSelectAllToggle
                    ? 'Unselect All'
                    : 'Select All'}
                </Button>
              )}
            </div>
          );
      }
    };

    return (
      <React.Fragment>
        <div className={`chart-container ${this.props.customClass || ''}`}>
          {getChart(this.props.chartType)}
        </div>
        <div id={this.state.id} className="chart-tooltip" />
      </React.Fragment>
    );
  }

  private onHandleToggleSelectAll() {
    if (!this.chartRef || !this.chartRef.current) {
      return;
    }

    const chartInstance = this.chartRef.current.chartInstance;
    const dataSets = _.get(chartInstance, 'config.data.datasets', []);
    this.toggleChartLegendItems(dataSets, !this.state.isSelectAllToggle);
    chartInstance.update();

    this.setState({
      isSelectAllToggle: !this.state.isSelectAllToggle,
    });
  }

  private toggleChartLegendItems = (
    dataSets: IChartDataSet[],
    isHidden: boolean,
  ) => {
    dataSets.forEach((dataset: IChartDataSet) => {
      const meta = _.get(dataset, '_meta', {});

      for (const key in meta) {
        if (meta[key].hidden !== null) {
          meta[key].hidden = null;
        }
      }

      dataset.hidden = isHidden;
    });
  };

  private getPlugins = (plugins: IDashboardChartPlugins) => {
    const pluginArr: IDashboardChartPlugins[] = [];

    if (!Object.keys(plugins).length) {
      return [];
    }

    for (const pluginKey in plugins) {
      if (chartPlugins[pluginKey]) {
        pluginArr.push(chartPlugins[pluginKey]);
      }
    }

    return pluginArr;
  };

  private handleCustomToolTip = (tooltipModel: ITooltipModel) => {
    const tooltipElm = document.getElementById(this.state.id);

    if (tooltipElm) {
      tooltipElm.style.background = 'transparent';
      tooltipElm.classList.remove('caret');
      tooltipElm.innerHTML = '<div class="chart-tooltip_table"></div>';

      const chartTableRoot = tooltipElm.querySelector('.chart-tooltip_table');

      // get tooltip content
      const getBodyLines = (bodyItem: ITooltipBodyItem) => bodyItem.lines;

      if (tooltipModel.body) {
        const titleLines = tooltipModel.title || [];
        const bodyLines = tooltipModel.body.map(getBodyLines);

        let listElm = '<div class="chart-tooltip__list">';

        titleLines.forEach((title: string) => {
          listElm += `<p class="chart-tooltip__list-title">${title}</p>`;
        });

        listElm += '</div>';

        bodyLines.forEach((body, i) => {
          const colors = tooltipModel.labelColors[i];
          const style = `background: ${_.get(colors, 'backgroundColor', '')}`;
          const div = `<div class="chart-tooltip__list-item" style="${style}">&nbsp;</div>`;
          listElm += `<div class="chart-tooltip__list-container">${div}${body}</div>`;
        });

        if (chartTableRoot) {
          tooltipElm.classList.add('caret');
          const topPositionAdjustment = this.props.isTooltipOffset ? 40 : 120;
          const leftPositionAdjustment = 8;
          const tooltipStyles = {
            background: '#000000d1',
            fontFamily: tooltipModel._bodyFontFamily,
            fontSize: tooltipModel.bodyFontSize + 'px',
            fontStyle: tooltipModel._bodyFontStyle,
            left: `${tooltipModel.caretX - leftPositionAdjustment}px`,
            padding: `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`,
            top: `${tooltipModel.caretY + topPositionAdjustment}px`,
          };
          chartTableRoot.innerHTML = listElm;

          // set styles for tooltip
          for (const styleKey in tooltipStyles) {
            if (tooltipStyles[styleKey]) {
              tooltipElm.style[styleKey] = tooltipStyles[styleKey];
            }
          }
        }
      }
    }
  };
}

export default DashboardChart;
