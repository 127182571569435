import * as _ from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import * as chartjs from 'chart.js';
import 'pleasejs';
import { Doughnut } from 'react-chartjs-2';

import { IArchState, ISite } from '@halio-inc/api-client';

import ActionTypes from '../../../lib/action-types';
import IState from '../../../state';
import Actions from './actions';
import NoModesWidget from './NoModesWidget';
import IModesWidgetStateProps from './state';

import debug from '../../../lib/debug';
const createDebug = debug('components:widgets:modes');

const log = createDebug('');

interface IDispatchProps {
  onLoad: () => ReturnType<typeof Actions.load>;
  onUnload: () => ReturnType<typeof Actions.unload>;
}

interface IModesWidgetComponentProps {
  height?: number | string;
  width?: number | string;
}

// Where the any in RouteComponentProps<any> is the interface for props.match.params
interface IModesWidgetProps
  extends RouteComponentProps<any>,
    IModesWidgetComponentProps,
    IModesWidgetStateProps,
    IDispatchProps {
  selectedSite?: ISite;
}

interface IModesWdgetState {
  data: chartjs.ChartData;
}

interface IModesWidgetDefaultProps {
  selectedSite?: ISite;
}

export class ModesWidget extends React.Component<
  IModesWidgetProps,
  IModesWdgetState
> {
  public static defaultProps: IModesWidgetDefaultProps = {
    selectedSite: {
      attributes: {},
      id: '',
      name: '',
    },
  };

  public static getDerivedStateFromProps(
    props: IModesWidgetProps,
    state: IModesWdgetState,
  ) {
    const Please = require('pleasejs');
    let changed = false;

    if (props.archStates.length && props.modes.length) {
      let processedData: {
        [mode: string]: {
          windows: IArchState[];
          locations: IArchState[];
        };
      } = {};

      props.modes
        .sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }

          if (a.name < b.name) {
            return -1;
          }

          return 0;
        })
        .forEach(
          mode => (processedData[mode.mode] = { windows: [], locations: [] }),
        );

      const recurseArchTree = (
        items?: IArchState[],
        lastMode: string = 'unknown',
      ) => {
        if (!items || items.length < 1) {
          return;
        }

        items.forEach(item => {
          const mode = _.get(item, 'state.mode', lastMode).replace(' ', '_');

          if (_.get(processedData, `${mode}.${item.type}s`)) {
            processedData[mode][`${item.type}s`].push(item.id);
          }

          recurseArchTree(item.items, mode);
        });
      };

      recurseArchTree(props.archStates);

      processedData = _.reduce(
        processedData,
        (result, value, key) => {
          if (value.windows.length > 0) {
            result[key] = value;
          }

          return result;
        },
        {},
      );

      const newLabels = Object.keys(processedData);

      if (newLabels !== state.data.labels) {
        changed = true;
        state.data.labels = newLabels;
      }

      const newDataset: chartjs.ChartDataSets = {
        backgroundColor: [],
        data: [],
        hoverBackgroundColor: [],
      };

      newLabels.forEach(key => {
        newDataset.data = [
          ...newDataset.data,
          processedData[key].windows.length,
        ] as chartjs.ChartPoint[];

        newDataset.backgroundColor = newDataset.hoverBackgroundColor = [
          ...newDataset.backgroundColor,
          Please.make_color({
            full_random: true,
            golden: false,
            seed: key,
          }),
        ];
      });

      if (!state.data.datasets || newDataset !== state.data.datasets[0]) {
        changed = true;
        state.data.datasets = [newDataset];
      }
    }

    if (changed) {
      return state;
    }

    return null;
  }

  public readonly state: IModesWdgetState = {
    data: {
      datasets: [],
      labels: [],
    },
  };

  public constructor(props: IModesWidgetProps) {
    super(props);

    this.routeToModesBrowser = this.routeToModesBrowser.bind(this);
  }

  public componentDidMount() {
    this.props.onLoad();
  }

  public componentWillUnmount() {
    this.props.onUnload();
  }

  public render() {
    log(this.state.data);
    return (
      <React.Fragment>
        {this.shouldRenderWidget(this.state.data) ? (
          <div
            className="chart-container"
            style={{
              cursor: 'pointer',
              height: this.props.height || '100%',
              position: 'relative',
              width: this.props.width || '100%',
            }}
            onClick={this.routeToModesBrowser}
          >
            <Doughnut
              data={this.state.data}
              legend={{ position: 'bottom' }}
              options={{
                maintainAspectRatio: false,
                responsive: true,
              }}
            />
          </div>
        ) : (
          <NoModesWidget />
        )}
      </React.Fragment>
    );
  }

  private routeToModesBrowser() {
    if (!this.props.selectedSite) {
      return;
    }

    this.props.history.push(`/${this.props.selectedSite.id}/mode-browser`);
  }

  private shouldRenderWidget = (chartData: chartjs.ChartData) => {
    const dataSet = _.get(chartData, 'datasets', false);
    const shouldRender = dataSet
      ? _.get(dataSet, '[0].data', []).length
      : false;

    return !!shouldRender;
  };
}

function mapStateToProps(state: IState) {
  return {
    archStates: _.get(state, 'components.widgets.modes.archStates', []),
    modes: _.get(state, 'components.widgets.modes.modes', []),
    selectedSite: _.get(state, 'shared.lastSelectedSite', null),
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    {
      onLoad: Actions.load,
      onUnload: Actions.unload,
    },
    dispatch,
  );

export default withRouter(
  connect<IModesWidgetStateProps, IDispatchProps, IModesWidgetComponentProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(ModesWidget),
);
