import * as chartjs from 'chart.js';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { ISite } from '@halio-inc/api-client';

import ActionTypes from '../../../lib/action-types';
import IState from '../../../state';

import LoadingIndicator from '../../LoadingIndicator';
import DashboardChart from '../dashboard-chart';

import Actions from './actions';
import IEventsBySourceWidgetStateProps from './state';

import './index.css';

interface IDispatchProps {
  onLoad: () => ReturnType<typeof Actions.load>;
  onUnload: () => ReturnType<typeof Actions.unload>;
}

interface IEventsBySourceWidgetProps
  extends RouteComponentProps<any>,
    IEventsBySourceWidgetStateProps,
    IDispatchProps {
  selectedSite: ISite;
}

class EventsBySourceWidget extends React.Component<
  IEventsBySourceWidgetProps,
  any
> {
  public render() {
    const chartData: chartjs.ChartData = this.getChartData();

    return (
      <div className="widget-events-by-source">
        {this.props.isLoading && (
          <LoadingIndicator height="90px" width="90px" />
        )}
        <DashboardChart
          chartData={chartData}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              xAxes: [
                {
                  stacked: true,
                },
              ],
              yAxes: [
                {
                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          }}
        />
      </div>
    );
  }

  public componentDidMount() {
    this.props.onLoad();
  }

  public componentWillUnmount() {
    this.props.onUnload();
  }

  private getChartData() {
    const data: { labels: string[]; datasets: any[] } = {
      datasets: [],
      labels: [],
    };

    if (!this.props.rollups) {
      return data;
    }

    const shapedData = {};
    const labels: string[] = [];

    this.props.rollups
      .sort((a, b) => {
        // Sort by periodEnd, then subtype
        if (a.periodEnd === b.periodEnd) {
          if (a.subtype === b.subtype) {
            return 0;
          }
          return a.subtype < b.subtype ? -1 : 1;
        }
        return a.periodEnd < b.periodEnd ? -1 : 1;
      })
      .forEach(r => {
        const dateStr = moment(r.periodEnd).format('MMM D');
        const source = r.subtype;
        const value = Number.parseInt(r.value, 10);

        if (!shapedData[source]) {
          shapedData[source] = {};
        }

        if (!shapedData[source][dateStr]) {
          shapedData[source][dateStr] = {};
        }

        shapedData[source][dateStr] = Number.isNaN(value) ? 0 : value;

        if (!labels.includes(dateStr)) {
          labels.push(dateStr);
        }
      });

    const Please = require('pleasejs');
    const colors = Please.make_color({
      colors_returned: Object.keys(shapedData).length,
      seed: '2019', // randomish value that produces diverse and relatively pleasing palette
    });

    const datasets: any[] = [];
    Object.keys(shapedData).forEach((source, idx) => {
      let color: any;
      if (idx < colors.length) {
        color = colors[idx];
      } else {
        color = Please.make_color({ seed: source });
      }

      datasets.push({
        backgroundColor: Array(labels.length).fill(color),
        data: labels.map(lbl => shapedData[source][lbl] || 0),
        label: getSourceDisplayName(source),
      });
    });

    data.labels = labels;
    data.datasets = datasets;

    return data;
  }
}

function getSourceDisplayName(source: string) {
  switch (source) {
    case 'bms':
    case 'api':
      // ALL CAPS
      return source.toUpperCase();
    default:
      // Capital first letter
      return source.charAt(0).toUpperCase() + source.slice(1).toLowerCase();
  }
}

function mapStateToProps(state: IState) {
  return {
    isLoading: _.get(
      state,
      'components.widgets.eventsBySource.isLoading',
      false,
    ),
    rollups: _.get(state, 'components.widgets.eventsBySource.rollups', null),
    selectedSite: _.get(state, 'shared.lastSelectedSite', null),
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    {
      onLoad: Actions.load,
      onUnload: Actions.unload,
    },
    dispatch,
  );

export default withRouter(
  connect<IEventsBySourceWidgetStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(EventsBySourceWidget),
);
