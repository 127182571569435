import * as React from 'react';

import EcoIcon from '../../components/svgs/EcoIcon';
import GlareControlIcon from '../../components/svgs/GlareControlIcon';
import ManualIcon from '../../components/svgs/ManualIcon';
import SetSceneIcon from '../../components/svgs/SetSceneIcon';
import SetTintIcon from '../../components/svgs/SetTintIcon';

interface IModeIconProps {
  modeType: string;
  isLarge?: boolean;
}

const ModeIcon = (props: IModeIconProps) => {
  switch (props.modeType) {
    case 'eco':
      return (
        <React.Fragment>
          <EcoIcon />
        </React.Fragment>
      );
    case 'glare control':
    case 'glare_control':
      return (
        <React.Fragment>
          <GlareControlIcon />
        </React.Fragment>
      );
    case 'manual':
      return (
        <React.Fragment>
          <ManualIcon />
        </React.Fragment>
      );
    case 'set scene':
    case 'set_scene':
      return (
        <React.Fragment>
          <SetSceneIcon />
        </React.Fragment>
      );
    case 'set tint':
    case 'set_tint':
      return (
        <React.Fragment>
          <SetTintIcon />
        </React.Fragment>
      );
    case 'mixed':
      return <i className="m-menu__link-icon flaticon-puzzle">&nbsp;</i>;
    default:
      return <i className="m-menu__link-icon flaticon-info">&nbsp;</i>;
  }
};

export default ModeIcon;
