import * as React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.css';

interface ISubHeaderProps {
  children?: React.ReactNode;
  localizeNameSpace?: string;
  title: string;
  timeZone?: string;
  tzoneLabelTranslation?: string;
}

const SubHeader = (props: ISubHeaderProps) => {
  const { t } = useTranslation(props.localizeNameSpace);
  const tzLabelTranslation = props.tzoneLabelTranslation
    ? props.tzoneLabelTranslation
    : 'Timezone:';

  return (
    <div className="m-subheader sub-header">
      <div className="d-flex align-items-center">
        <div className="mr-auto">
          <h3 className="m-subheader__title ">
            {props.localizeNameSpace ? t(`${props.title}`) : props.title}
            {props.timeZone && (
              <span className="time-zone-label">
                {' '}
                - {tzLabelTranslation} {props.timeZone}
              </span>
            )}
          </h3>
        </div>
        {props.children}
      </div>
    </div>
  );
};

export default SubHeader;
