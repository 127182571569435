import { createStandardAction } from 'typesafe-actions';

import { IArchItem, IRollup } from '@halio-inc/api-client';

const ENTITY = 'COMPONENTS/WIDGETS/TINT_BY_FACADE';

export default {
  load: createStandardAction(`${ENTITY}/LOAD`)(),
  setFacades: createStandardAction(`${ENTITY}/SET_FACADES`)<IArchItem[]>(),
  setRollups: createStandardAction(`${ENTITY}/SET_FACADE_ROLLUPS`)<IRollup[]>(),
  unload: createStandardAction(`${ENTITY}/UNLOAD`)(),
};
