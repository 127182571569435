import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const SetTintIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '27px'}
    height={props.height ? props.height : '27px'}
    viewBox="0 0 62 62"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="tintset-icon">
        <circle
          id="Oval"
          stroke="#8689A6"
          fill="#FFFFFF"
          cx="31"
          cy="31"
          r="30.5"
        />
        <g id="circle" transform="translate(5.998565, 6.000000)">
          <circle id="Oval" fill="#878787" cx="25.0014348" cy="25" r="25" />
          <path
            d="M17,1.3582355 L17,48.6816406 C7.2113957,45.3821411 -0.117827929,36.052002 0.00143476644,25 C-0.00112871012,13.8485107 7.40927168,4.36975098 17,1.3582355 Z"
            id="Path"
            fill="#D8D8D8"
          />
          <path
            d="M32.0014348,1 C41.8845841,3.44458008 50.0014348,13.1784668 50.0014348,25.1100797 C49.7546086,37.1862793 41.852509,46.1425781 32.0014348,49.0415039 L32.0014348,1 Z"
            id="Path"
            fill="#000000"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SetTintIcon;
