import * as React from 'react';
import { Tooltip } from 'reactstrap';

interface ITooltipProps {
  children?: React.ReactNode;
  id: string;
  target: string;
  text: string;
}

interface ITooltipState {
  tooltipOpen: boolean;
}

class TooltipItem extends React.Component<ITooltipProps, ITooltipState> {
  constructor(props: ITooltipProps) {
    super(props);

    this.state = {
      tooltipOpen: false,
    };
  }

  public render() {
    return (
      <React.Fragment>
        {this.props.children}
        <Tooltip
          placement="bottom"
          isOpen={this.state.tooltipOpen}
          target={this.props.target}
          toggle={this.toggle}
        >
          {this.getModeName(this.props.text)}
        </Tooltip>
      </React.Fragment>
    );
  }

  private toggle = () => {
    this.setState(state => ({
      tooltipOpen: !state.tooltipOpen,
    }));
  };

  private getModeName = (modeName: string) => {
    switch (modeName) {
      case 'manual':
        return 'Manual';
      case 'glare_control':
        return 'Glare Control';
      case 'eco':
        return 'Eco';
      case 'set tint':
      case 'set_tint':
        return 'Tint Set';
      case 'set scene':
      case 'set_scene':
        return 'Scene Set';
      default:
        return 'Mode';
    }
  };
}

export default TooltipItem;
