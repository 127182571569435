import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import moment from 'moment';

import { IRollup, ISite } from '@halio-inc/api-client';

import ActionTypes from '../../../lib/action-types';
import IState from '../../../state';
import LoadingIndicator from '../../LoadingIndicator';

import DashboardChart from '../dashboard-chart';
import Actions from './actions';
import ITintByFacadeWidgetStateProps from './state';

import './index.css';

import debug from '../../../lib/debug';
const createDebug = debug('components:widgets:tint-by-facade');

const log = createDebug('');

interface IDispatchProps {
  onLoad: () => ReturnType<typeof Actions.load>;
  onUnload: () => ReturnType<typeof Actions.unload>;
}

interface ITintByFacadeWidgetProps
  extends RouteComponentProps<any>,
    ITintByFacadeWidgetStateProps,
    IDispatchProps {
  selectedSite: ISite;
}

class TintByFacadeWidget extends React.Component<
  ITintByFacadeWidgetProps,
  any
> {
  public render() {
    const chartData = this.getChartData();

    log({ chartData });

    return (
      <div className="widget-tint-by-facade">
        {this.props.isLoading && (
          <LoadingIndicator height="90px" width="90px" />
        )}
        <DashboardChart
          chartData={chartData}
          chartType="line"
          mode="nearest"
          options={{
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  type: 'time',
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    max: 100,
                  },
                },
              ],
            },
          }}
        />
      </div>
    );
  }

  public componentDidMount() {
    this.props.onLoad();
  }

  public componentWillUnmount() {
    this.props.onUnload();
  }

  private getChartData() {
    log({
      facades: this.props.facades,
      rollups: this.props.rollups,
    });

    const data: { datasets: any[]; labels: any[] } = {
      datasets: [],
      labels: [],
    };

    if (!this.props.facades || !this.props.rollups) {
      return data;
    }

    const Please = require('pleasejs');
    const colors = this.props.facades.map(f => {
      return Please.make_color({ seed: f.id });
    });

    // Organize rollups for easier access
    const rollups: {
      [facadeId: string]: IRollup[];
    } = {};
    this.props.rollups.forEach(r => {
      if (!rollups[r.objectId]) {
        rollups[r.objectId] = [];
      }
      rollups[r.objectId].push(r);
    });

    // Iterate facades in order to generate chart data
    this.props.facades.forEach(facade => {
      if (!rollups[facade.id]) {
        return;
      }

      const color = colors.pop();
      const dataColor = _.get(color, '[0]', '');
      data.datasets.push({
        backgroundColor: dataColor,
        borderColor: dataColor,
        data: rollups[facade.id].map(r => {
          return {
            x: moment(r.periodEnd, moment.ISO_8601).toDate(),
            y: Number.parseInt(r.value, 10),
          };
        }),
        fill: false,
        label: facade.name,
      });
    });

    return data;
  }
}

function mapStateToProps(state: IState) {
  return {
    facades: _.get(state, 'components.widgets.tintByFacade.facades', null),
    isLoading: _.get(state, 'components.widgets.tintByFacade.isLoading', false),
    rollups: _.get(state, 'components.widgets.tintByFacade.rollups', null),
    selectedSite: _.get(state, 'shared.lastSelectedSite', null),
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    {
      onLoad: Actions.load,
      onUnload: Actions.unload,
    },
    dispatch,
  );

export default withRouter(
  connect<ITintByFacadeWidgetStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(TintByFacadeWidget),
);
