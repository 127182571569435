import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const MobileIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '26px'}
    height={props.height ? props.height : '26px'}
    viewBox="0 0 16 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <image
        id="ico_mobile@3x"
        x="-7"
        y="-2"
        width="30"
        height="30"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABGdBTUEAALGOfPtRkwAABFRJREFUeAHt3U9oFUccB/D5zXvJJSJCQw8NGBRSpdBLW6NUIymCStVeSnsqFHqIUIuH3vL0mggFD+If1INS9KQHQWprvCQSWjBIC20ppbZJk0NRTFVEPeRl59dZyQtjSMIv/nbGjf3uJbPzfr+Z3372Zd7bw9s1BhsEIAABCEAAAhCAwEskQCmO5aMLXHl8a+jNjGgDMbelmHMpc7ChzJAZGqh1Dy8lbymx0aHf7x9+PeP6OWbTuZTCkscS8Z+XD68bvfHNrRhzV2MM2hhz91fXO6bq9R8Nm5ZGX2n/MpPjylpfXxRoG+vA2Rden3JnlgWyR/D/2vcqVftTLI9o7+iOTR+2rd3zxZZG4WToNllzkMiONfpK85ezzFLTz3/9cOl+rJqiQU/RdHtYtIfef7XWfTHs+z+1o0H7paM1hORq9bdwf7F2z6mbTX9PPjxGbLb5uOerkcxjNvbktQPdRxebK9Vrz3cQguoq/jtTGGad43B/sfb4v48+8Wt7jzhhvsHyZOIjuw4NX7nS2zU6X0jKvmgfhikPYjnMFe0drTn49ldWnPdLxyb90kEny/Buzi1KCX167zt1X9tezckqW24yaOasc+ehwVfLBpDXY8m6rLnp14Ev370Xq75k0I75rMliHYZuXJcXNu0erNn4wfqxG5fv6EabPxsfhrMuvMoZ9/bsbsENQAegxNQc7BbaTLZ0GLInrOG7hVavHMx/1W71F1b7lMOI0pNBV8ge/662VXx1KKpeGbS9f3C9vzBKAo2lQ3mypOmAlkop4wCtBJSmA1oqpYwDtBJQmg5oqZQyDtBKQGk6oKVSyjhAKwGl6YCWSinjAK0ElKYDWiqljAO0ElCaDmiplDIO0EpAaTqgpVLKOEArAaXpgJZKKeMArQSUpgNaKqWMA7QSUJoOaKmUMg7QSkBpOqClUso4QCsBpemAlkop4wCtBJSmA1oqpYwDtBJQmg5oqZQyDtBKQGk6oKVSyjhAKwGl6YCWSinjAK0ElKYDWiqljAO0ElCaDmiplDIO0EpAaTqgpVLKOEArAaXpgJZKKeMArQSUpif7LXjGbt/OvsFy/eie/Y/upVLKuGTQht3nTlnsck7H0pHo7AE6EXSypYOo8qm/R9REouMSTcPOrvY31fpaFKwMSgbtbxo8MtD73u/KegtNn7kxSqFjLjQYlo6FZAruB3TBoAsNB+iFZArujwadkb8t1DLbYtYcDZqIJkNna+wb4X4Z2nNrmltzkTVG+9bRzNXxsFDH2VF/Cb6SrJ0I+19U29/Cs53Z9YXzz605fE3bfuZm2trBwvz8YQo7+oeGDfPmsL+0baLv/XNYuvy7OsqSF3Pp4CpVP/Nn8klpcWcKy2t8Wmsk5HyaaND54N/Wuv6gpua3DNHNfL+Um68trzGvNWZ90ZaOsOjGI5ymDXdaQ6+Fr72otjP8T9XQSEtH9y8XP/aPcMIGAQhAAAIQgAAEIAABucB/b1vtqG79iQoAAAAASUVORK5CYII="
      />
    </g>
  </svg>
);

export default MobileIcon;
