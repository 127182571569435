import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  race,
  take,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import sharedActions from '../../../lib/shared/actions';
import { requireSelectedSite } from '../../../lib/shared/setup-saga';
import actions from './actions';

import { IApiResponse, IArchState, IMode } from '@halio-inc/api-client';

import ApiClient from '../../../lib/web/api-client';

import debug from '../../../lib/debug';
const createDebug = debug('components:widgets:modes');

const log = createDebug('sagas');

export default function* ModeBrowserSaga() {
  while (true) {
    // Wait for component load
    yield take(getType(actions.load));

    let handle = yield fork(LoadPageData, true);

    while (true) {
      const {
        selectedSite,
        unload,
      }: {
        selectedSite: ReturnType<typeof sharedActions.setSite>;
        unload: ReturnType<typeof actions.unload>;
      } = yield race({
        selectedSite: take(getType(sharedActions.setSite)),
        unload: take(getType(actions.unload)),
      });

      yield cancel(handle);

      if (unload) {
        log('Page Unloaded');
        break;
      }

      let fresh = false;

      if (selectedSite) {
        fresh = true;
      }

      handle = yield fork(LoadPageData, fresh);
    }
  }
}

function* LoadPageData(fresh: boolean) {
  try {
    const siteId = yield* requireSelectedSite();

    if (yield cancelled()) {
      log('cancel handeled1');
      return;
    }

    if (fresh) {
      const modesService = ApiClient.getModes();
      const modesResponse: IApiResponse<IMode[]> = yield call(
        [modesService, modesService.getModes],
        {
          siteId,
        },
      );

      if (yield cancelled()) {
        log('cancel handeled2');
        return;
      }

      if (modesResponse.success) {
        yield put(actions.setModes(modesResponse.results));
      }
    }

    const archStatesService = ApiClient.getArchStates();

    const archStatesResponse: IApiResponse<IArchState[]> = yield call(
      [archStatesService, archStatesService.getArchStates],
      {
        siteId,
        types: ['window'],
      },
    );

    if (yield cancelled()) {
      log('cancel handeled3');
      return;
    }

    if (archStatesResponse.success) {
      yield put(actions.setArchStates(archStatesResponse.results));
    }
  } finally {
    if (yield cancelled()) {
      log('cancel handeled');
    }
  }
}
