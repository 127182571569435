import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  race,
  take,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import sharedActions from '../../lib/shared/actions';
import { requireSelectedSite } from '../../lib/shared/setup-saga';
import actions from './actions';

import { IApiResponse, IArchItem } from '@halio-inc/api-client';
import ApiClient from '../../lib/web/api-client';

import debug from '../../lib/debug';
const createDebug = debug('components:widgets:tint-summary');

const log = createDebug('sagas');

export default function* TintSummarySaga() {
  while (true) {
    // Wait for component load
    yield take(getType(actions.load));

    let handle = yield fork(loadData, true);

    while (true) {
      const {
        selectedSite,
        unload,
      }: {
        selectedSite: ReturnType<typeof sharedActions.setSite>;
        unload: ReturnType<typeof actions.unload>;
      } = yield race({
        selectedSite: take(getType(sharedActions.setSite)),
        unload: take(getType(actions.unload)),
      });

      yield cancel(handle);

      if (unload) {
        log('Page unloaded or user logged out');
        break;
      }

      let fresh = false;

      if (selectedSite) {
        fresh = true;
      }

      handle = yield fork(loadData, fresh);
    }
  }
}

function* loadData(fresh: boolean) {
  try {
    const siteId = yield* requireSelectedSite();

    if (yield cancelled()) {
      log('cancel handled (1)');
      return;
    }

    const archItemsService = ApiClient.getArchItems();

    const response: IApiResponse<IArchItem[]> = yield call(
      [archItemsService, archItemsService.getArchItems],
      {
        minified: true,
        siteId,
        types: ['location', 'window'],
      },
    );

    if (yield cancelled()) {
      log('cancel handled (2)');
      return;
    }

    if (response.success) {
      const items = response.results;
      const counts = countItems(items);

      yield put(actions.setLocationCount(counts.location));
      yield put(actions.setWindowCount(counts.window));
    } else {
      log('Failed to retrieve window/location data.');
      return;
    }
  } finally {
    if (yield cancelled()) {
      log('cancel handled');
    }
  }
}

function countItems(items: IArchItem[]): { location: number; window: number } {
  const results = {
    location: 0,
    window: 0,
  };

  items.forEach(item => {
    if (['location', 'window'].includes(item.type)) {
      results[item.type]++;
    }

    if (item.items) {
      const descendantCounts = countItems(item.items);

      Object.keys(results).forEach(key => {
        results[key] += descendantCounts[key];
      });
    }
  });

  return results;
}
