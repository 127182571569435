import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { Bar } from 'react-chartjs-2';

import { ISite } from '@halio-inc/api-client';

import ActionTypes from '../../../lib/action-types';
import IState from '../../../state';
import LoadingIndicator from '../../LoadingIndicator';

import Actions from './actions';
import ITintSummaryWidgetStateProps from './state';

import debug from '../../../lib/debug';

import './styles.css';

const createDebug = debug('components:widgets:tint-summary');

const log = createDebug('');

interface IDispatchProps {
  onLoad: () => ReturnType<typeof Actions.load>;
  onUnload: () => ReturnType<typeof Actions.unload>;
}

interface ITintSummaryWidgetComponentProps {
  height?: string;
  width?: string;
}

interface ITintSummaryWidgetProps
  extends RouteComponentProps<any>,
    ITintSummaryWidgetComponentProps,
    ITintSummaryWidgetStateProps,
    IDispatchProps {
  selectedSite: ISite;
}

class TintSummaryWidget extends React.Component<ITintSummaryWidgetProps, any> {
  public static defaultProps = {
    height: '200px',
    width: '100%',
  };

  public render() {
    const chartData = this.getChartData();

    log(chartData);

    return (
      <div className="widget-tint-summary">
        {this.props.isLoading && (
          <LoadingIndicator height="90px" width="90px" />
        )}
        <div className="chart-container">
          <Bar
            data={{
              datasets: [
                {
                  data: chartData.values,
                  fill: false,
                },
              ],
              labels: chartData.labels,
            }}
            legend={{ display: false }}
            options={{
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>
    );
  }

  public componentDidMount() {
    this.props.onLoad();
  }

  public componentWillUnmount() {
    this.props.onUnload();
  }

  private getChartData() {
    const data: { labels: string[]; values: number[] } = {
      labels: [
        '0',
        '10',
        '20',
        '30',
        '40',
        '50',
        '60',
        '70',
        '80',
        '90',
        '100',
      ],
      values: Array(11).fill(0),
    };

    if (!this.props.rollups) {
      return data;
    }

    this.props.rollups.forEach(r => {
      const level = Number.parseFloat(r.value);
      if (Number.isNaN(level)) {
        return;
      }

      const idx: number = Math.floor(level / 10);
      data.values[idx] += 1;
    });

    return data;
  }
}

function mapStateToProps(state: IState) {
  return {
    isLoading: _.get(state, 'components.widgets.tintSummary.isLoading', false),
    rollups: _.get(state, 'components.widgets.tintSummary.rollups', null),
    selectedSite: _.get(state, 'shared.lastSelectedSite', null),
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    {
      onLoad: Actions.load,
      onUnload: Actions.unload,
    },
    dispatch,
  );

export default withRouter(
  connect<
    ITintSummaryWidgetStateProps,
    IDispatchProps,
    ITintSummaryWidgetComponentProps
  >(
    mapStateToProps,
    mapDispatchToProps,
  )(TintSummaryWidget),
);
