import { combineReducers, Reducer } from 'redux';

import { routerReducer } from 'react-router-redux';

import { reducer as form } from 'redux-form';

import shared from './lib/shared/reducer';
import { IAppState } from './state';

import components from './components/reducer';
import pages from './pages/reducer';

const reducer: Reducer<IAppState> = combineReducers({
  components,
  form,
  pages,
  router: routerReducer,
  shared,
});

export default reducer;
