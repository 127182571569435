import classNames from 'classnames';
import { orderBy } from 'lodash';
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, RouteComponentProps } from 'react-router-dom';

import { IApiResponse, IArchItem, IScene } from '@halio-inc/api-client';

import LoadingSpinner from 'components/LoadingSpinner';
import SortableHeader, {
  useSortColumn,
} from 'components/table/sortable-header';

import notifier from 'lib/shared/notifier';
import ApiClient from 'lib/web/api-client';

import { flattenArchItems, ISetWindows, setWindowLocations } from './utils';

const sortTypes = {
  location: 'location',
  name: 'name',
  windowCount: 'windowCount',
};
interface ISceneWithLocations extends IScene {
  sceneWindows: Array<{ archItemId: string }>;
  locations: IArchItem[];
}
interface IArchItemUnique {
  [key: string]: IArchItem;
}

interface ISceneRowProps {
  siteId: string;
  scene: ISceneWithLocations;
}
const SceneRow: React.FC<ISceneRowProps> = ({ siteId, scene }) => {
  const queryClient = useQueryClient();
  const [modelRef, setModelRef] = useState<HTMLDivElement | null>();
  const [modalType, setModalType] = useState<'delete' | 'activate' | undefined>(
    undefined,
  );

  const handleCloseModal = useCallback(
    () => {
      setModalType(undefined);
      if (modelRef) {
        ($(modelRef) as any).modal('hide');
      }
    },
    [setModalType, modelRef],
  );

  // Delete Functions
  const deleteSceneMutation = useMutation(
    (sceneId: string) => {
      return ApiClient.rawDelete(`sites/${siteId}/scenes/${sceneId}`);
    },
    {
      onError: e => {
        // Need to log to sentry or some remote log feature
        // tslint:disable-next-line: no-console
        console.log(e);
        notifier(
          'error',
          'An error occurred while attempting to delete the Scene. Please try again.',
        );
      },
      onSuccess: () => {
        queryClient.invalidateQueries(`${siteId}-scenes`);
        handleCloseModal();
        notifier('success', `Scene "${scene.name}" has been deleted`);
      },
    },
  );
  const deleteScene = useCallback(
    () => {
      deleteSceneMutation.mutate(scene.id);
    },
    [deleteSceneMutation, scene.id],
  );
  const requestDeleteScene = useCallback(
    () => {
      if (modelRef) {
        setModalType('delete');
        ($(modelRef) as any).modal('show');
      }
    },
    [setModalType, modelRef],
  );

  // Execute Functions
  const executeSceneMutation = useMutation(
    (sceneId: string) => {
      return ApiClient.rawPost(`sites/${siteId}/scenes/${sceneId}/execute`, {
        level: 100,
      });
    },
    {
      onError: e => {
        // Need to log to sentry or some remote log feature
        // tslint:disable-next-line: no-console
        console.log(e);
        notifier(
          'error',
          'An error occurred while attempting to executing the Scene. Please try again.',
        );
      },
      onSuccess: () => {
        handleCloseModal();
        notifier(
          'success',
          `Scene "${
            scene.name
          }" has been activated and windows should change tint soon.`,
        );
      },
    },
  );
  const activateScene = useCallback(
    () => {
      executeSceneMutation.mutate(scene.id);
    },
    [executeSceneMutation, scene.id],
  );
  const requestSceneActivate = useCallback(
    () => {
      if (modelRef) {
        setModalType('activate');
        ($(modelRef) as any).modal('show');
      }
    },
    [setModalType, modelRef],
  );

  return (
    <tr>
      <td>{scene.name}</td>
      <td>
        {scene.locations.length
          ? scene.locations.map(l => l.name).join(', ')
          : 'Unknown'}
      </td>
      <td>{scene.sceneWindows.length}</td>
      <td>
        <div className="row w-100">
          <div ref={e => setModelRef(e)} className="modal show">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {modalType === 'activate' &&
                      `Activate Scene "${scene.name}"?`}
                    {modalType === 'delete' && `Delete Scene "${scene.name}"?`}
                  </h5>
                </div>
                <div className="modal-footer">
                  {modalType === 'activate' && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={activateScene}
                    >
                      Activate
                    </button>
                  )}
                  {modalType === 'delete' && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={deleteScene}
                    >
                      Delete
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleCloseModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            className="btn btn-icon btn-outline-success m-1"
            onClick={requestSceneActivate}
          >
            <i className="fa fa-play" />
          </button>
          <Link
            className="btn btn-icon btn-outline-primary m-1"
            to={`/${siteId}/scenes/${scene.id}`}
          >
            <i className="fa fa-pencil" />
          </Link>
          <button
            className="btn btn-icon btn-outline-danger m-1"
            onClick={requestDeleteScene}
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      </td>
    </tr>
  );
};

const useStyles = createUseStyles({
  sceneTable: {
    '&tbody tr td': {
      verticalAlign: 'middle',
    },
  },
});
interface IParams extends RouteComponentProps<{ siteId: string }> {}

const ListPage: React.FC<IParams> = props => {
  const {
    match: {
      params: { siteId },
    },
  } = props;
  const classes = useStyles();

  // Get all scenes for site
  const {
    data: scenes,
    isError: isErrorScenes,
    isFetching: isFetchingScenes,
  } = useQuery<IApiResponse<IScene[]>>([`${siteId}-scenes`], () =>
    ApiClient.getScenes().getScenes({ siteId }),
  );

  // Get all locations and windows for site
  const {
    data: locationWindows,
    isError: isItemError,
    isFetching: isFetchingItems,
  } = useQuery<IApiResponse<ISetWindows[]>>(
    [`${siteId}-arch-item-window-location`],
    () =>
      ApiClient.rawGet<ISetWindows[]>(
        `sites/${siteId}/arch/items?types=window,location&min=true`,
      ),
  );

  const [textSearch, setTextSearch] = useState<string>('');
  const {
    sortCol,
    sortIsAsc,
    onHeaderClick: handleHeaderClick,
  } = useSortColumn(sortTypes.name);
  const onTextSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setTextSearch(e.target.value);
  };
  const scenesWithLocations = useMemo(
    () => {
      if (!scenes || !locationWindows) {
        return [];
      }
      const sceneLocations = scenes.results as ISceneWithLocations[];
      setWindowLocations(locationWindows.results);
      const flattened = flattenArchItems(locationWindows.results);
      const windowMap = flattened.reduce<IArchItemUnique>((a, ai) => {
        if (ai.type === 'window') {
          a[ai.id] = ai.location;
        }
        return a;
      }, {});
      sceneLocations.forEach(s => {
        const locs: IArchItemUnique = {};
        s.sceneWindows.forEach(w => {
          const loc = windowMap[w.archItemId];
          if (loc) {
            locs[loc.id] = loc;
          }
        });
        s.locations = Object.values(locs);
      });
      return sceneLocations;
    },
    [scenes, locationWindows],
  );

  if (isFetchingItems || isFetchingScenes) {
    return <LoadingSpinner width="50px" height="50px" />;
  }
  if (!locationWindows || !scenes || isItemError || isErrorScenes) {
    return <p>Unable to load scenes</p>;
  }

  let filteredScenes = scenesWithLocations;
  if (textSearch) {
    const textLower = textSearch.toLowerCase();
    filteredScenes = filteredScenes.filter(
      w =>
        w.name.toLowerCase().includes(textLower) ||
        w.locations
          .map(l => l.name)
          .join(', ')
          .toLowerCase()
          .includes(textLower),
    );
  }
  filteredScenes = orderBy(
    filteredScenes,
    s => {
      switch (sortCol) {
        case sortTypes.name:
          return s.name;
        case sortTypes.location:
          return s.locations.map(l => l.name).join(', ');
        case sortTypes.windowCount:
          return s.sceneWindows.length;
        default:
          return s.name;
      }
    },
    [sortIsAsc ? 'asc' : 'desc'],
  );

  return (
    <div className="table-responsive">
      <div className="form-group row">
        <label className="col-2 col-form-label">Search Scenes/Location</label>
        <input
          className="col-3 form-control"
          type="text"
          value={textSearch}
          onChange={onTextSearch}
        />
        <div className="col-7 align-self-center">
          <Link
            className="float-right btn btn-outline-info"
            to={`/${siteId}/scenes/new`}
          >
            <i className="fa fa-plus" /> Create New
          </Link>
        </div>
      </div>
      <table
        className={classNames(
          'table table-head-custom table-head-bg table-borderless table-vertical-center',
          classes.sceneTable,
        )}
      >
        <thead>
          <tr>
            <SortableHeader
              onClick={handleHeaderClick}
              sortKey={sortTypes.name}
              currSort={sortCol}
              currSortAsc={sortIsAsc}
            >
              Scene Name
            </SortableHeader>
            <SortableHeader
              onClick={handleHeaderClick}
              sortKey={sortTypes.location}
              currSort={sortCol}
              currSortAsc={sortIsAsc}
            >
              Locations
            </SortableHeader>
            <SortableHeader
              onClick={handleHeaderClick}
              sortKey={sortTypes.windowCount}
              currSort={sortCol}
              currSortAsc={sortIsAsc}
            >
              Window Count
            </SortableHeader>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredScenes.map(s => (
            <SceneRow key={s.id} scene={s} siteId={siteId} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListPage;
