import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const ComputerIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '26px'}
    height={props.height ? props.height : '26px'}
    viewBox="0 0 26 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <image
        id="ico_computer@3x"
        x="-2"
        y="-5"
        width="30"
        height="30"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABGdBTUEAALGOfPtRkwAABEhJREFUeAHt3DtoFEEYB/CZvYdBI0R8goUhJEUIiIhgEfKw0fhCLBQLwVgqqI2IxkbEqIE0Wtr4aEQFtZEEC42KCUIiRJEUp6CiCT6CohK8Mzef3yYc2TvuZndyl+HI/q+53Zn55tv5MUx2NtkIgQ8EIAABCEAAAhCAAAQgAAEIQAACEIAABCAAgVALyCCjb7/6uGJsVBwjIXYKEg1CUFWQuDC0YUBFUg4skgt33evYOF5ozNFCFZnybd3P14yNpvqIqDpThu8ZAZ58jiBqnBAT+7n00kxN9pEWurX1THQymbrPHVVnh+HMK+DOahkRQ96y3GMt9MeJ4Zpaal6XCZJSvuXjW7x8fMqUhf2bJCkRib3oPdk0rLPQQqtIusYb7MTibT0nGt95y3AcTMDRNUsrWuCtr5iM/vSe4zi4gBY6eDdo6ScAaD+hEtUDukSQft0A2k+oRPWALhGkXzfa2zu/4LDXt53r260kHXSEHOjpaLnI+wzeKOb/GEEn45OV3E3B/Xz+FPOzdMe5/tUpmeTNG8WUoJ1t55+6+4vbhUZrtHRQKlldqKOwlaedv7UucmbcJFR95jjftxY6QiLr6R4/pco6z9dhWMpyLRhSa6OFDguajXEC2oYy5wA0oC0JWEqDGQ1oSwKW0mBGA9qSgKU0mNGAtiRgKQ1mNKAtCVhKgxkNaEsCltJgRgPakoClNJjRgLYkYCkNZrQlaKPfgvMfqN7d3PkoZenayjoNW8RNLtAMmmiJSefzuS3xH4mbfLB0mGgV0dZoRvNv1K87UrwvIt+8CVUkqvmlqQNBB2QE7UTEtd5Tm/qCdj6f27VdeNyq0iIwNJYOS7MB0JagjZYOS9ck9tx+E/+d+NKthFxrkpNf+Hu1uG7l8Tt7G8ruFrQsoX8lvu8jEkf4h42Js1BCtHDsIAfdMAq00Lgslw5J6Vm/Al1M7Fx6lye0E73Jt5KvzQcuX8upWPPIuY4oy6Wjp6P5Gw/caH2ea6hi+y/LGV3soMoxXgutiH57L5qUs8J7HubjXAv+4f1d56FdOmJR50N2sOra0vlESEd9zS4P19k0suryjlo6cvb/3aAqtYqhZYJvs+rcTqf/Z0f6FqW9KcJ4nAsgE4trl2uhtUvH0NCVfyLqtPObK7k9h1E375inbNjIb5OkhXZ7fniypZ8ikebpmZ03V4gLZcK1cY38ELRvEnmDt15OLKA/n9fz48ENvNVd6q0L2zE/Ghjnx8WDsnL1y56jdcmwjR/jhQAErAkEXqOLvaLtF54tmVT/OgXJepL6t0yLzeUXz6/G8zvBNBJ1YqcfnGr64de+FPXaDUspEmT6cJF593Ro6tGn2dPPTBcl+55KT6KVr8nt83DJOtZ05Ht7p4k1qyK5zCzAQmuL12QNOirFWb4XH7HAFzCFHJm+poDN0QwCEIAABCAAAQhAAAIQgAAEIAABCEAAAhCAAASKEfgPI8X6FTKxwD0AAAAASUVORK5CYII="
      />
    </g>
  </svg>
);

export default ComputerIcon;
