import { createStandardAction } from 'typesafe-actions';

import { IAccount, ISite } from '@halio-inc/api-client';

const ENTITY = '@@SHARED';

export default {
  clear: createStandardAction(`${ENTITY}/CLEAR`)(),
  logOut: createStandardAction(`${ENTITY}/LOG_OUT`)(),
  setMe: createStandardAction(`${ENTITY}/SET_ME`)<IAccount | undefined>(),
  setSite: createStandardAction(`${ENTITY}/SET_SITE`)<ISite | undefined>(),
  setSites: createStandardAction(`${ENTITY}/SET_SITES`)<ISite[]>(),
};
