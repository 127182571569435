export const helpMessages = {
  eventsBySource: {
    hintMessage: '',
    message: 'widget-events-by-source-help-msg',
  },
  tintByLocale: {
    hintMessage: '',
    message: 'widget-tint-by-location-help-msg',
  },
  tintByTrend: {
    hintMessage: '',
    message: 'widget-tint-by-facade-help-msg',
  },
  tintSummary: {
    hintMessage: '',
    message: 'widget-tint-summary-help-msg',
  },
};
