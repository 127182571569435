import * as React from 'react';

import LoadingSpinner from '../LoadingSpinner';

import './styles.css';

interface ILoadingIndicatorProps {
  height?: string;
  width?: string;
}

const LoadingIndicator = (props: ILoadingIndicatorProps) => (
  <div className="loading-indicator-container">
    <LoadingSpinner {...props} />
  </div>
);

export default LoadingIndicator;
