import * as _ from 'lodash';
import moment from 'moment';
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  race,
  select,
  take,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import sharedActions from '../../lib/shared/actions';
import { requireSelectedSite } from '../../lib/shared/setup-saga';
import actions from './actions';

import { IApiResponse, IArchItem, IRollup } from '@halio-inc/api-client';
import ApiClient from '../../lib/web/api-client';

import IState from '../../state';

import debug from '../../lib/debug';
const createDebug = debug('pages:home');

const log = createDebug('sagas');

export default function* HomeSaga() {
  while (true) {
    // Wait for home page load
    const loadData = yield take(getType(actions.load));

    let timeRangeInfo = loadData.payload;

    let handle = yield fork(LoadPageData, true, timeRangeInfo);

    while (true) {
      const {
        selectedSite,
        timeRangeChanged,
        unload,
      }: {
        selectedSite: ReturnType<typeof sharedActions.setSite>;
        timeRangeChanged: ReturnType<typeof actions.timeRangeChanged>;
        unload: ReturnType<typeof actions.unload>;
      } = yield race({
        selectedSite: take(getType(sharedActions.setSite)),
        timeRangeChanged: take(getType(actions.timeRangeChanged)),
        unload: take(getType(actions.unload)),
      });

      yield cancel(handle);

      if (unload) {
        log('Page Unloaded');
        break;
      }

      let fresh = false;

      if (selectedSite) {
        fresh = true;
      }

      if (timeRangeChanged) {
        timeRangeInfo = timeRangeChanged.payload;
      }

      handle = yield fork(LoadPageData, fresh, timeRangeInfo);
    }
  }
}

function ProcessArchItems(archItems: IArchItem[]) {
  const yn = require('yn');

  if (!archItems) {
    return [];
  }

  let results: IArchItem[] = [];

  archItems.forEach(archItem => {
    if (
      !yn(
        _.get(
          archItem,
          'attributes.dashboard.hideOnHomePageGraph.value',
          false,
        ),
        {
          default: false,
          lenient: true,
        },
      )
    ) {
      results.push(
        Object.assign({}, archItem, {
          items: undefined,
        }),
      );
    }

    results = [...results, ...ProcessArchItems(archItem.items)];
  });

  return results;
}

function ProcessRollups(
  rollups: IRollup[],
  results: {
    dates: Date[];
    values: {
      [locationId: string]: {
        [date: string]: IRollup;
      };
    };
  } = { dates: [], values: {} },
  timeRangeInfo: { type: string; length: string },
) {
  if (!rollups) {
    return {};
  }

  rollups.forEach(rollup => {
    switch (timeRangeInfo.length) {
      case '1d':
        if (moment.utc(rollup.periodEnd).minutes() % 15 !== 0) {
          return;
        }
        break;
    }

    results.dates.push(rollup.periodEnd);
    results.values[rollup.objectId][rollup.periodEnd.toString()] = rollup;
  });

  return results;
}

function* LoadPageData(
  fresh: boolean,
  timeRangeInfo: { type: string; length: string },
) {
  try {
    const siteId = yield* requireSelectedSite();

    if (yield cancelled()) {
      log('cancel handeled1');
      return;
    }

    if (fresh) {
      const archItemsService = ApiClient.getArchItems();

      const archItemsResponse: IApiResponse<IArchItem[]> = yield call(
        [archItemsService, archItemsService.getArchItems],
        {
          minified: true,
          siteId,
          types: ['location'],
        },
      );

      if (yield cancelled()) {
        log('cancel handeled2');
        return;
      }

      if (archItemsResponse.success) {
        const processed = ProcessArchItems(archItemsResponse.results);

        yield put(actions.setLocations(processed));
      }
    }

    const archItems: IArchItem[] = yield select((state: IState) => {
      return _.get(state, 'pages.home.locations', []);
    });

    const results: {
      dates: Date[];
      values: {
        [locationId: string]: {
          [date: string]: IRollup;
        };
      };
    } = { dates: [], values: {} };

    archItems.forEach(archItem => {
      results.values[archItem.id] = {};
    });

    // rollups in mysql has a hard limit of 2000 items returned, so we have to chunk for a lot of locations
    let chunkSize = archItems.length;
    switch (timeRangeInfo.type) {
      case '5m':
        // 5m for 1d report; 12 5m in an hour, 24h in a day; 2000 / (24 * 12)
        chunkSize = 6;
        break;
      case '1h':
        // 1h for 1w report; 24 hours in a day; 7 days in a week; 2000 / (24 * 7)
        chunkSize = 11;
        break;
      case '6h':
        // 6h for 3w report; 4 6h in a day; 21 days in 3 weeks; 2000 / (4 * 21)
        chunkSize = 23;
        break;
      default:
        break;
    }
    const rollupsService = ApiClient.getRollups();
    const objectIds = archItems.map(a => a.id);
    for (let i = 0; i < objectIds.length; i += chunkSize) {
      const chunk = objectIds.slice(i, i + chunkSize);

      const rollupsResponse: IApiResponse<IRollup[]> = yield call(
        [rollupsService, rollupsService.getRollups],
        {
          limit: 2000,
          objectIds: chunk,
          objectTypes: ['location'],
          period: timeRangeInfo.type,
          relative: timeRangeInfo.length,
          siteId,
          to: moment()
            .endOf('day')
            .toDate(),
          type: 'window_tint',
        },
      );

      if (yield cancelled()) {
        log('cancel handeled3');
        return;
      }

      if (rollupsResponse.success) {
        const groups = _.groupBy(rollupsResponse.results, 'objectId');
        Object.values(groups).forEach(g =>
          ProcessRollups(g, results, timeRangeInfo),
        );
      }
    }

    results.dates = [...new Set(results.dates)];
    results.dates.sort((a: Date, b: Date) => {
      if (a > b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }
      return 0;
    });

    const datasets: { [locationId: string]: Array<number | null> } = {};

    archItems.forEach(archItem => {
      datasets[archItem.id] = [];
    });

    results.dates.forEach(date => {
      Object.keys(results.values).forEach(archItemId => {
        let value: number | null = null;

        if (
          results.values[archItemId] &&
          results.values[archItemId][date.toString()]
        ) {
          value = parseInt(
            results.values[archItemId][date.toString()].value,
            10,
          );

          if (!Number.isInteger(value)) {
            value = null;
          }
        }

        datasets[archItemId].push(value);
      });
    });

    let labels: string[] = [];

    switch (timeRangeInfo.length) {
      case '1d':
        labels = results.dates.map(d =>
          moment
            .utc(d)
            .local()
            .format('h:mm a'),
        );
        break;
      case '1w':
        labels = results.dates.map(d =>
          moment
            .utc(d)
            .local()
            .format('MMM Do h:mm a'),
        );
        break;
      case '3w':
        labels = results.dates.map(d =>
          moment
            .utc(d)
            .local()
            .format('MMM Do'),
        );
        break;
    }

    yield put(
      actions.setChart({
        datasets,
        labels,
      }),
    );
  } finally {
    if (yield cancelled()) {
      log('cancel handeled');
    }
  }
}
