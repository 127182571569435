import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { RouteComponentProps } from 'react-router';

import { IApiResponse } from '@halio-inc/api-client';

import notifier from 'lib/shared/notifier';
import ApiClient from 'lib/web/api-client';
import LoadingSpinner from '../../components/LoadingSpinner';
import Form from './form';
import {
  flattenArchItems,
  ICompleteScene,
  ISetWindows,
  setWindowLocations,
} from './utils';

interface IMatchProps {
  siteId: string;
  sceneId: string;
}
interface IParams extends RouteComponentProps<IMatchProps> {}

const EditScenePage: React.FC<IParams> = props => {
  const {
    match: {
      params: { siteId },
    },
    history,
  } = props;

  // Get all locations and windows for site
  const {
    data: locationWindows,
    isError: isItemError,
    isFetching: isFetchingItems,
  } = useQuery<IApiResponse<ISetWindows[]>>(
    [`${siteId}-arch-item-window-location`],
    () =>
      ApiClient.rawGet<ISetWindows[]>(
        `sites/${siteId}/arch/items?types=window,location&min=true`,
      ),
  );

  const saveSceneMutation = useMutation(
    (scene: ICompleteScene) => {
      return ApiClient.rawPost(`sites/${siteId}/scenes`, {
        scene: {
          name: scene.name,
          windows: scene.windows.map(w => ({ id: w.id, level: w.sceneLevel })),
        },
      });
    },
    {
      onError: e => {
        // Need to log to sentry or some remote log feature
        // tslint:disable-next-line: no-console
        console.log(e);
        notifier(
          'error',
          'An error occurred while attempting to create the Scene. Please try again.',
        );
      },
      onSuccess: (_, newScene) => {
        notifier('success', `Scene "${newScene.name}" has been created`);
        history.push(`/${siteId}/scenes`);
      },
    },
  );

  // Handle save
  const handleSubmit = (scene: ICompleteScene) => {
    saveSceneMutation.mutate(scene);
  };

  const handleReset = () => {
    history.push(`/${siteId}/scenes`);
  };

  if (isFetchingItems) {
    return <LoadingSpinner width="50px" height="50px" />;
  }

  if (!locationWindows || isItemError) {
    return <p>Unable to load scene</p>;
  }

  const newObj: ICompleteScene = {
    id: '',
    name: '',
    windows: [],
  };

  setWindowLocations(locationWindows.results);
  const flattened = flattenArchItems(locationWindows.results);
  const windows = flattened.filter(i => i.type === 'window');
  return (
    <Form
      scene={newObj}
      windows={windows}
      onSubmit={handleSubmit}
      onReset={handleReset}
    />
  );
};

export default EditScenePage;
