import * as _ from 'lodash';
import * as React from 'react';

import NoMap from './NoMap';

import debug from '../../../lib/debug';
const createDebug = debug('components:widgets:map');

const log = createDebug('');

interface IMapWidgetProps {
  address?: string;
  height?: number;
  latitude?: number;
  longitude?: number;
  width?: number;
}

export default class MapWidget extends React.Component<IMapWidgetProps, any> {
  public render() {
    if (!_.get(window, 'HALIO_CONFIG.MAPS_API_KEY', false)) {
      // Google Maps will just give an error if we request without an API key
      return null;
    }

    let location: string;

    if (this.props.latitude && this.props.longitude) {
      location = `${this.props.latitude},${this.props.longitude}`;
    } else if (this.props.address) {
      location = this.props.address;
    } else {
      return <NoMap />;
    }

    log(`Using "${location}" for map pin.`);

    location = encodeURIComponent(location);

    const mapUrl = `https://www.google.com/maps/embed/v1/place?q=${location}&key=${_.get(
      window,
      'HALIO_CONFIG.MAPS_API_KEY',
      '',
    )}`;

    const style: any = {
      height: '100%',
      overflow: 'hidden',
      position: 'relative',
    };

    if (this.props.height) {
      style.minHeight = this.props.height;
    }

    if (this.props.width) {
      style.minWidth = this.props.width;
    }

    return (
      <div style={style}>
        <iframe
          title="google map"
          frameBorder="0"
          style={{
            border: 0,
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
          src={mapUrl}
          allowFullScreen
        />
      </div>
    );
  }
}
