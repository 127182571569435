import * as _ from 'lodash';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import Actions from './actions';
import ILanguageTextSwitcherStateProps, { IMapStateProps } from './state';

import ActionTypes from '../../lib/action-types';
import IStateProps from '../../state';

interface IDispatchProps {
  onChangeLanguage: (
    languageId: string,
  ) => ReturnType<typeof Actions.changeLanguage>;
}

interface ILanguageTextSwitcherProps
  extends ILanguageTextSwitcherStateProps,
    IDispatchProps {
  readonly isLoadingState?: boolean;
}

const LanguageTextSwitcher: React.FC<
  ILanguageTextSwitcherProps & WithTranslation
> = props => {
  const [isOpen, setIsOpen] = React.useState(false);

  if (props.isLoadingState) {
    const defaultToggle = () => null;
    return (
      <Dropdown isOpen={false} size="sm" toggle={defaultToggle}>
        <DropdownToggle caret>Loading.... </DropdownToggle>
      </Dropdown>
    );
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const onHandleClick = (id: string, langCode: string) => {
    props.i18n.changeLanguage(langCode);
    props.onChangeLanguage(id);
  };
  const currentLanguage = _
    .get(window, 'localStorage.i18nextLng', 'en-US')
    .toLowerCase();
  const dropdownItems = props.languages.map(language => {
    const languageName =
      currentLanguage === 'en-us'
        ? props.t(language.languageName)
        : language.nativeLangTitle;
    const countryCode = language.languageCode;
    const languageId = language.id;
    const isActive = currentLanguage === countryCode;

    return (
      <DropdownItem
        key={languageId}
        active={isActive}
        onClick={onHandleClick.bind(language, languageId, countryCode)}
      >
        {languageName}
      </DropdownItem>
    );
  });

  return (
    <Dropdown isOpen={isOpen} size="sm" toggle={toggleDropdown}>
      <DropdownToggle>Language</DropdownToggle>
      <DropdownMenu>{dropdownItems}</DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = (state: IStateProps) => {
  return {
    languages: _.get(state, 'components.languageTextSwitcher.languages', []),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    {
      onChangeLanguage: Actions.changeLanguage,
    },
    dispatch,
  );

export default withTranslation('shared')(
  connect<IMapStateProps, IDispatchProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(LanguageTextSwitcher),
);
