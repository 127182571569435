import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { ISite } from '@halio-inc/api-client';

import ActionTypes from '../../lib/action-types';
import IState from '../../state';
import Actions from './actions';

interface IStateProps {
  locationCount?: number;
  selectedSite?: ISite;
  windowCount?: number;
}

interface IDispatchProps {
  onLoad: () => ReturnType<typeof Actions.load>;
  onUnload: () => ReturnType<typeof Actions.unload>;
}

interface ISiteSummaryProps extends RouteComponentProps<any> {
  prefix: string;
  style: any;
}

class SiteSummary extends React.Component<
  ISiteSummaryProps & IStateProps & IDispatchProps,
  any
> {
  public render() {
    const parts = [];

    if (this.props.windowCount) {
      parts.push(
        this.props.windowCount +
          ' Halio Window' +
          (this.props.windowCount === 1 ? '' : 's'),
      );
    }

    if (this.props.locationCount) {
      parts.push(
        this.props.locationCount +
          ' Location' +
          (this.props.locationCount === 1 ? '' : 's'),
      );
    }

    let text = '';
    if (parts.length) {
      text = parts.join(' in ');
    } else {
      text = parts.join('');
    }

    if (text && this.props.prefix) {
      text = this.props.prefix + text;
    }

    return <span style={this.props.style}>{text}</span>;
  }

  public componentDidMount() {
    this.props.onLoad();
  }

  public componentWillUnmount() {
    this.props.onUnload();
  }
}

function mapStateToProps(state: IState) {
  return {
    locationCount: _.get(state, 'components.siteSummary.locationCount', null),
    selectedSite: _.get(state, 'shared.lastSelectedSite', null),
    windowCount: _.get(state, 'components.siteSummary.windowCount', null),
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    { onLoad: Actions.load, onUnload: Actions.unload },
    dispatch,
  );

export default withRouter(
  connect<IStateProps, IDispatchProps, ISiteSummaryProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(SiteSummary),
);
