import { ActionType, getType } from 'typesafe-actions';

import IState from './state';

import actions from './actions';

import sharedActions from '../../../lib/shared/actions';

import debug from '../../../lib/debug';
const createDebug = debug('components:widgets:modes');

type ActionTypes =
  | ActionType<typeof actions>
  | ActionType<typeof sharedActions>;

const initialState = {
  archStates: [],
  modes: [],
};

const log = createDebug('reducer');

export default (state: IState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case getType(actions.setArchStates):
      log('setArchStates');
      const setArchStatesAction = action as ReturnType<
        typeof actions.setArchStates
      >;

      return Object.assign({}, state, {
        archStates: setArchStatesAction.payload,
      });
    case getType(actions.setModes):
      log('setModes');
      const setModesAction = action as ReturnType<typeof actions.setModes>;

      return Object.assign({}, state, {
        modes: setModesAction.payload,
      });
    case getType(actions.unload):
    case getType(sharedActions.setSite):
    case getType(sharedActions.clear):
      log(action.type);
      return initialState;
    case getType(actions.load):
      log(action.type);
      return state;
    default:
      return state;
  }
};
