import { IArchState } from '@halio-inc/api-client';
import * as _ from 'lodash';

export const getLocationsWithWindows = (archItems: IArchState[]) => {
  const finalLocations: IArchState[] = [];
  archItems.forEach(itemLocation => {
    const itemWindows = itemLocation.items.filter(
      item => item.type === 'window',
    );
    const hasWindows = !!itemWindows.length;

    if (hasWindows) {
      finalLocations.push(itemLocation);
    }

    return;
  });

  return finalLocations;
};

export const getLocations = (archItems: IArchState[]) => {
  const items = _.get(archItems, '[0].items', []);
  const clonedItems = JSON.parse(JSON.stringify(items));
  const finalLocations: IArchState[] = [];

  if (!items.length) {
    return [];
  }

  clonedItems.forEach((item: IArchState) => {
    const subItems = _.get(item, 'items', []);
    const filteredSubLocations = getLocationsWithWindows(subItems);
    let hasWindows = false;

    if (!subItems.length) {
      return;
    } else if (!filteredSubLocations.length) {
      hasWindows = !!item.items.filter(
        locationItem => locationItem.type === 'window',
      ).length;
    }

    finalLocations.push({
      ...item,
      items: hasWindows ? [item] : filteredSubLocations,
    });
  });

  return finalLocations;
};
