import * as React from 'react';

import Locale from '../../../components/locale';

interface IActivityStreamErrorProps {
  error?: string;
}

export default class ActivityStreamError extends React.Component<
  IActivityStreamErrorProps,
  any
> {
  public render() {
    if (!this.props.error) {
      return null;
    }

    // TODO: Map/translate error strings as needed
    return (
      <div>
        {this.props.error === 'NO_EVENTS_FOUND' ? (
          <Locale nameSpace="activity-stream" localeKey="no-events" />
        ) : (
          this.props.error
        )}
      </div>
    );
  }
}
