import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { faqList } from './faq-content-data';
import './index.css';

import SubHeader from '../../theme/sub-header';

export class FAQ extends React.Component<WithTranslation> {
  public render() {
    return (
      <div>
        <SubHeader title={this.props.t('title')} />
        <br />
        <div className="m-portlet m-portlet--space">
          <div className="m-portlet__body">
            <div className="row">
              <div className="col-xl-3">
                <div
                  className="m-tabs"
                  data-tabs="true"
                  data-tabs-contents="#m_sections"
                >
                  <ul
                    className="m-nav m-nav--active-bg m-nav--active-bg-padding-lg m-nav--font-lg m-nav--font-bold m--margin-bottom-20 m--margin-top-10 m--margin-right-40"
                    id="m_nav"
                    role="tablist"
                  >
                    {faqList
                      .filter(item => item.tabTitle)
                      .map((item, index) => (
                        <li className="m-nav__item" key={item.id}>
                          <button
                            className={`m-nav__link m-tabs__item btn-blank ${
                              index === 0 ? ' m-tabs__item--active' : ''
                            }`}
                            data-tab-target={`#${item.id}`}
                          >
                            <span className="m-nav__link-text">
                              {this.props.t(item.tabTitle)}
                            </span>
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="col-xl-9">
                <div className="m-tabs-content" id="m_sections">
                  {faqList.map((faq, index) => (
                    <div
                      className={`m-tabs-content__item${
                        index === 0 ? ' m-tabs-content__item--active' : ''
                      }`}
                      id={faq.id}
                      key={faq.id}
                    >
                      <h4 className="m--font-bold m--margin-top-15 m--margin-bottom-20">
                        {this.props.t(faq.title)}
                      </h4>
                      <div
                        className="m-accordion m-accordion--section m-accordion--padding-lg"
                        id={faq.sectionContentId}
                      >
                        {faq.items.map(item => (
                          <div className="m-accordion__item" key={item.id}>
                            <a
                              className="m-accordion__item-head collapsed"
                              role="tab"
                              id={`${faq.sectionContentId}_${item.id}_head`}
                              data-toggle="collapse"
                              href={`#${faq.sectionContentId}_${item.id}_body`}
                            >
                              <span className="m-accordion__item-title">
                                {this.props.t(item.titleKey)}
                              </span>
                              <span className="m-accordion__item-mode" />
                            </a>
                            <div
                              className="m-accordion__item-body collapse"
                              id={`${faq.sectionContentId}_${item.id}_body`}
                              role="tabpanel"
                              aria-labelledby={`${faq.sectionContentId}_${
                                item.id
                              }_head`}
                              data-parent={`#${faq.sectionContentId}`}
                            >
                              <div className="m-accordion__item-content">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.props.t(item.contentKey),
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public componentDidMount() {
    if (window.mApp) {
      window.mApp.init();
    }
  }
}

export default withTranslation('faq')(FAQ);
