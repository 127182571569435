import { ActionType, getType } from 'typesafe-actions';

import IState from './state';

import actions from './actions';

import sharedActions from '../../lib/shared/actions';

import debug from '../../lib/debug';
const createDebug = debug('pages:schedule');

type ActionTypes =
  | ActionType<typeof actions>
  | ActionType<typeof sharedActions>;

const initialState = {
  formattedScheduleEvents: {
    days: {
      friday: {},
      monday: {},
      saturday: {},
      sunday: {},
      thursday: {},
      tuesday: {},
      wednesday: {},
    },
    exceptions: {},
  },
  scenes: [],
};

const log = createDebug('reducer');

export default (state: IState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case getType(actions.setScenes):
      log('setScenes');
      const setScenesAction = action as ReturnType<typeof actions.setScenes>;

      return Object.assign({}, state, {
        scenes: setScenesAction.payload,
      });
    case getType(actions.setFormattedScheduleEvents):
      log('setFormattedScheduleEvents');
      const setFormattedScheduleEventsAction = action as ReturnType<
        typeof actions.setFormattedScheduleEvents
      >;

      return Object.assign({}, state, {
        formattedScheduleEvents: setFormattedScheduleEventsAction.payload,
      });
    case getType(actions.unload):
    case getType(sharedActions.setSite):
    case getType(sharedActions.clear):
      log(action.type);
      return initialState;
    case getType(actions.load):
      log(action.type);
      return state;
    default:
      return state;
  }
};
