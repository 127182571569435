import { ActionType, getType } from 'typesafe-actions';

import IState from './state';

import actions from './actions';

import sharedActions from '../../lib/shared/actions';

import debug from '../../lib/debug';
const createDebug = debug('components:site-summary');

type ActionTypes =
  | ActionType<typeof actions>
  | ActionType<typeof sharedActions>;

const initialState = {
  locationCount: undefined,
  windowCount: undefined,
};

const log = createDebug('reducer');

export default (state: IState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case getType(actions.setLocationCount):
      log(action.type);

      const setLocationCountAction = action as ReturnType<
        typeof actions.setLocationCount
      >;

      return Object.assign({}, state, {
        locationCount: setLocationCountAction.payload,
      });
    case getType(actions.setWindowCount):
      log(action.type);

      const setWindowCountAction = action as ReturnType<
        typeof actions.setWindowCount
      >;

      return Object.assign({}, state, {
        windowCount: setWindowCountAction.payload,
      });
    case getType(actions.unload):
    case getType(sharedActions.setSite):
    case getType(sharedActions.clear):
      log(action.type);
      return initialState;
    case getType(actions.load):
      log(action.type);
      return state;
    default:
      return state;
  }
};
