import { createStandardAction } from 'typesafe-actions';

import { IArchItem } from '@halio-inc/api-client';

const ENTITY = 'PAGES/HOME';

export default {
  load: createStandardAction(`${ENTITY}/LOAD`)<{
    type: string;
    length: string;
  }>(),
  setChart: createStandardAction(`${ENTITY}/SET_CHART`)<{
    labels: string[];
    datasets: {
      [locationId: string]: Array<number | null>;
    };
  }>(),
  setLocations: createStandardAction(`${ENTITY}/SET_LOCATIONS`)<IArchItem[]>(),
  timeRangeChanged: createStandardAction(`${ENTITY}/TIME_RANGE_CHANGED`)<{
    type: string;
    length: string;
  }>(),
  unload: createStandardAction(`${ENTITY}/UNLOAD`)(),
};
