import * as React from 'react';

interface ISvgProps {
  fill?: string;
  height?: string;
  stroke?: string;
  width?: string;
}

const GlareControlIcon = (props: ISvgProps) => {
  const styles = {
    fill: props.fill ? props.fill : '#8689A6',
    height: props.height ? props.height : '27px',
    stroke: props.stroke ? props.stroke : '#8689A6',
    width: props.width ? props.width : '27px',
  };
  return (
    <svg
      width={props.width ? props.width : '27px'}
      height={props.height ? props.height : '27px'}
      viewBox="0 0 52 51"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="glareControl-icon">
          <g id="icon">
            <g
              id="inner-circle"
              transform="translate(13.000000, 13.000000)"
              stroke={styles.stroke}
              strokeWidth="2"
            >
              <path
                d="M12.4826892,0 C19.9480938,0 26,5.59644063 26,12.5 C26,19.4035594 19.9480938,25 12.4826892,25 C12.321115,25 12.1602029,24.9973785 12,24.992179 L12,0.00782100263 C12.1602029,0.00262149605 12.321115,0 12.4826892,0 Z"
                id="Path"
                fill="#FFFFFF"
              />
              <path
                d="M13.5173108,0 C13.678885,0 13.8397971,0.00262149605 14,0.00782100263 L14,24.992179 C13.8397971,24.9973785 13.678885,25 13.5173108,25 C6.05190618,25 0,19.4035594 0,12.5 C0,5.59644063 6.05190618,0 13.5173108,0 Z"
                id="Path"
                fill={styles.fill}
              />
            </g>
            <g id="flares" fill={styles.fill}>
              <rect
                id="Rectangle"
                x="24.8095238"
                y="0"
                width="1.42857143"
                height="9.61538462"
                rx="0.714285714"
              />
              <path
                d="M36.5048297,1.41393789 C36.9010701,1.41551387 37.2218934,1.73634396 37.223461,2.1325844 L37.255757,10.2961865 C37.2573086,10.6883877 36.9406245,11.0075875 36.5484232,11.0091391 C36.5465452,11.0091465 36.5446673,11.0091465 36.5427893,11.009139 C36.1465489,11.0075631 35.8257256,10.686733 35.8241581,10.2904925 L35.791862,2.12689046 C35.7903104,1.73468918 36.1069946,1.41548942 36.4991959,1.41393783 C36.5010738,1.4139304 36.5029518,1.41393042 36.5048297,1.41393789 Z"
                id="Rectangle"
                transform="translate(36.523810, 6.211538) rotate(28.000000) translate(-36.523810, -6.211538) "
              />
              <path
                d="M43.6000678,10.1318497 C43.9984342,10.1334256 44.3209857,10.4559703 44.3225702,10.8543366 L44.3548053,18.9590496 C44.3563735,19.3533552 44.0379971,19.6742743 43.6436915,19.6758426 C43.6418035,19.6758501 43.6399154,19.6758501 43.6380274,19.6758427 C43.239661,19.6742667 42.9171095,19.351722 42.9155251,18.9533557 L42.88329,10.8486427 C42.8817217,10.4543372 43.2000982,10.133418 43.5944037,10.1318497 C43.5962917,10.1318422 43.5981798,10.1318422 43.6000678,10.1318497 Z"
                id="Rectangle"
                transform="translate(43.619048, 14.903846) rotate(-298.000000) translate(-43.619048, -14.903846) "
              />
              <path
                d="M47,20.2380952 C47.3982823,20.2380952 47.7211538,20.5609668 47.7211538,20.9592491 L47.7211538,29.0407509 C47.7211538,29.4390332 47.3982823,29.7619048 47,29.7619048 C46.6017177,29.7619048 46.2788462,29.4390332 46.2788462,29.0407509 L46.2788462,20.9592491 C46.2788462,20.5609668 46.6017177,20.2380952 47,20.2380952 Z"
                id="Rectangle"
                transform="translate(47.000000, 25.000000) rotate(-270.000000) translate(-47.000000, -25.000000) "
              />
              <path
                d="M44.6388742,31.3228022 C45.0329289,31.3211733 45.3536939,31.6392972 45.3553228,32.0333519 C45.355331,32.0353229 45.355331,32.037294 45.3553228,32.039265 L45.3216461,40.1470948 C45.3199917,40.5453895 44.9975158,40.867859 44.599221,40.8695055 C44.2051663,40.8711344 43.8844013,40.5530105 43.8827724,40.1589558 C43.8827643,40.1569848 43.8827643,40.1550137 43.8827725,40.1530427 L43.9164491,32.0452129 C43.9181035,31.6469182 44.2405794,31.3244487 44.6388742,31.3228022 Z"
                id="Rectangle"
                transform="translate(44.619048, 36.096154) rotate(-240.000000) translate(-44.619048, -36.096154) "
              />
              <path
                d="M36.5427893,38.990861 C36.9349906,38.9893011 37.254197,39.3059784 37.255757,39.6981797 C37.2557644,39.7000576 37.2557644,39.7019356 37.255757,39.7038135 L37.223461,47.8674156 C37.2218934,48.263656 36.9010701,48.5844861 36.5048297,48.5860621 C36.1126285,48.587622 35.793422,48.2709447 35.7918621,47.8787434 C35.7918546,47.8768655 35.7918546,47.8749875 35.791862,47.8731095 L35.8241581,39.7095075 C35.8257256,39.313267 36.1465489,38.9924369 36.5427893,38.990861 Z"
                id="Rectangle"
                transform="translate(36.523810, 43.788462) rotate(-208.000000) translate(-36.523810, -43.788462) "
              />
              <rect
                id="Rectangle"
                transform="translate(25.523810, 46.192308) rotate(-180.000000) translate(-25.523810, -46.192308) "
                x="24.8095238"
                y="41.3846154"
                width="1.42857143"
                height="9.61538462"
                rx="0.714285714"
              />
              <path
                d="M14.9810202,38.990861 C15.3772606,38.9924369 15.6980839,39.313267 15.6996515,39.7095075 L15.7319475,47.8731095 C15.7334991,48.2653108 15.4168149,48.5845106 15.0246137,48.5860622 C15.0227357,48.5860696 15.0208577,48.5860696 15.0189798,48.5860621 C14.6227394,48.5844861 14.3019161,48.263656 14.3003485,47.8674156 L14.2680525,39.7038135 C14.2665009,39.3116123 14.5831851,38.9924125 14.9753863,38.9908609 C14.9772643,38.9908535 14.9791423,38.9908535 14.9810202,38.990861 Z"
                id="Rectangle"
                transform="translate(15.000000, 43.788462) rotate(-152.000000) translate(-15.000000, -43.788462) "
              />
              <path
                d="M7.36243093,30.8440427 C7.76082956,30.8455805 8.08341658,31.1681605 8.08496309,31.5665591 L8.11641822,39.6697665 C8.11794934,40.0642014 7.79943788,40.3851953 7.40500297,40.3867265 C7.40315993,40.3867336 7.40131687,40.3867336 7.39947383,40.3867265 C7.0010752,40.3851887 6.67848818,40.0626087 6.67694167,39.6642101 L6.64548654,31.5610027 C6.64395542,31.1665678 6.96246688,30.8455739 7.3569018,30.8440428 C7.35874483,30.8440356 7.36058789,30.8440356 7.36243093,30.8440427 Z"
                id="Rectangle"
                transform="translate(7.380952, 35.615385) rotate(-117.000000) translate(-7.380952, -35.615385) "
              />
              <path
                d="M5,20.2380952 C5.39828227,20.2380952 5.72115385,20.5609668 5.72115385,20.9592491 L5.72115385,29.0407509 C5.72115385,29.4390332 5.39828227,29.7619048 5,29.7619048 C4.60171773,29.7619048 4.27884615,29.4390332 4.27884615,29.0407509 L4.27884615,20.9592491 C4.27884615,20.5609668 4.60171773,20.2380952 5,20.2380952 Z"
                id="Rectangle"
                transform="translate(5.000000, 25.000000) rotate(-90.000000) translate(-5.000000, -25.000000) "
              />
              <path
                d="M7.9237417,10.1318497 C8.31804729,10.1302897 8.63895969,10.448673 8.6405196,10.8429786 C8.64052707,10.8448666 8.64052705,10.8467547 8.64051954,10.8486427 L8.60828444,18.9533557 C8.6067,19.351722 8.28414853,19.6742667 7.88578211,19.6758427 C7.49147652,19.6774026 7.17056412,19.3590193 7.16900421,18.9647137 C7.16899674,18.9628257 7.16899676,18.9609376 7.16900427,18.9590496 L7.20123937,10.8543366 C7.20282381,10.4559703 7.52537528,10.1334256 7.9237417,10.1318497 Z"
                id="Rectangle"
                transform="translate(7.904762, 14.903846) rotate(-62.000000) translate(-7.904762, -14.903846) "
              />
              <path
                d="M14.7516866,2.00270608 C15.1439356,2.00122278 15.4631187,2.31800094 15.464602,2.71024989 C15.4646087,2.71203513 15.4646087,2.71382038 15.464602,2.71560562 L15.4339013,10.8821811 C15.4324123,11.278269 15.1116935,11.598995 14.7156056,11.6004928 C14.3233566,11.6019761 14.0041735,11.2851979 14.0026902,10.892949 C14.0026835,10.8911637 14.0026835,10.8893785 14.0026902,10.8875932 L14.0333909,2.72101778 C14.0348799,2.32492982 14.3555987,2.0042039 14.7516866,2.00270608 Z"
                id="Rectangle"
                transform="translate(14.733646, 6.801599) rotate(-26.000000) translate(-14.733646, -6.801599) "
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default GlareControlIcon;
