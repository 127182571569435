import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const SetTintLargeIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '125px'}
    height={props.height ? props.height : '125px'}
    viewBox="0 0 150 150"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="tintset">
        <circle
          id="Oval"
          stroke="#4684BE"
          strokeWidth="6"
          fill="#FFFFFF"
          cx="75"
          cy="75"
          r="72"
        />
        <circle
          id="Oval"
          stroke="#4684BE"
          strokeWidth="4"
          fill="#FFFFFF"
          cx="74.611399"
          cy="74.611399"
          r="37.6373057"
        />
        <g id="circle" transform="translate(43.000000, 43.000000)">
          <circle id="Oval" fill="#878787" cx="32.0014348" cy="32" r="32" />
          <path
            d="M22.0014198,1.6340332 L22.0014198,62.414917 C9.95944258,58.5279541 0.0979923836,46.8759766 0.00329152301,32.4742998 C-0.194366015,18.1125488 8.84567305,5.78552246 22.0014198,1.6340332 Z"
            id="Path"
            fill="#D8D8D8"
          />
          <path
            d="M41.0014348,1.36816406 C53.5870061,4.96740723 63.9430852,16.7724609 64.0014348,32.115282 C63.8389592,47.7288818 53.1845402,59.2113037 41.0014348,62.6374512 L41.0014348,1.36816406 Z"
            id="Path"
            fill="#000000"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SetTintLargeIcon;
