import { all } from 'redux-saga/effects';

import activityStream from './activity-stream/sagas';
import home from './home/sagas';
import modeBrowser from './mode-browser/sagas';
import schedule from './schedule/sagas';

export default function* Sagas() {
  yield all([activityStream(), home(), modeBrowser(), schedule()]);
}
