import { ActionType, getType } from 'typesafe-actions';

import IState from './state';

import actions from './actions';

import { alphabetizeList } from './utils';

import debug from '../debug';
const createDebug = debug('lib:shared');

type ActionTypes = ActionType<typeof actions>;

const initialState = {};

const log = createDebug('reducer');

export default (state: IState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case getType(actions.setMe):
      log('setMe');
      const setMeAction = action as ReturnType<typeof actions.setMe>;

      return Object.assign({}, state, {
        me: setMeAction.payload,
      });
    case getType(actions.setSites):
      log('setSites');
      const setSitesAction = action as ReturnType<typeof actions.setSites>;

      return Object.assign({}, state, {
        sites: alphabetizeList(setSitesAction.payload, 'name'),
      });
    case getType(actions.setSite):
      log('setSite');
      const setSiteAction = action as ReturnType<typeof actions.setSite>;

      return Object.assign({}, state, {
        lastSelectedSite: setSiteAction.payload,
      });
    case getType(actions.clear):
      return initialState;
    default:
      log('default');
      return state;
  }
};
