import invariant from 'invariant';

import debug from '../debug';
const createDebug = debug('shared:sagas');

const log = createDebug('storage');
const error = createDebug('storage:error');

const tokenKey = '@@SHARED/siteId';

export const restoreSiteId = async (): Promise<string | undefined> => {
  try {
    let siteId = window.sessionStorage.getItem(tokenKey);
    siteId = siteId || window.localStorage.getItem(tokenKey);
    return siteId || undefined;
  } catch (err) {
    error(err);
    return undefined;
  }
};

export const saveSiteId = async (siteId: string) => {
  invariant(siteId, 'Save SiteId: SiteId must be present');
  log('saveSiteId', siteId);
  window.localStorage.setItem(tokenKey, siteId);
  window.sessionStorage.setItem(tokenKey, siteId);
};
