import * as _ from 'lodash';
import { ActionType, getType } from 'typesafe-actions';

import IState from './state';

import actions from './actions';
import { formatEventsIntoSchedules } from './utils';

import { IEvent } from '@halio-inc/api-client';

import sharedActions from '../../lib/shared/actions';

import debug from '../../lib/debug';
const createDebug = debug('pages:activityStream');

type ActionTypes =
  | ActionType<typeof actions>
  | ActionType<typeof sharedActions>;

const initialState = {
  count: 50,
  error: undefined,
  events: [],
  eventsInSchedules: [],
  filters: { source: '' },
  isMoreEvents: false,
  position: undefined,
};

const log = createDebug('reducer');

export default (state: IState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case getType(actions.setEvents):
      log('setEvents');
      const setEventsAction = action as ReturnType<typeof actions.setEvents>;
      let events: IEvent[];

      events = [
        ...(setEventsAction.payload.fresh ? [] : state.events),
        ...setEventsAction.payload.events,
      ];

      const lastSelectedSite = _.get(
        setEventsAction,
        'payload.lastSelectedSite',
        {},
      );

      const eventsInSchedules = formatEventsIntoSchedules(
        events,
        lastSelectedSite,
      );

      return Object.assign({}, state, {
        count: setEventsAction.payload.count,
        error: events.length === 0 ? 'NO_EVENTS_FOUND' : undefined,
        events,
        eventsInSchedules,
        filters: setEventsAction.payload.filters,
        isMoreEvents: setEventsAction.payload.isMoreEvents,
        position: setEventsAction.payload.position,
      });
    case getType(actions.load):
    case getType(actions.filtersChanged):
    case getType(actions.unload):
    case getType(sharedActions.setSite):
    case getType(sharedActions.clear):
      log(action.type);
      return initialState;
    default:
      return state;
  }
};
