import { combineReducers } from 'redux';

import languageTextSwitcher from './language-text-switcher/reducer';
import siteSummary from './site-summary/reducer';
import widgets from './widgets/reducer';

const Reducer = combineReducers({
  languageTextSwitcher,
  siteSummary,
  widgets,
});

export default Reducer;
