import { PropertyPath } from 'lodash';
import { clone, curry, setWith } from 'lodash/fp';
import { ChangeEvent, FormEvent, useCallback, useState } from 'react';

type ValidityCheck<T, TValidate> = (
  model: T,
) => { [P in keyof TValidate]: string };
interface IFieldValidity {
  error: boolean;
  dirty: boolean;
  msg: string;
}
type FieldValidator<TValidate> = { [P in keyof TValidate]: IFieldValidity };
interface IValidity<TValidate> {
  submitted: boolean;
  error: boolean;
  fields: FieldValidator<TValidate>;
}

const useForm = <T extends object, TValidate extends object>(
  model: T,
  onSubmit: (model: T, e: FormEvent) => void,
  validityChecker?: ValidityCheck<T, TValidate>,
) => {
  const setIn = curry((path: PropertyPath, value: any, obj: T) => {
    return setWith<T>(clone, path, value, clone(obj));
  });
  const [state, setState] = useState(model);
  const generateFieldValidity = useCallback(
    () => {
      let fields: FieldValidator<TValidate> = {} as FieldValidator<TValidate>;
      let anyErr = false;
      if (validityChecker) {
        const fieldMsgs = validityChecker(state);
        fields = Object.keys(fieldMsgs).reduce(
          (a, fn) => {
            const err = !!fieldMsgs[fn];
            if (err) {
              anyErr = true;
            }
            a[fn] = {
              dirty: false,
              error: err,
              msg: fieldMsgs[fn],
            };
            return a;
          },
          {} as FieldValidator<TValidate>,
        );
      }
      return { fields, anyErr };
    },
    [validityChecker, state],
  );
  const [validity, setValidity] = useState<IValidity<TValidate>>({
    error: false,
    fields: generateFieldValidity().fields,
    submitted: false,
  });
  const updateState = (key: string, value: any) => {
    setState(prevState => {
      const o = setIn(key, value, prevState);
      return o;
    });
    let msg: string = '';
    if (validityChecker) {
      const v = validityChecker({ ...state, [key]: value });
      msg = v[key];
    }
    setValidity(v => {
      const fields = v.fields;
      fields[key] = {
        dirty: true,
        error: !!msg,
        msg,
      };
      const anyErr = Object.values<IFieldValidity>(fields).some(f => f.error);
      return {
        error: anyErr,
        fields,
        submitted: v.submitted,
      };
    });
  };
  const onChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    updateState(event.target.name, event.target.value);
  };
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { fields, anyErr } = generateFieldValidity();
    setValidity({
      error: anyErr,
      fields,
      submitted: true,
    });
    if (!anyErr) {
      onSubmit(state, e);
    }
  };

  return {
    formState: state,
    formValidity: validity,
    handleSubmit,
    onChange,
    setState: updateState,
  };
};

export default useForm;
