import * as _ from 'lodash';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';

import { Route, Switch } from 'react-router';

import Header from './theme/header';
import HeaderLoading from './theme/header-loading';
import Nav from './theme/nav';
import NavLoading from './theme/nav-loading';

import ForceSiteRedirect from './components/force-site-redirect';
import LoadingSpinner from './components/LoadingSpinner';

import { ConnectedRouter, routerMiddleware } from 'react-router-redux';

import createSagaMiddleware from 'redux-saga';

import registerServiceWorker from './register-service-worker';
import { IAppState } from './state';

import ActionTypes from './lib/action-types';

import ApiClient from './lib/web/api-client';

import { Auth } from '@halio-inc/react-authentication';
import Callback from './lib/web/components/callback';
import history from './lib/web/history';

// Import all of our components
import ActivityStream from './pages/activity-stream';
import Colophon from './pages/colophon';
import FAQ from './pages/faq';
import Home from './pages/home';
import ModeBrowser from './pages/mode-browser';
import Reporting from './pages/reporting';
import Scenes from './pages/scene';
import Schedule from './pages/schedule';
import Support from './pages/support';

import './index.css';
import './locale/i18n';

// Import the primary reducer and sagas
import Reducer from './reducer';
import Sagas from './sagas';

// Setup auth controller
const auth = new Auth();

// Setup the middleware to watch between the Reducers and the Actions
const sagaMiddleware = createSagaMiddleware();

// Redux DevTools - completely optional, but this is necessary for it to
// work properly with redux saga.  Otherwise you'd just do:
//
// const store = createStore(
//   IndexReducer,
//   applyMiddleware(sagaMiddleware)
// )

const composeSetup =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  _.get(window, '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__', null)
    ? _.get(window, '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__', null)
    : compose;

// Build the middleware for intercepting and dispatching navigation actions
const routingMiddleware = routerMiddleware(history);

const store = createStore<IAppState, ActionTypes, {}, {}>(
  Reducer,
  composeSetup(applyMiddleware(sagaMiddleware, routingMiddleware)), // allows redux devtools to watch sagas
);

// Begin our Index Saga
sagaMiddleware.run(Sagas(auth, ApiClient));

// ignoring this line from tslint as adding IWindow
// will not work for setting new types to Window
// tslint:disable:interface-name
declare global {
  interface Window {
    HALIO_CONFIG: {
      API_V3: string;
    };
    mApp: {
      init: () => void;
    };
  }
}
interface AppProps {
  queryClient: QueryClient;
}

const Content: React.FC = () => {
  return (
    <div>
      <div className="m-grid m-grid--hor m-grid--root m-page">
        <header
          id="m_header"
          className="m-grid__item    m-header "
          m-minimize-offset="200"
          m-minimize-mobile-offset="200"
        >
          <React.Suspense fallback={<HeaderLoading />}>
            <Header auth={auth} />
          </React.Suspense>
        </header>
        <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
          <button
            className="m-aside-left-close  m-aside-left-close--skin-dark "
            id="m_aside_left_close_btn"
          >
            <i className="la la-close" />
          </button>
          <div
            id="m_aside_left"
            className="m-grid__item	m-aside-left  m-aside-left--skin-dark "
          >
            <div
              id="m_ver_menu"
              className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark "
              m-menu-vertical="1"
              m-menu-scrollable="0"
              m-menu-dropdown-timeout="500"
            >
              <React.Suspense fallback={<NavLoading />}>
                <Nav auth={auth} />
              </React.Suspense>
            </div>
          </div>
          <div className="m-grid__item m-grid__item--fluid m-wrapper">
            <div className="m-content" id="root">
              <React.Suspense
                fallback={<LoadingSpinner width="50px" height="50px" />}
              >
                <Switch>
                  <Route exact path="/" component={ForceSiteRedirect} />
                  <Route
                    exact
                    path="/callback"
                    render={(props: any) => <Callback {...props} auth={auth} />}
                  />
                  <Route exact path="/colophon" component={Colophon} />
                  <Route path="/support" component={Support} />
                  <Route path="/faq" component={FAQ} />
                  <Route exact path="/:siteId" component={Home} />
                  <Route path="/:siteId/scenes" component={Scenes} />
                  <Route
                    exact
                    path="/:siteId/activity-stream"
                    component={ActivityStream}
                  />
                  <Route path="/:siteId/reporting" component={Reporting} />
                  <Route path="/:siteId/mode-browser" component={ModeBrowser} />
                  <Route path="/:siteId/schedule" component={Schedule} />
                </Switch>
              </React.Suspense>
            </div>
          </div>
        </div>

        <footer className="m-grid__item		m-footer ">
          <div className="m-container m-container--fluid m-container--full-height m-page__container">
            <div
              className="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop"
              id="footer"
            >
              <div className="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
                <span className="m-footer__copyright">
                  {new Date().getFullYear()} &copy;
                  <a
                    href="https://www.halioglass.com"
                    className="m-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    &nbsp;Halio
                  </a>
                </span>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

const App: React.FC<AppProps> = ({ queryClient: qc }) => {
  return (
    <>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <QueryClientProvider client={qc}>
            <Content />
          </QueryClientProvider>
        </ConnectedRouter>
      </Provider>
    </>
  );
};

// Setup Query Client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <App queryClient={queryClient} />,
  document.getElementById('root'),
);

registerServiceWorker();
