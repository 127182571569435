import { createStandardAction } from 'typesafe-actions';

import { IEvent, IEventFiltersParam, ISite } from '@halio-inc/api-client';

const ENTITY = 'PAGES/ACTIVITY_STREAM';

export default {
  filtersChanged: createStandardAction(`${ENTITY}/FILTERS_CHANGED`)<
    IEventFiltersParam
  >(),
  load: createStandardAction(`${ENTITY}/LOAD`)<{
    count: number;
    filters?: IEventFiltersParam;
    position?: string;
  }>(),
  loadMore: createStandardAction(`${ENTITY}/LOAD_MORE`)<{
    count: number;
    filters?: IEventFiltersParam;
    position: string;
  }>(),
  setEvents: createStandardAction(`${ENTITY}/SET_EVENTS`)<{
    count: number;
    error?: string;
    events: IEvent[];
    filters?: IEventFiltersParam;
    fresh: boolean;
    isMoreEvents?: boolean;
    lastSelectedSite?: ISite;
    position?: string;
  }>(),
  unload: createStandardAction(`${ENTITY}/UNLOAD`)(),
};
