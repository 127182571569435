import * as React from 'react';

import MapPlaceHolderIcon from '../../svgs/MapPlaceHolderIcon';

import './styles.css';

const NoMap = () => (
  <div className="no-map-container">
    <div className="no-map">
      <MapPlaceHolderIcon width="90px" height="90px" />
    </div>
    <div className="no-map__message">
      <p>Map Not Available</p>
    </div>
  </div>
);

export default NoMap;
