import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import './index.css';

import SubHeader from '../../theme/sub-header';

export class Support extends React.Component<WithTranslation> {
  public render() {
    return (
      <div>
        <SubHeader title={this.props.t('title')} />
        <br />
        <div className="m-portlet m-portlet--full-height support-main">
          <div className="m-portlet__body">
            <div className="container">
              <h4 className="content-header">{this.props.t('header')}</h4>
              <h5 className="content-sub-header">
                {this.props.t('sub-header')}
              </h5>
              <div className="row">
                <div className="col-md region">
                  <img
                    alt="america icon"
                    src="/assets/app/media/img/support_america.png"
                    style={{ width: 250, height: 136 }}
                  />
                  <h4>{this.props.t('country-title-america')}</h4>
                  <ul className="list-unstyled hours-and-contact text-center">
                    <li>
                      <img
                        alt="email icon"
                        src="/assets/app/media/img/ico_email.png"
                      />{' '}
                      <a href="mailto:support@kinestral.com">
                        support@kinestral.com
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md region">
                  <img
                    alt="europe icon"
                    src="/assets/app/media/img/support_europe.png"
                    style={{ width: 250, height: 136 }}
                  />
                  <h4>{this.props.t('country-title-europe')}</h4>
                  <ul className="list-unstyled hours-and-contact text-center">
                    <li>
                      <img
                        alt="email icon"
                        src="/assets/app/media/img/ico_email.png"
                      />{' '}
                      <a href="mailto:supportEU@halioglass.com">
                        supportEU@halioglass.com
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md region">
                  <img
                    alt="asia icon"
                    src="/assets/app/media/img/support_asia.png"
                    style={{ width: 250, height: 136 }}
                  />
                  <h4>{this.props.t('country-title-asia')}</h4>
                  <ul className="list-unstyled hours-and-contact text-center">
                    <li>
                      <img
                        alt="email icon"
                        src="/assets/app/media/img/ico_email.png"
                      />{' '}
                      <a href="mailto:asia-help@halioglass.com">
                        asia-help@halioglass.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation('support')(Support);
