import * as React from 'react';

import Locale from '../../../components/locale';

interface IActivityStreamLoadMoreProps {
  readonly loadMore: () => void;
}

export default class ActivityStreamLoadMore extends React.Component<
  IActivityStreamLoadMoreProps,
  any
> {
  public constructor(props: IActivityStreamLoadMoreProps) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  public render() {
    return (
      <div className="m-portlet__foot">
        <button
          className="btn btn-primary"
          id="loadMoreLink"
          onClick={this.handleClick}
        >
          <Locale nameSpace="activity-stream" localeKey="label-load-more" />
        </button>
      </div>
    );
  }

  private handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.loadMore();
  };
}
