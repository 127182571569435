import * as _ from 'lodash';
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  race,
  take,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import sharedActions from '../../lib/shared/actions';
import { requireSelectedSite } from '../../lib/shared/setup-saga';
import actions from './actions';

import { IApiResponse, IArchState, IRollup } from '@halio-inc/api-client';
import ApiClient from '../../lib/web/api-client';

import debug from '../../lib/debug';
const createDebug = debug('pages:mode-browser');

const log = createDebug('sagas');

export default function* ModeBrowserSaga() {
  while (true) {
    // Wait for page load
    yield take(getType(actions.load));

    let handle = yield fork(LoadPageData, true);

    while (true) {
      const {
        changeArchState,
        load,
        selectedSite,
        unload,
      }: {
        changeArchState: ReturnType<typeof actions.changeArchState>;
        load: ReturnType<typeof actions.load>;
        selectedSite: ReturnType<typeof sharedActions.setSite>;
        unload: ReturnType<typeof actions.unload>;
      } = yield race({
        changeArchState: take(getType(actions.changeArchState)),
        load: take(getType(actions.load)),
        selectedSite: take(getType(sharedActions.setSite)),
        unload: take(getType(actions.unload)),
      });

      yield cancel(handle);

      if (unload) {
        log('Page Unloaded');
        break;
      }

      if (changeArchState) {
        yield fork(
          ChangeArchState,
          changeArchState.payload.archItemId,
          changeArchState.payload.state,
        );
        break;
      }

      let fresh = false;

      if (selectedSite || load) {
        fresh = true;
      }

      handle = yield fork(LoadPageData, fresh);
    }
  }
}

function* ChangeArchState(
  archItemId: string,
  state: { [name: string]: string },
) {
  try {
    const siteId = yield* requireSelectedSite();

    const archStatesService = ApiClient.getArchStates();

    yield call([archStatesService, archStatesService.setArchState], {
      archItemId,
      siteId,
      state,
    });

    yield put(actions.load());
  } catch (err) {
    log(err);
  }
}

function* LoadPageData(fresh: boolean) {
  try {
    const siteId = yield* requireSelectedSite();

    if (yield cancelled()) {
      log('cancel handeled1');
      return;
    }

    if (fresh) {
      const archStatesService = ApiClient.getArchStates();

      const archStatesResponse: IApiResponse<IArchState[]> = yield call(
        [archStatesService, archStatesService.getArchStates],
        {
          siteId,
          types: ['location', 'window'],
        },
      );

      if (yield cancelled()) {
        log('cancel handeled2');
        return;
      }

      if (archStatesResponse.success) {
        yield put(actions.setArchStates(archStatesResponse.results));
      }
    }

    const rollupsService = ApiClient.getRollups();

    const rollupsResponse: IApiResponse<IRollup[]> = yield call(
      [rollupsService, rollupsService.getRollups],
      {
        limit: 500,
        objectTypes: ['location'],
        period: '5m',
        relative: '5m',
        siteId,
        type: 'window_tint',
      },
    );

    if (yield cancelled()) {
      log('cancel handeled3');
      return;
    }

    if (rollupsResponse.success) {
      yield put(
        actions.setRollups(
          _
            .chain(rollupsResponse.results)
            .keyBy('objectId')
            .reduce(
              (
                result: { [locationId: string]: string },
                value: IRollup,
                key: string,
              ) => {
                result[key] = value.value;
                return result;
              },
              {},
            )
            .value(),
        ),
      );
    }
  } finally {
    if (yield cancelled()) {
      log('cancel handeled');
    }
  }
}
