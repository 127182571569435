import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { ISite } from '@halio-inc/api-client';

import SiteSummary from '../components/site-summary';
import ActionTypes from '../lib/action-types';
import SharedActions from '../lib/shared/actions';
import IState from '../lib/state';

import './header.css';

interface IStateProps {
  readonly sites?: ISite[];
  readonly selectedSite?: ISite;
}

interface IDispatchProps {
  setSite?: (
    site: ISite | undefined,
  ) => ReturnType<typeof SharedActions.setSite>;
}

interface IHeaderProps
  extends RouteComponentProps<any>,
    IStateProps,
    IDispatchProps {}

const HeaderMobile = (props: IHeaderProps) => {
  if (!props.selectedSite) {
    return null;
  }

  const setSite = async (site: ISite) => {
    const $closeBtn = document.getElementById(
      'm_aside_header_menu_mobile_close_btn',
    );
    const $headerMenu = document.getElementById('m_header_menu');

    if ($closeBtn && $headerMenu) {
      $closeBtn.click();
      $headerMenu.scrollTo(0, 0);
    }

    if (props.setSite) {
      props.setSite(site);
    }
  };

  return (
    <div id="m-menu_mobile-nav">
      <div className="m-menu__nav-title-container">
        <h2 className="m-menu__nav-title">
          <span className="m-menu__nav-name">{props.selectedSite.name}</span>
          <SiteSummary style={{ fontSize: '16px' }} prefix=" - " />
        </h2>
        <div className="siteAddress">
          {_.get(props.selectedSite, 'attributes.siteinfo.address.value', '')}
        </div>
      </div>
      <ul className="m-menu__nav m-menu__nav--submenu-arrow ">
        <li className="m-menu__item m-menu__item--submenu m-menu__item--rel">
          <div className="m-menu__submenu--classic m-menu__submenu--left">
            <span className="m-menu__arrow" />
            <ul className="m-menu__subnav site_switcher">
              {!props.sites
                ? null
                : props.sites.map(site => (
                    <li className="m-menu__item " key={site.id}>
                      <button
                        className="m-menu__link btn-blank"
                        onClick={setSite.bind({}, site)}
                      >
                        <span
                          className={`m-menu__link-text ${
                            _.get(props.selectedSite, 'id', '') === site.id
                              ? 'm-menu__link-text-selected'
                              : ''
                          }`}
                        >
                          {site.name}
                        </span>
                      </button>
                    </li>
                  ))}
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
};

function mapStateToProps(state: IState) {
  return {
    selectedSite: _.get(state, 'shared.lastSelectedSite', {}),
    sites: _.get(state, 'shared.sites', []),
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    {
      setSite: SharedActions.setSite,
    },
    dispatch,
  );

export default withRouter(
  connect<IStateProps, IDispatchProps, IHeaderProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(HeaderMobile),
);
