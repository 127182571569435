import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const ManualIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '27px'}
    height={props.height ? props.height : '27px'}
    viewBox="0 0 37 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="manual-icon"
        transform="translate(18.292683, 25.000000) scale(-1, 1) rotate(-180.000000) translate(-18.292683, -25.000000) "
        fill="#8788A6"
        fillRule="nonzero"
      >
        <path
          d="M16.5982691,49.8706736 C10.7220724,48.8717638 7.09415966,42.5786324 9.0869568,36.9347923 C9.44463834,35.8859371 9.95561196,35.3864822 10.670975,35.3864822 C11.4885328,35.3864822 11.6418249,35.5862642 11.3352407,36.5352284 C9.34244361,42.9282508 12.4082854,47.8728541 18.4377741,47.8728541 C23.9051919,47.8728541 27.2776178,43.3278147 25.6425022,38.0835385 C25.2848207,36.9347923 25.1315286,35.8359916 25.2848207,35.6861552 C26.5622548,34.3875725 28.146273,37.2344653 28.0440783,40.6807039 C27.9929809,46.4244349 22.4744658,50.8196378 16.5982691,49.8706736 Z"
          id="Path"
        />
        <path
          d="M15.9620012,41.886339 C15.4421711,41.2848197 14.714409,38.5779828 14.0386299,34.2169677 C13.4148338,30.5075985 12.7910378,27.2994955 12.5831057,27.0989891 C12.3231907,26.848356 10.1918874,26.1465835 7.80066904,25.4949376 C4.83763761,24.6427852 3.01823235,23.7906328 1.9785722,22.7881006 C-0.51661216,20.4321499 -0.568595167,18.8280984 1.35477611,10.3567013 C2.91426633,3.73998882 3.27814739,2.73745662 4.68168859,1.48429137 C6.18919581,0.180999507 6.44911084,0.130872897 12.6350887,0.0306196768 C20.6404719,-0.0696335431 21.264268,0.0306196768 23.0836732,1.03315188 C24.5391975,1.88530425 34.5199349,15.9708817 36.1833911,19.529871 C37.1190853,21.585062 36.3913232,23.3896199 34.3120029,24.1916457 C32.5965636,24.8934182 31.6088865,24.4924054 28.281974,21.835695 C27.0863648,20.933416 25.6308406,20.1815169 25.0590275,20.1815169 C23.7074693,20.1815169 23.6035033,20.682783 22.8757412,31.7106372 C22.4078941,38.2270965 22.0959961,40.2321609 21.368234,41.2346931 C20.1726248,42.8387446 17.2095934,43.1896309 15.9620012,41.886339 Z M20.0166758,40.1319077 C20.1206418,39.7810214 20.5365059,35.1192467 20.9523699,29.805826 C21.576166,21.9359482 21.8880641,19.9810105 22.6158262,19.1789847 C24.1753164,17.5248066 26.3586027,17.9258194 29.6855152,20.4321499 C33.0124277,22.9384804 33.5322577,23.0888603 34.2600199,21.7354418 C34.7278669,20.9835427 33.8441558,19.3293645 29.3216341,12.6123988 C26.3586027,8.10100388 23.3955713,3.89036865 22.7717752,3.28884933 C21.732115,2.18606391 21.264268,2.1359373 14.298545,2.1359373 C9.20421024,2.1359373 6.70902588,2.33644374 6.08522979,2.78758323 C5.04556964,3.48935577 2.08253822,14.9683495 2.08253822,18.0761993 C2.08253822,21.1339225 3.12219836,21.9860749 8.84032919,23.6903796 C12.0112926,24.6427852 14.194579,25.5951908 14.506477,26.0964569 C14.766392,26.597723 15.3901881,29.555193 15.9100182,32.7131694 C16.3778653,35.8711458 17.0016614,38.9789956 17.2095934,39.6306416 C17.6774405,40.8336802 19.6527947,41.1845665 20.0166758,40.1319077 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default ManualIcon;
