import * as React from 'react';

const HeaderLoading = () => (
  <div
    id="m-header-loading"
    className="m-container m-container--fluid m-container--full-height"
  >
    <div className="m-stack m-stack--ver m-stack--desktop">
      <div className="m-stack__item m-brand  m-brand--skin-dark ">
        <div className="m-stack m-stack--ver m-stack--general">
          <div className="m-stack__item m-stack__item--middle m-brand__logo">
            <button className="m-brand__logo-wrapper btn-blank">
              <img alt="" src="/logo.png" height="70px" />
            </button>
          </div>
          <div className="m-stack__item m-stack__item--middle m-brand__tools">
            <button
              id="m_aside_left_minimize_toggle"
              className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block btn-blank"
            >
              <span />
            </button>
            <button
              id="m_aside_left_offcanvas_toggle"
              className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block btn-blank"
            >
              <span />
            </button>
            <button
              id="m_aside_header_menu_mobile_toggle"
              className="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block btn-blank"
            >
              <span />
            </button>
            <button
              id="m_aside_header_topbar_mobile_toggle"
              className="m-brand__icon m--visible-tablet-and-mobile-inline-block btn-blank"
            >
              <i className="flaticon-more" />
            </button>
          </div>
        </div>
      </div>
      <div
        className="m-stack__item m-stack__item--fluid m-header-head"
        id="m_header_nav"
      >
        <button
          className="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-dark "
          id="m_aside_header_menu_mobile_close_btn"
        >
          <i className="la la-close" />
        </button>
        <div
          id="m_header_menu"
          className="m-topbar__loading m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark "
        >
          <ul className="m-menu__nav  m-menu__nav--submenu-arrow ">
            <li
              className="m-menu__item  m-menu__item--submenu m-menu__item--rel"
              m-menu-submenu-toggle="click"
              m-menu-link-redirect="1"
              aria-haspopup="true"
            >
              <p className="message">Loading...</p>
            </li>
          </ul>
        </div>
        <div
          id="m_header_topbar"
          className="m-topbar__loading m-topbar  m-stack m-stack--ver m-stack--general m-stack--fluid"
        />
      </div>
    </div>
  </div>
);

export default HeaderLoading;
