import chartjs from 'chart.js';
import _ from 'lodash';
import moment from 'moment-timezone';
import 'pleasejs';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import LoadingSpinner from '../../components/LoadingSpinner';
import Locale from '../../components/locale';
import DashboardChart from '../../components/widgets/dashboard-chart';
import HelpPopup from '../../components/widgets/help-popup';
import MapWidget from '../../components/widgets/map';
import ModesWidget from '../../components/widgets/modes';
import SunPositionsWidget from '../../components/widgets/sun-positions';

import SubHeader from '../../theme/sub-header';

import { ISite } from '@halio-inc/api-client';

import ActionTypes from '../../lib/action-types';
import IState from '../../state';
import Actions from './actions';
import IHomeStateProps from './state';

import './index.css';

const yesterday = moment().subtract(1, 'day');
const maxSkycamera = yesterday.format('YYYY-MM-DD');
const minSkycamera = yesterday.subtract(3, 'weeks').format('YYYY-MM-DD');

interface IDispatchProps {
  onLoad: (
    {
      type,
      length,
    }: {
      type: string;
      length: string;
    },
  ) => ReturnType<typeof Actions.load>;
  onTimeRangeChanged: (
    {
      type,
      length,
    }: {
      type: string;
      length: string;
    },
  ) => ReturnType<typeof Actions.timeRangeChanged>;
  onUnload: () => ReturnType<typeof Actions.unload>;
}

// Where the any in RouteComponentProps<any> is the interface for props.match.params
interface IHomeProps
  extends RouteComponentProps<any>,
    IHomeStateProps,
    IDispatchProps {
  selectedSite: ISite;
}

interface IHomeState {
  locationDatasetMap: {
    [locationId: string]: number;
  };
  data: chartjs.ChartData;
  isFilteredByWeek?: boolean;
  selectedTimeRange: string;
  selectedSkycameraDate: string;
  skycameraVideoError: boolean;
  selectedSite?: ISite;
}

interface IHomeDataSetWithColors {
  backgroundColor: string;
  pointBackgroundColor: string[];
  pointBorderColor: string[];
}

export class Home extends React.Component<
  IHomeProps & WithTranslation,
  IHomeState
> {
  public static getDerivedStateFromProps(props: IHomeProps, state: IHomeState) {
    let changed = false;

    if (props.locations.length && props.chart.labels.length) {
      if (props.chart.labels !== state.data.labels) {
        changed = true;
        state.data.labels = props.chart.labels;
      }

      const newMap = {};
      const datasets: chartjs.ChartDataSets[] = [];

      props.locations.forEach(location => {
        newMap[location.id] = datasets.length;

        let dataset: chartjs.ChartDataSets = {
          fill: false,
        };

        if (state.data.datasets) {
          const idx = state.locationDatasetMap[location.id];

          if (idx) {
            dataset = state.data.datasets[idx];
          } else {
            changed = true;
          }
        }

        if (dataset.data !== props.chart.datasets[location.id]) {
          changed = true;
          dataset.data = props.chart.datasets[
            location.id
          ] as chartjs.ChartPoint[];
        }

        if (
          dataset.label !== location.name ||
          (dataset.label && location.name)
        ) {
          changed = true;
          dataset.label = location.name;
          dataset.hidden = false;

          const dataSetWithColors = Home.setDataPointColors(
            dataset.data,
            dataset.label,
          );

          dataset = {
            ...dataset,
            ...dataSetWithColors,
          };
        }

        datasets.push(dataset);
      });

      state.locationDatasetMap = newMap;
      state.data.datasets = datasets;
    }
    if (props.selectedSite) {
      if (
        !state.selectedSite ||
        props.selectedSite.id !== state.selectedSite.id
      ) {
        changed = true;
        state.skycameraVideoError = false;
        state.selectedSite = props.selectedSite;
      }
      if (!state.selectedSkycameraDate) {
        changed = true;
        let time = moment().startOf('day');
        const siteTz: string = _.get(
          props.selectedSite,
          'attributes.siteinfo.timezone.value',
          'UTC',
        );
        time = time.tz(siteTz).subtract(1, 'day');
        state.selectedSkycameraDate = time.format('YYYY-MM-DD');
      }
    }

    if (changed) {
      return state;
    }

    return null;
  }

  private static setDataPointColors(
    dataSet: Array<chartjs.ChartPoint | undefined | null | number> = [],
    locationName: string,
  ) {
    const Please = require('pleasejs');
    const generatedColor = Please.make_color({ seed: locationName });
    const pointColor = _.get(generatedColor, '[0]', '');
    const finalDataSet: IHomeDataSetWithColors = {
      backgroundColor: pointColor,
      pointBackgroundColor: [],
      pointBorderColor: [],
    };

    dataSet.forEach(
      (data: number | undefined | null | chartjs.ChartPoint, index: number) => {
        if (
          index === 0 ||
          data !== dataSet[index - 1] ||
          data !== dataSet[index + 1]
        ) {
          finalDataSet.pointBackgroundColor.push(pointColor);
          finalDataSet.pointBorderColor.push(pointColor);
        } else {
          finalDataSet.pointBackgroundColor.push('rgba(0, 0, 0, 0)');
          finalDataSet.pointBorderColor.push('rgba(0, 0, 0, 0)');
        }
      },
    );

    return finalDataSet;
  }

  public readonly state = {
    data: {
      datasets: [],
      labels: [],
    },
    isFilteredByWeek: false,
    locationDatasetMap: {},
    selectedSite: undefined,
    selectedSkycameraDate: '',
    selectedTimeRange: '1d',
    skycameraVideoError: false,
  };

  public componentDidMount() {
    this.props.onLoad({
      length: '1d',
      type: '5m',
    });
  }

  public componentWillUnmount() {
    this.props.onUnload();
  }

  public render() {
    const localizeNameSpace = 'dashboard';
    const timezone: string = _.get(
      this.props.selectedSite,
      'attributes.siteinfo.timezone.value',
      '',
    );

    return (
      <div className="home-page">
        <SubHeader title={this.props.t('title')}>
          <div>
            <button
              type="button"
              className={
                'btn btn-sm m-btn--pill btn' +
                (this.state.selectedTimeRange === '1d' ? '' : 'outline') +
                '-brand m-btn m-btn--custom'
              }
              style={{ marginLeft: 5 }}
              onClick={this.updateTimeRange.bind(this, '1d')}
            >
              {this.props.t('time-range-1day')}
            </button>
            <button
              type="button"
              className={
                'btn btn-sm m-btn--pill btn' +
                (this.state.selectedTimeRange === '1w' ? '' : 'outline') +
                '-brand m-btn m-btn--custom'
              }
              style={{ marginLeft: 5 }}
              onClick={this.updateTimeRange.bind(this, '1w')}
            >
              {this.props.t('time-range-1week')}
            </button>
            <button
              type="button"
              className={
                'btn btn-sm m-btn--pill btn' +
                (this.state.selectedTimeRange === '3w' ? '' : 'outline') +
                '-brand m-btn m-btn--custom'
              }
              style={{ marginLeft: 5 }}
              onClick={this.updateTimeRange.bind(this, '3w')}
            >
              {this.props.t('time-range-3weeks')}
            </button>
          </div>
        </SubHeader>
        <div className="m-content">
          <div className="row">
            <div className="col-12">
              <div className="m-portlet">
                <div className="m-portlet__head">
                  <div className="m-portlet__head-caption widget__header-wrapper">
                    <div className="row">
                      <div className="m-portlet__head-title col-11">
                        <h3 className="m-portlet__head-text">
                          {this.props.t('widget-tint-location-title')}
                          <span className="timezone-label">
                            {' - '}
                            {this.props.t('shared:label-time-zone-utc')}
                          </span>
                        </h3>
                      </div>
                      <div className="col-1 help-popup-container">
                        <HelpPopup
                          message={this.props.helpMessages.tintWidget.message}
                          hintMessage={
                            this.props.helpMessages.tintWidget.hintMessage
                          }
                          localizeNameSpace={localizeNameSpace}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`m-portlet__body chart-wrapper ${this.getClassForHorizontalScroll(
                    this.state.selectedTimeRange,
                  )}`}
                >
                  {this.props.isLoadingChart && (
                    <LoadingSpinner width="90px" height="90px" />
                  )}
                  <DashboardChart
                    buttonId="tintByLocationChartBtn"
                    chartData={this.state.data}
                    chartType="line"
                    customClass={
                      this.props.isLoadingChart
                        ? 'default-loading home-tint-chart'
                        : `home-tint-chart ${this.getClassForHorizontalScroll(
                            this.state.selectedTimeRange,
                          )}`
                    }
                    isTooltipOffset
                    mode="nearest"
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              max: 100,
                            },
                          },
                        ],
                      },
                    }}
                    plugins={{
                      dataSeparation: {
                        isWeekly: this.state.isFilteredByWeek,
                        labelChangePoints: this.getLabelChangePoints(
                          _.get(this, 'state.data.labels', []),
                        ),
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="m-portlet">
                <div className="m-portlet__head">
                  <div className="m-portlet__head-caption widget__header-wrapper">
                    <div className="row">
                      <div className="m-portlet__head-title col-11">
                        <h3 className="m-portlet__head-text">
                          {this.props.t('widget-skycamera-effect-title')}
                        </h3>
                      </div>
                      <div className="col-1">
                        <HelpPopup
                          message={
                            this.props.helpMessages.skycameraEffect.message
                          }
                          hintMessage={
                            this.props.helpMessages.skycameraEffect.hintMessage
                          }
                          localizeNameSpace={localizeNameSpace}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="m-portlet__body">
                  {this.props.isLoading ? (
                    <div className=" d-flex justify-content-center">
                      <LoadingSpinner width="90px" height="90px" />
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-sm-12 pb-3">
                        {`${this.props.t(
                          'widget-skycamera-effect-view-date',
                        )} `}
                        <input
                          type="date"
                          min={minSkycamera}
                          max={maxSkycamera}
                          onChange={e => this.updateSkycameraDate(e)}
                          value={this.state.selectedSkycameraDate}
                        />
                      </div>
                      {!this.state.skycameraVideoError && (
                        <div className="col-sm-12">
                          <video
                            key={this.state.selectedSkycameraDate}
                            height="400px"
                            onError={() => this.videoError()}
                            width="100%"
                            controls
                          >
                            <source
                              src={`${
                                process.env.REACT_APP_SKYCAMERA_BUCKET_URL
                              }/${this.props.selectedSite.id}/videos/${
                                this.state.selectedSkycameraDate
                              }/glarecontrol-effect.mp4`}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      )}
                      {this.state.skycameraVideoError && (
                        <div className="col-sm-12">
                          {this.props.t('widget-skycamera-effect-error-msg')}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
              <div className="m-portlet">
                <div className="m-portlet__head">
                  <div className="m-portlet__head-caption widget__header-wrapper">
                    <div className="row">
                      <div className="m-portlet__head-title col-11">
                        <h3 className="m-portlet__head-text">
                          {this.props.t('widget-current-modes-title')}
                        </h3>
                      </div>
                      <div className="col-1 help-popup-container">
                        <HelpPopup
                          message={this.props.helpMessages.modesWidget.message}
                          hintMessage={
                            this.props.helpMessages.modesWidget.hintMessage
                          }
                          localizeNameSpace={localizeNameSpace}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="m-portlet__body current-mode-widget">
                  {this.props.isLoading ? (
                    <LoadingSpinner width="90px" height="90px" />
                  ) : (
                    <ModesWidget />
                  )}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 map-widget-container">
              <div className="m-portlet">
                <div className="help-content">
                  <HelpPopup
                    message={this.props.helpMessages.mapWidget.message}
                    hintMessage={this.props.helpMessages.mapWidget.hintMessage}
                    localizeNameSpace={localizeNameSpace}
                  />
                </div>
                <div className="m-portlet__body map-widget">
                  {this.props.isLoading ? (
                    <LoadingSpinner width="90px" height="90px" />
                  ) : (
                    <MapWidget
                      height={300}
                      address={_.get(
                        this.props.selectedSite,
                        'attributes.siteinfo.address.value',
                      )}
                      latitude={parseFloat(
                        _.get(
                          this.props.selectedSite,
                          'attributes.siteinfo.latitude.value',
                        ),
                      )}
                      longitude={parseFloat(
                        _.get(
                          this.props.selectedSite,
                          'attributes.siteinfo.longitude.value',
                        ),
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
              <div className="m-portlet">
                <div className="m-portlet__head">
                  <div className="m-portlet__head-caption widget__header-wrapper">
                    <div className="row">
                      <div className="m-portlet__head-title col-11">
                        <h3 className="m-portlet__head-text">
                          {this.props.t('widget-sun-position-title')}
                          {timezone &&
                            timezone !== 'UTC' && (
                              <span className="timezone-label">
                                {' - '}
                                <Locale
                                  nameSpace="shared"
                                  localeKey="label-time-zone"
                                />{' '}
                                {timezone}
                              </span>
                            )}
                        </h3>
                      </div>
                      <div className="col-1 help-popup-container">
                        <HelpPopup
                          message={
                            this.props.helpMessages.sunPositionWidget.message
                          }
                          hintMessage={
                            this.props.helpMessages.sunPositionWidget
                              .hintMessage
                          }
                          localizeNameSpace={localizeNameSpace}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="m-portlet__body sun-position-widget">
                  {this.props.isLoading ? (
                    <LoadingSpinner width="90px" height="90px" />
                  ) : (
                    <SunPositionsWidget
                      latitude={parseFloat(
                        _.get(
                          this.props.selectedSite,
                          'attributes.siteinfo.latitude.value',
                        ),
                      )}
                      longitude={parseFloat(
                        _.get(
                          this.props.selectedSite,
                          'attributes.siteinfo.longitude.value',
                        ),
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private getLabelChangePoints(labels: string[]) {
    if (!labels.length) {
      return [];
    }

    let currentLabel = '';
    const newArr: number[] = [];

    labels.forEach((label, index) => {
      const currentLabelName = label.split(' ')[1];

      if (currentLabel !== currentLabelName || labels.length - 1 === index) {
        currentLabel = currentLabelName;
        newArr.push(index);
      }
    });

    return newArr;
  }

  private updateTimeRange(type: string) {
    const selectAllBtnElm = document.getElementById('tintByLocationChartBtn');
    const triggerBtn = () => {
      if (selectAllBtnElm && selectAllBtnElm.dataset.isActive === 'true') {
        selectAllBtnElm.click();
      }
    };

    switch (type) {
      case '1d':
        triggerBtn();
        this.setState({
          isFilteredByWeek: false,
          selectedTimeRange: type,
        });
        this.props.onTimeRangeChanged({
          length: '1d',
          type: '5m',
        });
        break;
      case '1w':
        triggerBtn();
        this.setState({
          isFilteredByWeek: true,
          selectedTimeRange: type,
        });
        this.props.onTimeRangeChanged({
          length: '1w',
          type: '1h',
        });
        break;
      case '3w':
        triggerBtn();
        this.setState({
          isFilteredByWeek: true,
          selectedTimeRange: type,
        });
        this.props.onTimeRangeChanged({
          length: '3w',
          type: '6h',
        });
        break;
    }
  }

  private updateSkycameraDate(date: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      selectedSkycameraDate: date.target.value,
      skycameraVideoError: false,
    });
  }

  private videoError() {
    this.setState({
      skycameraVideoError: true,
    });
  }

  private getClassForHorizontalScroll(timeRange: string) {
    return timeRange !== '1d' ? 'add-horizontal-scroll' : '';
  }
}

function mapStateToProps(state: IState) {
  return {
    chart: _.get(state, 'pages.home.chart', undefined),
    helpMessages: _.get(state, 'pages.home.helpMessages', []),
    isLoading: _.get(state, 'pages.home.isLoading', false),
    isLoadingChart: _.get(state, 'pages.home.isLoadingChart', false),
    locations: _.get(state, 'pages.home.locations', []),
    selectedSite: _.get(state, 'shared.lastSelectedSite', null),
  };
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) =>
  bindActionCreators(
    {
      onLoad: Actions.load,
      onTimeRangeChanged: Actions.timeRangeChanged,
      onUnload: Actions.unload,
    },
    dispatch,
  );

export default withTranslation(['dashboard', 'shared'])(
  withRouter(
    connect<IHomeStateProps, IDispatchProps, IHomeProps>(
      mapStateToProps,
      mapDispatchToProps,
    )(Home),
  ),
);
