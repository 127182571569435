import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const SettingsIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '26px'}
    height={props.height ? props.height : '26px'}
    viewBox="0 0 83 83"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="ico_settings@3x" fill="#4584BE" fillRule="nonzero">
        <g
          id="Group"
          transform="translate(41.500000, 41.400000) scale(-1, 1) rotate(-180.000000) translate(-41.500000, -41.400000) translate(0.100000, -0.000000)"
        >
          <path
            d="M34,80.6 C33.5,79.3 33,77.4 32.8,76.3 C32.5,75.2 30.7,73.4 28.7,72.3 L25,70.4 L21,72.6 C16.4,75.2 15.2,74.9 10.4,69.8 L7.4,66.7 L9.9,62.1 L12.3,57.6 L10.3,54 C8.9,51.3 7.5,50.2 5.1,49.7 C0.3,48.7 -0.2,47.8 0.1,41.2 L0.4,35.4 L4.4,34.3 C7.4,33.4 8.9,32.3 10.4,29.5 L12.3,25.9 L10.1,22 C8,18.3 8,14.8 10,14.8 C11.4,14.8 15.9,23.4 15.9,26.2 C15.9,29.3 13.3,34.8 11,36.5 C10.1,37.1 8.2,37.9 6.7,38.2 C4.4,38.7 3.9,39.3 3.9,41.8 C3.9,44.3 4.4,44.9 6.7,45.4 C10.4,46.1 12.3,47.7 14.2,51.5 C16.4,55.6 16.4,60.2 14.2,63.4 C12.7,65.8 12.7,66 14.6,68 C16.5,69.9 16.8,69.9 19.7,68.4 C26.5,64.9 34.6,67.8 36.7,74.6 C37.9,78.3 38.5,78.8 41,78.8 C43.5,78.8 44,78.3 44.5,75.9 C45.6,70.5 50.7,66.8 57,66.8 C59.8,66.8 67.9,71.3 67.9,72.8 C67.9,74.7 64.3,74.7 61.1,72.8 C55.1,69.2 50.4,71.4 48.4,78.7 C47.3,82.3 47.3,82.3 41.1,82.6 C35.4,82.9 34.8,82.7 34,80.6 Z"
            id="Path"
          />
          <path
            d="M68.3,64.6 C63.6,57.2 66.5,47.8 74,45.8 C77.4,44.9 77.9,44.4 77.9,41.8 C77.9,39.2 77.4,38.7 74,37.8 C67.3,36 64,27.5 67.4,20.8 C69,17.7 69,17.5 67.1,15.5 C65.1,13.6 64.9,13.6 62.5,15.1 C59.1,17.4 54.7,17.2 49.9,14.8 C46.8,13.2 45.8,12 44.9,8.8 C44,5.3 43.5,4.8 40.9,4.8 C38.3,4.8 37.8,5.3 36.9,8.8 C36,12 35,13.2 31.9,14.8 C29.7,15.9 26.6,16.8 24.9,16.8 C21.8,16.8 13.9,12.5 13.9,10.9 C13.9,8.9 17.4,8.9 21.1,11 L25,13.2 L28.6,11.3 C31.3,9.8 32.5,8.3 33.3,5.3 C34.2,2.1 35.1,1.2 37.7,0.4 C39.7,-0.1 42.1,-0.1 44.1,0.4 C46.7,1.2 47.6,2.1 48.5,5.3 C49.3,8.3 50.5,9.8 53.2,11.3 L56.8,13.2 L60.9,10.9 C65.1,8.6 65.1,8.6 67.7,10.5 C69.1,11.6 71.1,13.6 72.2,15 C74.1,17.6 74.1,17.6 71.8,21.8 L69.5,25.9 L71.4,29.5 C72.9,32.2 74.4,33.4 77.3,34.2 C81.8,35.5 83.5,38.7 82.5,44.3 C81.7,48.1 81,48.8 76.5,49.7 C74.3,50.2 72.8,51.4 71.4,54 L69.5,57.7 L71.7,61.6 C73.8,65.3 73.8,68.8 71.8,68.8 C71.3,68.8 69.7,66.9 68.3,64.6 Z"
            id="Path"
          />
          <path
            d="M32.9,57.6 C19.7,50.9 19.7,32.7 32.9,26 C38.5,23.2 43.3,23.2 48.9,26 C62.1,32.7 62.1,50.9 48.9,57.6 C46.5,58.8 42.9,59.8 40.9,59.8 C38.9,59.8 35.3,58.8 32.9,57.6 Z M48.1,53.1 C52.8,50.8 55.3,44 53.7,38.2 C50.2,25.3 31.6,25.2 28.1,38.2 C26.1,45.2 29.6,51.9 36.4,54.3 C40.6,55.7 43.6,55.4 48.1,53.1 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SettingsIcon;
