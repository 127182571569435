import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';

const defaultData = {
  datasets: [
    {
      data: [100],
    },
  ],
  labels: ['No Data Recorded'],
};

const defaultOptions = {
  responsive: true,
};

const NoModesWidget = () => (
  <Doughnut
    data={defaultData}
    legend={{ position: 'bottom' }}
    options={defaultOptions}
  />
);

export default NoModesWidget;
