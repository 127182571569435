import ApiClient, { IApiResponse } from '@halio-inc/api-client';
import { ExtendedArchItems } from './arch-items';

const apiUrl = window.HALIO_CONFIG.API_V3;

class ExtendedRawAPIClient extends ApiClient {
  public async rawGet<T>(path: string): Promise<IApiResponse<T>> {
    const response = await this.client.get<IApiResponse<T>>(
      `${apiUrl}/${path}`,
    );
    return response.data;
  }
  public async rawPost<T>(path: string, data?: any): Promise<IApiResponse<T>> {
    const response = await this.client.post<IApiResponse<T>>(
      `${apiUrl}/${path}`,
      data,
    );
    return response.data;
  }
  public async rawPut<T>(path: string, data?: any): Promise<IApiResponse<T>> {
    const response = await this.client.put<IApiResponse<T>>(
      `${apiUrl}/${path}`,
      data,
    );
    return response.data;
  }
  public async rawDelete<T>(path: string): Promise<IApiResponse<T>> {
    const response = await this.client.delete<IApiResponse<T>>(
      `${apiUrl}/${path}`,
    );
    return response.data;
  }

  public getArchItems(headers?: any): ExtendedArchItems {
    return new ExtendedArchItems(this.client, apiUrl, headers);
  }
}

export default new ExtendedRawAPIClient(apiUrl);
