import { push } from 'react-router-redux';
import { call, put, race, select, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { IAuth } from '@halio-inc/react-authentication';

import {
  ApiClient,
  IAccount,
  IApiResponse,
  ISite,
} from '@halio-inc/api-client';

import sharedActions from './actions';
import { getLastSelectedSiteId } from './selectors';

import { restoreSiteId, saveSiteId } from './storage';

import debug from '../../lib/debug';
const createDebug = debug('lib:shared');

const guid = /([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/i;
let selectedSiteId: string | undefined;
const log = createDebug('sagas');

function processSelectedSite(
  sites: ISite[],
  newId = selectedSiteId,
): ISite | undefined {
  return (
    (newId && sites && sites.length > 0
      ? sites.find(s => s.id === newId)
      : undefined) ||
    (newId !== selectedSiteId ? processSelectedSite(sites) : undefined) ||
    sites[0]
  );
}

function* updateSelectedSite(newId: string, sites: ISite[]) {
  if (newId !== selectedSiteId) {
    const foundSite = processSelectedSite(sites, newId);

    if (foundSite) {
      // TODO: This should be moved into a web specific code base
      yield put(push(window.location.pathname.replace(guid, foundSite.id)));

      if (foundSite.id !== selectedSiteId) {
        selectedSiteId = foundSite.id;
        yield put(sharedActions.setSite(foundSite));
        yield call(saveSiteId, selectedSiteId);
      }
    }
  }
}

export default function* setupSaga(auth: IAuth, client: ApiClient) {
  auth.setSessionSetCallback(accessToken => client.setAccessToken(accessToken));
  client.setAuthErrorCallback(async () => {
    return (await auth.getAccessToken()) || '';
  });

  while (true) {
    let redirectUrl: string = '/';
    // Wait for login
    try {
      redirectUrl = yield auth.handleAuthentication();
    } catch (e) {
      log(e);
    }

    const isAuthenticated: boolean = yield call(auth.isAuthenticated);

    if (!isAuthenticated) {
      return yield auth.login();
    }

    if (window.location.pathname === '/callback') {
      yield put(push(redirectUrl));
    }

    client.setAccessToken(yield call(auth.getAccessToken));

    selectedSiteId = yield call(restoreSiteId);

    const sitesService = client.getSites();
    const accountsService = client.getAccounts();

    // Pull data we need almost always
    const sites: IApiResponse<ISite[]> = yield call(
      [sitesService, sitesService.getSites],
      {
        grant: 'shared-app-dashboard',
      },
    );

    yield put(sharedActions.setSites(sites.results));
    yield put(sharedActions.setSite(processSelectedSite(sites.results)));

    const account: IApiResponse<IAccount> = yield call([
      accountsService,
      accountsService.getAccountInfo,
    ]);
    yield put(sharedActions.setMe(account.results));

    // TODO: This should be moved into a web specific code base
    const matches = window.location.pathname.match(guid);
    if (matches) {
      yield updateSelectedSite(matches[1], sites.results);
    }

    while (true) {
      // Wait for logout
      const {
        logOut,
        setSite,
        setSites,
      }: {
        logOut: ReturnType<typeof sharedActions.logOut>;
        setSite: ReturnType<typeof sharedActions.setSite>;
        setSites: ReturnType<typeof sharedActions.setSites>;
      } = yield race({
        logOut: take(getType(sharedActions.logOut)),
        setSite: take(getType(sharedActions.setSite)),
        setSites: take(getType(sharedActions.setSites)),
      });

      if (logOut) {
        // Send clear command
        yield put(sharedActions.clear());
        auth.logout();
        return;
      }

      if (setSite) {
        if (setSite.payload) {
          yield updateSelectedSite(setSite.payload.id, sites.results);
        }
      } else if (setSites) {
        yield put(sharedActions.setSite(processSelectedSite(setSites.payload)));
      }
    }
  }
}

export function* requireSelectedSite() {
  const siteId = yield select(getLastSelectedSiteId);

  log(siteId);

  if (!siteId) {
    while (true) {
      const result: ReturnType<typeof sharedActions.setSite> = yield take(
        getType(sharedActions.setSite),
      );

      log(result.payload);

      if (result.payload) {
        return result.payload.id;
      }
    }
  }

  return siteId;
}
