import { IArchItem, IScene } from '@halio-inc/api-client';

export interface ISetWindows extends IArchItem {
  sceneLevel: number;
  location: IArchItem;
}
export interface ICompleteScene extends IScene {
  windows: ISetWindows[];
}

export function setWindowLocations(tree: ISetWindows[]): void {
  let lastLocation: ISetWindows;
  const setWindowLocation = (parents: ISetWindows[]) => {
    parents.forEach((childItem: ISetWindows) => {
      if (childItem.type === 'location') {
        lastLocation = childItem;
      }
      if (childItem.type === 'window') {
        childItem.location = lastLocation;
      }
      if (childItem.hasOwnProperty('items') && childItem.items.length > 0) {
        setWindowLocation(childItem.items as ISetWindows[]);
      }
    });
  };

  setWindowLocation(tree);
}

export function flattenArchItems<T>(tree: T[]): T[] {
  const flatten = (parentItems: any[]): any[] => {
    const flat: T[] = [];
    parentItems.forEach((childItem: any) => {
      const copy = Object.assign({}, childItem);
      delete copy.items;
      flat.push(copy);
      if (childItem.hasOwnProperty('items') && childItem.items.length > 0) {
        flat.push(...flatten(childItem.items));
      }
    });
    return flat;
  };

  return flatten(tree);
}
