import { combineReducers } from 'redux';

import activityStream from './activity-stream/reducer';
import home from './home/reducer';
import modeBrowser from './mode-browser/reducer';
import schedule from './schedule/reducer';

const Reducer = combineReducers({
  activityStream,
  home,
  modeBrowser,
  schedule,
});

export default Reducer;
