import { createStandardAction } from 'typesafe-actions';

import { IArchItem, IRollup } from '@halio-inc/api-client';

const ENTITY = 'COMPONENTS/WIDGETS/TINT_BY_LOCATION';

export default {
  load: createStandardAction(`${ENTITY}/LOAD`)(),
  setLocations: createStandardAction(`${ENTITY}/SET_LOCATIONS`)<IArchItem[]>(),
  setRollups: createStandardAction(`${ENTITY}/SET_LOCATION_ROLLUPS`)<
    IRollup[]
  >(),
  unload: createStandardAction(`${ENTITY}/UNLOAD`)(),
};
