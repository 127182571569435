import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import useDidMount from 'lib/hooks/useDidMount';
import Create from './create';
import Edit from './edit';
import List from './list';

const Router: React.FC<RouteComponentProps<{ siteId: string }>> = props => {
  const {
    match: {
      params: { siteId },
    },
    history,
  } = props;
  const [currSite, setCurrSite] = useState(siteId);
  const hasMounted = useDidMount();
  useEffect(
    () => {
      if (currSite !== siteId) {
        history.push(`/${siteId}/scenes`);
        setCurrSite(siteId);
      }
    },
    [hasMounted, history, siteId, currSite, setCurrSite],
  );
  return (
    <Switch>
      <Route path={`${props.match.path}/new`} exact component={Create} />
      <Route path={`${props.match.path}/:sceneId`} component={Edit} />
      <Route path={`${props.match.path}`} exact component={List} />
    </Switch>
  );
};

export default (props: any) => {
  return <Router {...props} />;
};
