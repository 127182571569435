import * as React from 'react';

interface ISvgProps {
  width?: string;
  height?: string;
}

const SetSceneIcon = (props: ISvgProps) => (
  <svg
    width={props.width ? props.width : '27px'}
    height={props.height ? props.height : '27px'}
    viewBox="0 0 50 50"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="scene-set-icon"
        transform="translate(25.000000, 25.000000) scale(-1, 1) rotate(-180.000000) translate(-25.000000, -25.000000) "
        fill="#8788A6"
        fillRule="nonzero"
      >
        <path
          d="M0.9,48.6 C-0.75,46.8 -0.1,45.05 5.25,35.75 C8.1,30.85 10.65,26.7 11,26.6 C12.65,26.05 11.6,28.6 7.1,36.35 C4.35,41.1 2.05,45.25 2,45.6 C1.55,49.05 3.45,48 11.25,40.45 C16.15,35.75 18.8,33.5 19.3,33.7 C19.7,33.85 20.05,34.15 20.05,34.4 C20.05,34.65 16.55,38.15 12.3,42.15 C4.1,49.95 2.8,50.7 0.9,48.6 Z"
          id="Path"
        />
        <path
          d="M22.05,30.5 C10.9,20 1.4,11 0.9,10.4 C0.4,9.8 0.05,8.55 0.05,7.4 C0.05,3.35 5.15,0.45 12.2,0.45 C17.65,0.45 20.4,1.3 23.2,3.9 L24.55,5.2 L25.95,3.9 C28.7,1.3 31.45,0.45 36.9,0.45 C41.5,0.45 45,1.55 47.3,3.75 C48.7,5.05 49.05,5.75 49.05,7.3 C49.05,8.4 48.65,9.85 48.15,10.55 C46.85,12.55 38.65,21 38.05,21 C36.85,21 37.7,19.05 39.8,17 C41.85,15 42.8,13.45 41.55,14.25 C40.8,14.75 32.95,14.6 31.2,14.05 C30.3,13.8 29.55,13.7 29.55,13.9 C29.55,14.5 38.75,30.75 42.15,36.15 C47.8,45.15 48.25,46.3 46.75,48.4 C46.2,49.2 45.5,49.5 44.15,49.5 C42.4,49.5 41.15,48.4 22.05,30.5 Z M45.35,46.8 C45.7,45.9 44.8,44.15 40.25,37 C38.3,33.85 34.05,26.55 30.8,20.75 C27.6,15 24.9,10.2 24.75,10.05 C24.65,9.95 23.9,10.4 23.15,11.1 C20.4,13.7 17.65,14.55 12.25,14.55 C10.2,14.55 8.55,14.65 8.55,14.75 C8.55,14.85 16.3,22.25 25.8,31.2 C36.6,41.4 43.45,47.5 44.1,47.5 C44.65,47.5 45.2,47.2 45.35,46.8 Z M19.45,11.25 C26.45,8 22.3,2.5 12.75,2.5 C6.4,2.5 2.05,4.55 2.05,7.5 C2.05,10.45 6.15,12.4 12.55,12.45 C16.05,12.5 17.25,12.25 19.45,11.25 Z M43.45,11.25 C50.45,8 46.3,2.5 36.75,2.5 C28.5,2.5 23.85,5.95 27.2,9.7 C28.8,11.55 31.7,12.45 36.55,12.45 C40.05,12.5 41.25,12.25 43.45,11.25 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default SetSceneIcon;
